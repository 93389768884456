import * as THREE from "three";
import { getAuxMaterialPoint } from "lib/materials/helpers";
import { IPoint } from "lib/math/types";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { getMiddlePoint, IpointsToBuffer, substractIpoint } from "lib/math/point";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { defaultLineStyleId, defaultLineWidth, materialCache } from "lib/materials";
import { calculateCentroidPoints } from "lib/math/centroid";
import { getBoundingBoxPoints, getDimensionsXY } from "lib/math/box";
import { nearestPointToPolyline } from "lib/math/distance";
import { lineCreateIPoints } from "../line";
import { setPosBuffer } from "..";
import { filledPolygon2DPoints } from "../solid/region";
import { LIArrow } from "lib/helpers/liarrow";
import { ErrorCentinel, i_ECS } from "lib/helpers/error-centinel";

/** Regulador centralizado de la incorporacion de flechas para la visualizacion grafica de las cargas. */
let use_arrows_for_loads: boolean = false;

const loadLinealColor = { r: 255, g: 255, b: 0, a: 1 }
const loadLinealWidth = 4;
const loadSuperficialColor = { r: 255, g: 255, b: 170, a: 0.3 }
const loadSegmentsColor = { r: 0, g: 0, b: 255, a: 1 }

/**
 * Para crear un letrero con un titulo (opcional) y un valor (obligatorio), separados por ":" y en un cuadro con un
 * reborde exterior del color suministrado.
 *
 * @param {string} title
 * @param {number} value
 * @param {string} [borderColor="hotpink"]
 * @return {*}  {HTMLCanvasElement}
 */
function drawBoxWithValue(title: string, value: number, borderColor: string = "hotpink"): HTMLCanvasElement {
  // \ToDo: WARNING: No tengo yo muy claro si no hay que hacer algun release o algo...
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const context = canvas.getContext("2d") as CanvasRenderingContext2D;

  const borderThickness = 3;
  const fontSize = 24;
  // Ojo, que ahora opcionalmente podemos incluir un titulo y tener un mensaje final de la forma "valor:5.600".
  let text: string =  String(value.toFixed(3) ?? 0);
  // Simplificacion: Todo lo que acabe en .000 lo quitamos para dejarlo exacto y que el letrerico sea mas piquico.
  if (text.endsWith(".000")) {
    text = text.substring(0, text.length - 4);
  }
  if (title.length) {
    text = title + ":" + text;
  }

  context.font = fontSize + "px Arial";
  const metrics = context.measureText(text);
  const textWidth = metrics.width;

  const minWidth = textWidth + (borderThickness * 2);
  const minHeight = fontSize + (borderThickness * 2);

  canvas.width = minWidth;
  canvas.height = minHeight;

  // draw border canvas
  context.strokeStyle = "#00ffff"; // border color
  context.strokeRect(0, 0, canvas.width, canvas.height);

  // draw rect
  context.fillStyle = "#ffffff"; // background color
  context.fillRect(0, 0, minWidth, minHeight);

  // draw border
  context.lineWidth = borderThickness;
  // Este es el color del borde exterior.
  context.strokeStyle = borderColor;
  context.strokeRect(borderThickness * 0.5, borderThickness * 0.5, minWidth - borderThickness, minHeight - borderThickness);

  // draw text
  context.font = fontSize + "px Arial";
  context.textAlign = "center";
  context.fillStyle = "#000000"; // text color
  context.fillText(text, minWidth * 0.5, (minHeight * 0.5) + (fontSize * 0.3));

  return canvas;
}

function getSpriteFromCanvas(canvas: HTMLCanvasElement) {
  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;
  const material = new THREE.SpriteMaterial({ map: texture, toneMapped: false });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(0.01 * canvas.width, 0.01 * canvas.height, 1); // magic number from answers in https://stackoverflow.com/questions/23514274/three-js-2d-text-sprite-labels
  return sprite;
}

function getFatLine(ptos2D: IPoint[]): Line2 {
  const geometry = new LineGeometry();
  const buffer = IpointsToBuffer(ptos2D);
  geometry.setPositions(buffer);
  const mat = { color: loadLinealColor, lineStyleId: defaultLineStyleId, width: loadLinealWidth };
  const material = materialCache.getMaterial(mat) as LineMaterial;
  return new Line2(geometry, material);
}

function getNearestPoints(points: IPoint[]): [IPoint, IPoint, IPoint, IPoint] {
  // Lo primero es calcular la AABB 3D.
  const bbx = getBoundingBoxPoints(points);
  // Se sacan los extremos maximo y minimo de la AABB.
  const [min, max] = [bbx.min, bbx.max];
  // Y para esos 2 extremos se calculan los puntos mas cercanos de los dados como parametro, con el posible problema de
  // que se puedan repetir en el poco probable caso de igualdad de distancias... Esto se podria hacer de otra forma, o
  // bien buscar una representacion alternativa mejor para ese esqueleto que queremos. Quizas un spanning-tree o alguna
  // de esas cosas raras similares para representar cosas en forma arborea???...
  // O quizas se podria calcular un convex-hull y luego tirarle radios???
  const p1 = nearestPointToPolyline({ x: min.x, y: min.y, z: min.z }, points);
  const p2 = nearestPointToPolyline({ x: min.x, y: max.y, z: min.z }, points);
  const p3 = nearestPointToPolyline({ x: max.x, y: max.y, z: min.z }, points);
  const p4 = nearestPointToPolyline({ x: max.x, y: min.y, z: min.z }, points);
  return [p1, p2, p3, p4];
}

/**
 * Sospecho que esta es la funcion que crea el esqueleto-aracnido de 4 segmentos para las cargas superficiales???.
 *
 * @param {IPoint} center
 * @param {IPoint[]} ptos2D
 * @return {*}  {THREE.LineSegments}
 */
function getLoadLineSegment(center: IPoint, ptos2D: IPoint[]): THREE.LineSegments {
  const [p1, p2, p3, p4] = getNearestPoints(ptos2D);
  const mat = { color: loadSegmentsColor, lineStyleId: defaultLineStyleId, width: defaultLineWidth };
  const material = materialCache.getMaterial(mat);
  const lineSegment = lineCreateIPoints([center, p1, p2, p3, p4], [0, 1, 0, 2, 0, 3, 0, 4], material) as THREE.LineSegments;
  return lineSegment;
}

/**
 * Sospecho que esta es la funcion que para las cargas superficiales genera la sabana que las cubre que no se ve un pijo
 * de bien... \ToDo: Arreglarla para luchar con el z-fighting y que se vea moito meyor.
 *
 * @param {IPoint} center
 * @param {IPoint[]} ptos2D
 * @return {*}  {THREE.Mesh}
 */
function getLoadMesh(center: IPoint, ptos2D: IPoint[]): THREE.Mesh {
  // Es esta la que pinta los 4 segmentos como de esqueleto de la carga superficial desde el centro hasta alguno de los
  // vertices dados???.
  const lineSegment = getLoadLineSegment(center, ptos2D);

  // Aqui nos hacemos con el material que sera usado para la superficie de carga que se calcula a continuacion.
  // Para ello damos como una clave inicial que nos devuelve el valor posiblemente cacheado previamente.  
  const mat = { color: loadSuperficialColor, texture: 0 };
  // Parece ser que el MeshBasicMaterial no resuelve el z-fighting, por lo que este material debiera ser phong..
  // Ademas le quito el const para sustituirlo por un clone posiblemente con distinto color?...
  let material = materialCache.getSolidMaterial(mat);

  // De momento pongo un color que sea muy visible y chillon para probar.
  // Tenemos a escoger entre unos naranjas (#F1C40F o #FFBF00), un amarillo (#DFFF00).
  material.color = new THREE.Color("#F1C40F");
  console.log(`[getLoadMesh() set a material of type "${material.type}"]`);
  if (material.type !== "MeshPhongMaterial") {
    debugger;
  }

  // Y si el problema esta en que siempre estamos usando el mismo puto material???. Hago un clon y le doy otro color.
  if (true) {
    material = material.clone();
    material.color = ErrorCentinel.getRandomColor();
  }

  // La pregunta es: Si yo altero ese material y lo cambio para impedir el z-fighting o aplicarle otras tecnicas...
  // ¿Eso quedara almacenado en el material original del cache o lo de aqui es una mera copia no profunda?.
  // Pues parece que SI!!!.

  if (false) {
    // Intento de remediar el puto z-fighting.
    material.polygonOffset = true;
    material.polygonOffsetFactor = -1.0;
    material.polygonOffsetUnits = -4.0;
    material.side = THREE.FrontSide;
  }

  if (true) {
    // Estos son los 4 parametros que parecen funcionar bien en el ejemplo del calvo Decal...
    // Pero aqui no van bien...
    material.depthTest = true; // Este parece que podria no hacer falta???
    material.depthWrite = false;
    material.polygonOffset = true;
    material.polygonOffsetFactor = -4;

    // La transparencia parece obligatoria para evitar el z-fighting.
    material.transparent = true;

    // Y un solo lado para que no toque los cojones y se vea desde abajo.
    material.side = THREE.FrontSide;

    // material.normalScale = new THREE.Vector2( 1, 1 );
  }

  // Y si le pegamos una textura cuadriculada para intentar mejorar la visualizacion???.
  if (true) {
    // const [dimX, dimY] = getDimensionsXY(ptos2D);
    const textureLoader = new THREE.TextureLoader();
    // Esta es la textura que mejor queda de las 3.
    textureLoader.load('/textures/sq_grid2.png', function(texture) {
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      // texture.repeat.set(dimX, dimY);
      // Con esto tenemos casillas de 1x1 metros.
      texture.repeat.set(1, 1);

      // Para probar le metemos una rotacion aleatoria en [0, 2*PI]. Aqui hay algo raro.
      texture.rotation = 2 * Math.PI * Math.random();

      material.map = texture;
      material.map.anisotropy = 4;

      // ESTO SI QUE QUITA EL Z-FIGHTING!!!, pero con la putada de que siempre se ve, tiñe las etiquetas de las cargas
      // y transparenta por delante de todo lo que tenga al lado de su cara frontal visible, pero no jode el rendering
      // de lo que tiene por debajo. Esto se usa mucho con objetos transparentes, junto con un valor grande de
      // mesh.renderOrder.

      // Lo quita...
      // material.depthTest = false;

      // Esta no hace mas que joder, asi que dejar valor por defecto.
      // material.depthFunc = THREE.NotEqualDepth;


      material.needsUpdate = true;
    });
  }
  

  // Aqui calculamos la manta entre los puntos con nuestro material. Creo que se puede usar una shape geometry o algo...
  const mesh = filledPolygon2DPoints(ptos2D, material);
  mesh.add(lineSegment);
  
  if (true) {
    // Intento agregar otra shape, pero en este caso esqueletica.
    const v = ptos2D.map(p => new THREE.Vector2(p.x, p.y));
    v.push(v[0]);
    const shape = new THREE.Shape(v);
    shape.autoClose = true;

    // Queremos una linea formada por puntos de la shape, que nada tienen que ver con los originales.
    // Con esto tenemos los puntos originales.
    // const points = shape.getPoints();
    // Con esto los tomamos espaciados, pero uno cada metro, para lo cual primero calculamos la longitud.
    const metersLength = Math.floor(shape.getLength());
    const points = shape.getSpacedPoints(metersLength);
    const geometryPoints = new THREE.BufferGeometry().setFromPoints(points);

    const shapeMaterial = new THREE.PointsMaterial({ color: 'red' });
    const shapeLine = new THREE.Points(geometryPoints, shapeMaterial);
    // Intento de levantar 10 cm.
    shapeLine.position.set(0, 0, 0.1);
    shapeLine.raycast = () => {};
    mesh.add(shapeLine);
  }

  // Esto no parece buena idea.
  // mesh.position.z += 0.001;

  // Otro truco para intentar acabar con el z-fighting: NO USAR, JODE EL RENDERING DE LO QUE HAY POR DEBAJO.
  if (false) {
    mesh.renderOrder = 999;
    mesh.onBeforeRender = function (renderer) { renderer.clearDepth(); };
  }


  return mesh;
}

/**
 * Situa el sprite en las coordenadas dadas. Ademas incluimos una altura adicional opcional que sirve para mover el
 * sprite hacia arriba y que no quede medio oculto por la losa.
 *
 * @param {THREE.Sprite} sprite
 * @param {IPoint} pos
 * @param {number} [incZ=0.0]
 */
function updateSpritePosition(sprite: THREE.Sprite, pos: IPoint, incZ: number = 0.0) {
  if (sprite) {
    sprite.position.set(pos.x, pos.y, pos.z + incZ);
  }
}

/**
 * Situe el sprite en el centro geometrico de la serie de puntos dados. Opcionalmente se puede dar una altura de
 * separacion para que el sprite no caiga sobre la losa.
 *
 * @param {THREE.Sprite} sprite
 * @param {IPoint[]} ptos2D
 * @param {number} [incZ=0.0]
 */
function updateSpritePositionMiddle(sprite: THREE.Sprite, ptos2D: IPoint[], incZ: number = 0.0) {
  if (sprite) {
    const center = getMiddlePoint(ptos2D[0], ptos2D[1]);
    const pos = substractIpoint(center, ptos2D[0]);
    pos.z += incZ;
    updateSpritePosition(sprite, pos);
  }
}

function updateSpriteValue(sprite: THREE.Sprite, newName: string, newValue: number, newBorderColor: string) {
  if (sprite) {
    const canvas = drawBoxWithValue(newName, newValue, newBorderColor);
    const texture = new THREE.CanvasTexture(canvas);
    texture.needsUpdate = true;
    sprite.material = new THREE.SpriteMaterial({ map: texture, toneMapped: false });
    sprite.scale.set(0.01 * canvas.width, 0.01 * canvas.height, 1); // magic number from answers in https://stackoverflow.com/questions/23514274/three-js-2d-text-sprite-labels
  }
}

/**
 * Constructor de flechas simples.
 *
 * @export
 * @return {*}  {LIArrow}
 */
export function createSimpleArrow(): LIArrow {

  // El planteamiento para colocar nuestras flechas inicialmente sobre un punto P € R3:
  //
  //                              |               +
  //                              |               | arrowLength
  //                              V               |
  //        ----------------------P-------------  *

  const arrowLength = 1.0;
  // Deber ser unitario. Inicialmente y por defecto apuntamos a -Z.
  const direction = new THREE.Vector3(0, 0, -1);
  // La posicion, basada en subir la flecha de la hipotetica posicion P(0, 0, 0) a un origen Q(0, 0, arrowLength).
  const position = new THREE.Vector3(0, 0, arrowLength);
  // Por defecto con color rosa, por el LGTBQC++ ese de los cojones.
  const color = "pink";

  // La longitud y grosor de la cabeza que por defecto son 0.2 por la longitud.
  const headLength = arrowLength * 0.25;
  const headWidth = arrowLength * 0.10;

  const arrow = new LIArrow(direction, position, arrowLength, color, headLength, headWidth, true);
  
  if (true) {
    // Sacamos a sus componentes de gestiones de profundidad para que se puedan ver siempre.
    // @ts-ignore
    arrow.line.material.depthTest = arrow.cone.material.depthTest = false;
    // @ts-ignore
    arrow.line.material.depthWrite = arrow.cone.material.depthWrite = false;
  }

  // Parece que esto no funciona.
  if (false) {
    arrow.visible = true;
    // @ts-ignore
    arrow.line.material.visible = arrow.cone.material.visible = false;
  }

  return arrow;
}

export function createLoadConcentrated(name: string, value: number): THREE.Points {
  // Las cargas puntuales van en color azul normal para distinguirse de cuando se selecciona..
  const canvas = drawBoxWithValue(name, value / 1000, "blue");
  const buffer = new THREE.BufferAttribute(new Float32Array([0, 0, 0]), 3);
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute("position", buffer);
  const material = getAuxMaterialPoint();
  const loadPoint = new THREE.Points(geometry, material);
  const sprite = getSpriteFromCanvas(canvas);
  const pos = sprite.position;
  // Subimos un poco la Z para que no se quede en la losa y para que se vea bien la flecha.
  sprite.position.set(pos.x, pos.y, pos.z + 0.50);
  loadPoint.add(sprite);

  if (use_arrows_for_loads) {
    // Probamos a meter una flecha a ver la pintaca.
    const arrow = createSimpleArrow();
    arrow.position.set(0, 0, 0);
    arrow.setColor("blue");
    loadPoint.add(arrow);
  }
  
  console.error(` ===============> Calls to createLoadConcentrated("${name}", ${value}): ${i_ECS.incCounter("createLoadConcentrated")}`);

  return loadPoint;
}

export function createLoadLineal(name: string, value: number, ptos2D: IPoint[]): Line2 {
  // Las cargas lineales por el momento las pintamos con borde verde.
  const canvas = drawBoxWithValue(name, value / 1000, "green");
  const loadLine = getFatLine(ptos2D);
  const sprite = getSpriteFromCanvas(canvas);
  // Y con una pequeña separacion de la losa.
  updateSpritePositionMiddle(sprite, ptos2D, 0.20);
  loadLine.add(sprite);

  // La retahila de flechas van en posiciones relativas NO usando loadLine, sino directamente los ptos2D.
  const N = ptos2D.length;
  if (use_arrows_for_loads) {    
    for (let i = 0; i < N; ++i) {
      const x = ptos2D[i].x;
      const y = ptos2D[i].y;
      const z = ptos2D[i].z;
      const arrow = createSimpleArrow();
      arrow.position.set(x, y, z);
      arrow.setColor("green");
      loadLine.add(arrow);
    }
  }

  console.error(` ===============> Calls to createLoadLineal("${name}", ${value}, N:${N}): ${i_ECS.incCounter("createLoadLineal")}`);

  return loadLine;
}

export function createLoadSuperficial(name: string, value: number, ptos2D: IPoint[]): THREE.Mesh {
  const center = calculateCentroidPoints(ptos2D);
  let mesh = new THREE.Mesh();
  const N = ptos2D.length;
  if (center) {
    // Las cargas superficiales de momento las pintamos con borde rojo.
    const canvas = drawBoxWithValue(name, value / 1000, "red");
    mesh = getLoadMesh(center, ptos2D);
    const sprite = getSpriteFromCanvas(canvas);
    // Y con una pequeña separacion de la losa.
    updateSpritePosition(sprite, center, 0.25);
    mesh.add(sprite);

    // Vamos con las flechas.    
    if (use_arrows_for_loads) {
      for (let i = 0; i < N; ++i) {
        const x = ptos2D[i].x;
        const y = ptos2D[i].y;
        const z = ptos2D[i].z;
        const arrow = createSimpleArrow();
        arrow.position.set(x, y, z);
        arrow.setColor("red");
        mesh.add(arrow);
      }
    }  
  }

  console.error(` ===============> Calls to createLoadSuperficial("${name}", ${value}, N:${N}): ${i_ECS.incCounter("createLoadSuperficial")}`);

  return mesh;
}

export function updateLoadConcentrated(graphicObj: THREE.Object3D, newName: string, newValue: number) {
  for (let i = 0; i < graphicObj.children.length; i++) {
    let child = graphicObj.children[i];
    if (child instanceof THREE.Sprite) {
      updateSpriteValue(child, newName, newValue / 1000, "blue");
    }
  }
}

export function updateLoadLineal(graphicObj: THREE.Object3D, newName: string, newValue: number, ptos2D: IPoint[]) {
  for (let i = 0; i < graphicObj.children.length; i++) {
    let child = graphicObj.children[i];
    if (child instanceof THREE.Sprite) {
      updateSpriteValue(child, newName, newValue / 1000, "green");
      // Lo elevamos un piquico para que no quede en la losa.
      updateSpritePositionMiddle(child, ptos2D, 0.20);
    }
  }
}

export function updateLoadSuperficial(graphicObj: THREE.Object3D, newName: string, newValue: number, ptos2D: IPoint[]) {
  const center = calculateCentroidPoints(ptos2D);
  if (center) {
    for (let i = 0; i < graphicObj.children.length; i++) {
      let child = graphicObj.children[i];
      if (child instanceof THREE.Sprite) {
        updateSpriteValue(child, newName, newValue / 1000, "red");
        // Pequeña separacion de la losa.
        updateSpritePosition(child, center, 0.25);
      } else if (child instanceof THREE.LineSegments) {
        const [p1, p2, p3, p4] = getNearestPoints(ptos2D);
        const coords = IpointsToBuffer([center, p1, p2, p3, p4]);
        setPosBuffer(child, coords);
      }
    }
  }
}