import { AnalysisProperties } from "lib/models-struc/analysis/analysis";
import { Hypothesis, loadHypothesisList } from "lib/models-struc/hypothesis/hypothesis";
import { MeshProperties } from "lib/models-struc/mesh/mesh";
import { storeyMaterialType } from "lib/models-struc/types/struc-base";
import { ICADModelPersistence } from "lib/input-output/database/loader";
import { CameraSettings } from "lib/graphic-processor";
import { PlanesSettings } from "lib/coordinates/plane-manager";
import { standard, euSteelType, usSteelType } from "./codes";

export let STRU_VERSION = "0.2";
export function setStruVERSION(version: string) {
  if (version) STRU_VERSION = version;
}

export function isStrucProject(proj: any): proj is StrucProject {
  return "concrete_code" in proj;
}

export interface StrucProject {
  version: string,
  id: string;
  userId: string; // user_id → userId
  createdAt: string; //created_at → createdAt 
  updatedAt: string; //updated_at → updatedAt

  name: string;
  remarks: string;
  country: string;
  city: string;
  concrete_code: string;
  load_code: string;

  steel_standard: standard;
  steel_type: euSteelType | usSteelType;
  concrete_standard: standard;

  footing_level: number;
  sites: StrucSite[];
  hypothesis: Hypothesis[];
  mesh?: MeshProperties;
  analysis?: AnalysisProperties;

  // ******************** CAD information ********************
  snapshot?: string | null;
  content: ICADModelPersistence;
  planes?: PlanesSettings;
  cameraCfg?: CameraSettings;
}

export interface StrucSite {
  id: string;
  name: string;
  remarks: string;
  buildings: StrucBuilding[];
}

export interface StrucBuilding {
  id: string;
  name: string;
  remarks: string;
  struc_storeys: StrucStorey[];
  struc_elements: string[]; // uuid
  load_elements: string[]; // uuid
  struc_counters?: StructCounters;
  compound_columns?: Record<string, string[]>;
  compound_walls?: Record<string, string[]>;
}
export interface StructCounters {
  numBeams: number;
  numColumns: number;
  numFooters: number;
  numPileCaps: number;
  numSlabs: number;
  numWalls: number;
  numLoads: number;
}

export interface StrucStorey {
  id: string;
  storey_number: number;
  floor_name: string;
  height: number;
  level: number;
  occupancy: loadHypothesisList;
  live_load: number;
  dead_load: number;
  column_concrete_type: storeyMaterialType;
  floor_concrete_type: storeyMaterialType;
  dxf_name?: string;
  dxf_url?: string;
}
