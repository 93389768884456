import { GraphicProcessor } from "lib/graphic-processor";
import { LoadStructuralData } from "lib/models/structural/load";
import { IFEMStructuralElement, IFEMStructuralElementRef, IMeshProject } from "modules/struc/models/ecore/analysis";
import { Building } from "modules/struc/models/ecore/base";
import { saveFile } from "lib/apis/utils";

export class FEMStructuralElementManager {

  private mapLoadFEMStructuralElemRef: Map<LoadStructuralData, IFEMStructuralElementRef> = new Map();
  private newRootRef = "mesh_project.json#//@"

  hasMeshData(): boolean {
    return this.mapLoadFEMStructuralElemRef.size > 0;
  }

  /**
   * Cuando estamos en "Project"/"Code analysis" y pulsamos al boton "Download Ecore" desencadenamos la descarga del modelo
   * Ecore desde el servidor. Este es el punto por el que nos entra el string con ese churro.
   *
   * @param {GraphicProcessor} graphicProc
   * @param {string} stringMesh
   * @return {*}  {Promise<void>}
   * @memberof FEMStructuralElementManager
   */
  async importMeshDataForLoads(graphicProc: GraphicProcessor, stringMesh: string): Promise<void> {
    console.log(`[FEMStructuralElementManager.importMeshDataForLoads() received ${stringMesh.length} chars string]`);
    if (false) {
      // Salvamos directamente la mierda que nos llega.
      saveFile(stringMesh, "stringMesh.txt", "application/json");
      // Y la volvemos a salvar una vez formateada, por si las moscas.
      const obj4JSON = JSON.parse(stringMesh);
      let txt2Save = JSON.stringify(obj4JSON, null, "\t");
      saveFile(txt2Save, "formatted_stringMesh.txt", "application/json");
    }

    this.mapLoadFEMStructuralElemRef.clear();
    const jsonMesh = JSON.parse(stringMesh.replaceAll("//@", this.newRootRef));
    const { building, femStructuralElems } = this.loadElemsAndFEMStrucElems(jsonMesh);
    if (building && femStructuralElems) {
      const strucMng = graphicProc.getStructuralModelManager();
      const loads = strucMng.getLoadElements();
      const N = loads.length;
      if (0 === N) {
        console.error("[WARNING: No loads present in the ecore model]");
      } else {
        console.log(`[Processing ${N} loads]`);
      }
      let index = 0;
      for (const load of loads) {
        console.log(`Load[${index}/${N}] "${load.definition.name}"`);
        const parentId = load.definition.parentStructElemId;
        const { storeyIndex, elemIndex } = this.getIndexOfStructuralElementById(parentId, building);
        if (storeyIndex !== undefined && elemIndex !== undefined) {
          const ref = `${this.newRootRef}versions.0/@building/@storeys.${storeyIndex}/@elements.${elemIndex}`;
          // El error es que con la referencia usada no se saca ningun elemento estructural, pues la ref es de la forma
          // 'mesh_project.json#//@versions.0/@building/@storeys.0/@elements.10' mientras que los elementos llevan "cosas"
          // de la forma "17bcf407-322f-4d14-bc91-4122a69ce676". Asi que hay que ampliar la busqueda...
          let { femEclass, femRef } = this.getInfoOfFEMStructuralElementByRef(ref, femStructuralElems);

          // SOLUCION: Ampliamos la busqueda pero usando no la referencia sino el id del padre. Funciona!!!.
          if (femEclass === undefined || femRef === undefined) {
            let altern = this.getInfoOfFEMStructuralElementByRef(load.definition.parentStructElemId, femStructuralElems);
            femEclass = altern.femEclass;
            femRef  = altern.femRef;
          }

          if (femEclass !== undefined && femRef !== undefined) {
            this.mapLoadFEMStructuralElemRef.set(load, {
              eClass: femEclass,
              $ref: femRef,
            });
          } else {
            console.error("\t[ERROR: if (femEclass !== undefined && femRef !== undefined)]");      
          }
        }
        ++index;
      }
      if (N) {
        if (this.mapLoadFEMStructuralElemRef.size !== N) {
          console.error(`[ERROR: this.mapLoadFEMStructuralElemRef.size = ${this.mapLoadFEMStructuralElemRef.size}]`);
          debugger;
        }
      }
    } else {
      console.error("[ERROR: if (building && femStructuralElems)]");
    }
  }

  getFEMStructuralElemRef(load: LoadStructuralData): IFEMStructuralElementRef | undefined {
    // He aqui el problema de la perdida de los elementos estructurales para determinadas cargas. Pero por que ha dejado
    // de funcionar???. Parece que el tamaño es 0.
    if (this.mapLoadFEMStructuralElemRef.has(load) === false) {
      debugger;
    }
    return this.mapLoadFEMStructuralElemRef.get(load);
  }

  private loadElemsAndFEMStrucElems(jsonMesh: any) {
    let building, femStructuralElems;
    const mesh = jsonMesh as IMeshProject;
    const version = mesh.versions[0];
    if (version) {
      building = version.building;
      femStructuralElems = version.femStructuralElements;
    }
    return { building, femStructuralElems };
  }

  private getIndexOfStructuralElementById(id: string, building: Building) {
    for (let storeyIndex = 0; storeyIndex < building.storeys.length; storeyIndex++) {
      const elems = building.storeys[storeyIndex].elements;
      if (elems) {
        const elemIndex = elems.findIndex(e => e.id === id);
        if (elemIndex > -1) {
          return { storeyIndex, elemIndex }
        }
      }
    }
    return { storeyIndex: undefined, elemIndex: undefined };
  }

  private getInfoOfFEMStructuralElementByRef(ref: string, femStructuralElems: IFEMStructuralElement[]) {
    const femIndex = femStructuralElems.findIndex(f => f.structuralelement.$ref === ref);
    if (femIndex > -1) {
      const ref = `${this.newRootRef}versions.0/@femStructuralElements.${femIndex}`;
      return { femEclass: femStructuralElems[femIndex].eClass, femRef: ref };
    }
    return { femEclass: undefined, femRef: undefined };
  }
}

export let femStructuralManager: FEMStructuralElementManager;
export function initFEMStructuralManager() {
  femStructuralManager = new FEMStructuralElementManager();
  return femStructuralManager;
}