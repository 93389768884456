import { requestStatus, saveFile } from "lib/apis/utils";
import { MeshAction, MeshActionType } from "lib/events/mesh";
import { userMessageEvents } from "lib/events/user-messages";
import { meshDispatcher } from "lib/models-struc/mesh/dispatcher";
import { MeshGenParams, getMeshInfoProp } from "lib/models-struc/mesh/mesh";
import { IPanelSetting } from "modules/cad/components/sidebar/context";
import { useMainGraphicContext } from "modules/cad/components/viewport/context";
import { useEffect, useReducer } from "react";
import { useUI } from "shared/components/ui/context";
import { useStrucProjectContext } from "../../context";

function reducer(state: MeshGenParams, action: MeshAction): MeshGenParams {
  switch (action.type) {
    case MeshActionType.UPDATE_MESH_PARAMS: {
      return { ...action.payload.params };
    }
    case MeshActionType.LOAD_MESH_PROP:
    case MeshActionType.DOWNLOADED_MESH: {
      return state;
    }
    default:
      throw new Error(`Action is not defined.`);
  }
}

export function useMeshPanel() {

  const { openModal } = useUI();

  const graphicProc = useMainGraphicContext();
  const { meshManager } = useStrucProjectContext();

  const downloadMeshModelToFile = () => {
    const meshProjectData = meshManager.exportMeshProjectStruct(graphicProc);
    const fileName = `${meshProjectData.name}.json`;

    console.info("Saving current project/ecore JSON to file...");
    const t0 = performance.now();
    let txt2Save = JSON.stringify(meshProjectData, null, "\t");
    const size = txt2Save.length;
    saveFile(txt2Save, fileName, "application/json");

    const t1 = performance.now();
    console.info("[MESH JSON SAVING] '" + fileName + "' (" + size + " bytes) " + (t1 - t0).toFixed(3) + " milliseconds.");
    // Ayudo al GC.
    txt2Save = "";
  }

  const launchMeshingAction = () => {
    const errors = meshManager.checkMeshProjectErrors(graphicProc);
    if (errors) {
      meshManager.status = requestStatus.ERROR;
      const message = "The mesh project has errors that make the analysis impossible. Please, check the downloaded file for more info ...";
      userMessageEvents.dispatchError(message);
      saveFile(JSON.stringify(errors), `${errors.name}.json`, "application/json");
    } else {
      openModal("MESHING");
    }
  }

  const regenerateWafflesAction = () => {
    openModal("WAFFLE");
  }

  const getPropSettingsFromMesh = (meshParams: MeshGenParams): IPanelSetting<MeshGenParams> | null => {
    if (meshParams) {
      return {
        title: "Mesh properties",
        setting: {
          propValue: {
            meshId: { type: "string", value: meshManager.mesh.project?.Id, publicName: "MeshId", editable: false },
            ecore: { type: "button", value: undefined, publicName: "Download JSON for mesh", buttonCb: downloadMeshModelToFile },
            waffles: { type: "button", value: undefined, publicName: "Re-calculate waffles", buttonCb: regenerateWafflesAction },
            meshing: { type: "button", value: undefined, publicName: "Launch meshing", buttonCb: launchMeshingAction },
            ...getMeshInfoProp(meshParams),
          },
          propCallback: meshManager.editMeshParam.bind(meshManager),
        },
      }
    }
    return null;
  };

  const [state, dispatch] = useReducer(reducer, { ...meshManager.mesh.params });

  useEffect(() => {
    meshDispatcher.subscribe(dispatch);
    return () => {
      meshDispatcher.unsubscribe(dispatch);
    }
  }, []);

  return {
    getPropSettingsFromMesh,
    isMeshDownloaded: meshManager.isDownload,
  }
}