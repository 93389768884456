import * as THREE from "three";
import { copyIPoint } from "../math/point";
import { IPoint } from "../math/types";
import { GraphicProcessor } from "../graphic-processor";
import { getEulerFromNormalAndPoint, lineIntersection } from "../math/plane";
import { vectorDist3D } from "../math/distance";
import { isPointInBox } from "../math/box";
import { PlaneDefinition } from "./plane";

export enum planeSnap { XY = 0, XZ, YZ }

/** Enumeracion con el tipo de plano empleado en los viewports.
 *  - DEF: Perspectiva.
 *  - TOP: Planta.
 *  - BOTTOM: Antiplanta, no usada.
 *  - FRONT: Alzado.
 *  - BACK: Retro-alzado con bostezo.
 *  - LEFT: Perfil izquierdo (el usado en el diedrico "americano").
 *  - RIGHT: Antiperfil.
 *  - CUSTOM: TBD...
 *
 * @export
 * @enum {number}
 */
export enum refViewPlane {
  DEF = 0,
  TOP,
  BOTTOM,
  FRONT,
  BACK,
  LEFT,
  RIGHT,
  CUSTOM,
}
export function getViewPosition(view: refViewPlane, dist: number = 500): IPoint {
  switch (view) {
    case refViewPlane.TOP: return { x: 0, y: 0, z: dist };
    case refViewPlane.BOTTOM: return { x: 0, y: 0, z: -dist };
    case refViewPlane.FRONT: return { x: 0, y: -dist, z: 0 };
    case refViewPlane.BACK: return { x: 0, y: dist, z: 0 };
    case refViewPlane.LEFT: return { x: -dist, y: 0, z: 0 };
    case refViewPlane.RIGHT: return { x: dist, y: 0, z: 0 };
    default: return { x: 0, y: -dist * 0.5, z: dist };
  }
}

export type PlanesSettings = any;

/** Manager of main Plane and list of planes defined by the user
 *
 * @export
 * @class CoordinateSystemManager
 */
export class PlaneManager {

  private graphicProcessor: GraphicProcessor | undefined;

  // Definition of Universal Coordinate System (SCU)
  public mainPlane: PlaneDefinition;
  // Planes defined by user
  public userPlanes: PlaneDefinition[];

  // Active Plane (main / user / other (raycast plane))
  public activePlane: PlaneDefinition;
  public lastMainPosition: IPoint;

  public isUserPlaneActive: boolean = false;

  public currentPlaneOPSnap: planeSnap = planeSnap.XY;

  constructor(graphicProcessor?: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.mainPlane = new PlaneDefinition({ x: 0, y: 0, z: 0 }, { x: 0, y: 0, z: 0 });
    this.mainPlane.setAsMainPlane();
    // FGM: Quitamos este helper grafico ya que ahora tenemos el infinitum.
    // this.addMainPlaneHelperToScene();
    this.activePlane = this.mainPlane.clone();
    this.lastMainPosition = copyIPoint(this.activePlane.position);
    this.userPlanes = [];
  }

  addMainPlaneHelperToScene() {
    if (this.mainPlane.gridHelper && this.mainPlane.axesHelper && this.graphicProcessor) {
      const helperScene = this.graphicProcessor.getHelperScene();
      // this.mainPlane.gridHelper.renderOrder = -1;
      helperScene.add(this.mainPlane.gridHelper);
      this.mainPlane.axesHelper.renderOrder = 10;
      helperScene.add(this.mainPlane.axesHelper);
    }
  }

  addUserPlane(position?: IPoint, rotation?: IPoint) {
    const plane = new PlaneDefinition(position, rotation);
    plane.setAsUserPlane();
    this.userPlanes.push(plane);
    if (this.graphicProcessor) {
      const helperScene = this.graphicProcessor.getHelperScene();
      plane.addToScene(helperScene);
    }
    return plane;
  }
  deleteUserPlane(plane: PlaneDefinition) {
    const indx = this.userPlanes.indexOf(plane);
    if (indx !== -1) {
      if (this.graphicProcessor) {
        const helperScene = this.graphicProcessor.getHelperScene();
        plane.removeFromScene(helperScene);
      }
      this.userPlanes.splice(indx, 1);
    }
  }

  filterPointPlane(ray: THREE.Ray, nearPointSnap: boolean): IPoint {
    if (this.activePlane.locked) {
      this.setPlaneOperationSnap();
      const intersect = lineIntersection(
        this.activePlane.position,
        this.activePlane.normal,
        ray.origin,
        ray.direction
      );
      return intersect ? intersect : { x: 0, y: 0, z: 0 };
    } else {
      // Objects intersections
      if (this.graphicProcessor) {
        const res = this.graphicProcessor.getRayCastObjects()[0];
        if (nearPointSnap && res && res.face) {
          this.isUserPlaneActive = true;
          const normal = res.face.normal;
          const pto = res.point;
          const normalMatrix = new THREE.Matrix3().getNormalMatrix(res.object.matrixWorld);
          const worldNormal = normal.clone().applyMatrix3(normalMatrix).normalize();
          const rot = getEulerFromNormalAndPoint(worldNormal, pto);
          this.activePlane.rotation = rot;
          this.setPlaneOperationSnap();
          return res.point;
        }
      }
      // User plane intersections
      let [currPlane, intersect] = this.intersectUserPlanes(ray);
      if (currPlane && intersect) {
        this.isUserPlaneActive = true;
        this.activePlane = currPlane.clone();
        this.setPlaneOperationSnap();
        return intersect;
      }

      // Main plane intersection
      this.isUserPlaneActive = false;
      this.activePlane = this.mainPlane.clone();
      // Restore last position before change to userPlane
      this.activePlane.position = copyIPoint(this.lastMainPosition);

      // Restore the rotation View Plane throught plane settings
      if (this.currentPlaneOPSnap === planeSnap.XZ) {
        this.activePlane.rotation = { x: Math.PI * 0.5, y: 0, z: 0 };

      } else if (this.currentPlaneOPSnap === planeSnap.YZ) {
        this.activePlane.rotation = {
          x: Math.PI * 0.5,
          y: Math.PI * 0.5,
          z: 0,
        };
      }

      this.setPlaneOperationSnap();
      const normal = this.activePlane.normal;
      intersect = lineIntersection(
        this.activePlane.position,
        normal,
        ray.origin,
        ray.direction
      );
      return intersect ? intersect : { x: 0, y: 0, z: 0 };
    }
  }
  private setPlaneOperationSnap(plane?: planeSnap) {
    if (plane !== undefined) {
      if (this.currentPlaneOPSnap === plane) {
        return;
      }
      this.currentPlaneOPSnap = plane;
    }

    if (this.graphicProcessor) {
      this.graphicProcessor.currentOp?.workingPlane?.updateCurrentPlane();
    }
  }
  private intersectUserPlanes(ray: THREE.Ray): [PlaneDefinition | undefined, IPoint | null] {
    const numPlanes = this.userPlanes.length;
    // Intersect ray with user planes. I get the closest one.
    let currPlane: PlaneDefinition | undefined;
    let currIntersect: IPoint | null = null;
    let intersect: IPoint | null = null;
    let minDistance = Infinity;
    for (let i: number = 0; i < numPlanes; i++) {
      const userPlane = this.userPlanes[i];
      if (userPlane.active) {
        intersect = lineIntersection(
          userPlane.position,
          userPlane.normal,
          ray.origin,
          ray.direction
        );
        if (intersect) {
          // Punto en coordenadas del plano
          const point = userPlane.getRelativePoint(intersect);
          const box = userPlane.getRelativeCornerVertices();
          if (isPointInBox(point, box)) {
            const distance = vectorDist3D(intersect, ray.origin);
            if (distance < minDistance) {
              minDistance = distance;
              currPlane = userPlane;
              currIntersect = intersect;
            }
          }
        }
      }
    }
    return [currPlane, currIntersect];
  }

  setMainPlaneFromView(view: refViewPlane) {
    switch (view) {
      case refViewPlane.DEF:
      case refViewPlane.TOP:
        this.mainPlane.rotation = { x: 0, y: 0, z: 0 };
        this.setPlaneOperationSnap(planeSnap.XY);
        break;
      case refViewPlane.BOTTOM:
        this.mainPlane.rotation = { x: Math.PI, y: 0, z: Math.PI };
        this.setPlaneOperationSnap(planeSnap.XY);
        break;
      case refViewPlane.FRONT:
        this.mainPlane.rotation = { x: Math.PI * 0.5, y: 0, z: 0 };
        this.setPlaneOperationSnap(planeSnap.XZ);
        break;
      case refViewPlane.BACK:
        this.mainPlane.rotation = { x: Math.PI * 1.5, y: 0, z: Math.PI };
        this.setPlaneOperationSnap(planeSnap.XZ);
        break;
      case refViewPlane.LEFT:
        this.mainPlane.rotation = { x: Math.PI * 0.5, y: Math.PI * 1.5, z: 0 };
        this.setPlaneOperationSnap(planeSnap.YZ);
        break;
      case refViewPlane.RIGHT:
        this.mainPlane.rotation = { x: Math.PI * 0.5, y: Math.PI * 0.5, z: 0 };
        this.setPlaneOperationSnap(planeSnap.YZ);
        break;
      default:
        throw new Error("Vista no definida. valor recibido: " + view);
    }
  }
  getPlaneDefinitionFromView(pos: IPoint | undefined, view: refViewPlane): PlaneDefinition {
    let plane;
    switch (view) {
      case refViewPlane.DEF:
      case refViewPlane.TOP:
        plane = new PlaneDefinition(pos, { x: 0, y: 0, z: 0 });
        break;
      case refViewPlane.BOTTOM:
        plane = new PlaneDefinition(pos, { x: Math.PI, y: 0, z: Math.PI });
        break;
      case refViewPlane.FRONT:
        plane = new PlaneDefinition(pos, { x: Math.PI * 0.5, y: 0, z: 0 });
        break;
      case refViewPlane.BACK:
        plane = new PlaneDefinition(pos, {
          x: Math.PI * 1.5,
          y: 0,
          z: Math.PI,
        });
        break;
      case refViewPlane.LEFT:
        plane = new PlaneDefinition(pos, {
          x: Math.PI * 0.5,
          y: Math.PI * 1.5,
          z: 0,
        });
        break;
      case refViewPlane.RIGHT:
        plane = new PlaneDefinition(pos, {
          x: Math.PI * 0.5,
          y: Math.PI * 0.5,
          z: 0,
        });
        break;
      default:
        throw new Error("Vista no definida. valor recibido: " + view);
    }
    return plane;
  }

  /** Dada una camara nos devuelve el punto (x, y, 0) en el plano Z = 0 en el que tenemos un corte con el haz de vision
   * de la misma. En los casos cabrones en que ese haz de vision se va a casa dios y no toca el plano devolvemos null.
   *
   * @param {THREE.Camera} camera
   * @returns {IPoint}
   * @memberof PlaneManager
   */
  getPointMainPlane(camera: THREE.Camera): IPoint | null {
    const cameraDirection = camera.getWorldDirection(new THREE.Vector3());
    const normal = this.mainPlane.normal;
    const intersect = lineIntersection(
      this.mainPlane.position,
      normal,
      camera.position,
      cameraDirection
    );
    return intersect ? intersect : null;
  }

  exportToJSON(): PlanesSettings {
    return {
      mainPlane: {
        position: copyIPoint(this.mainPlane.position),
        rotation: copyIPoint(this.mainPlane.rotation),
      },
      userPlanes: this.userPlanes.map((p) => {
        return {
          position: copyIPoint(p.position),
          rotation: copyIPoint(p.rotation),
          active: p.active,
        };
      }),
    };
  }
  importFromJSON(planes: PlanesSettings) {
    this.mainPlane = new PlaneDefinition(
      planes.mainPlane.position,
      planes.mainPlane.rotation
    );
    this.mainPlane.setAsMainPlane();
    this.activePlane = this.mainPlane.clone();
    this.lastMainPosition = copyIPoint(this.activePlane.position);
    for (const p of planes.userPlanes) {
      const plane = this.addUserPlane(p.position, p.rotation);
      plane.active = p.active;
      if (!plane.active) plane.hidePlaneHelpers();
    }
  }
}
