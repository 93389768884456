import { lineBaseCreate } from "lib/geometries/line";
import { getCurrentLineMaterial, ILineMaterial } from "lib/materials";
import { arcBuffer } from "../../geometries/arc";
import { eulerAnglesToAxisAngle, mirrorAngle, normalizeAngle } from "../../math/angles";
import { arcParam } from "../../math/arc";
import { mirrorPoint } from "../../math/mirror";
import { addIpoint, copyIPoint } from "../../math/point";
import { rotatePoint } from "../../math/rotate";
import { scalePoint } from "../../math/scale";
import { IPoint } from "../../math/types";
import { objDataType } from "../types";
import { LineBaseData } from "./line-base";

export class ArcData extends LineBaseData {

  public type = objDataType.ARC;
  protected nameObj: string = "Arc";
  public definition: arcParam;

  constructor(definition: arcParam, material?: ILineMaterial) {
    super();
    this.definition = {
      center: copyIPoint(definition.center),
      radius: definition.radius,
      azimutO: definition.azimutO,
      angleCenter: definition.angleCenter,
      plane: copyIPoint(definition.plane),
    };
    this.material = material ?? getCurrentLineMaterial();
  }

  static createObj(definition: arcParam, material: ILineMaterial) {
    const buffer = arcBuffer(
      definition.center,
      definition.radius,
      definition.azimutO,
      definition.angleCenter,
      definition.plane
    );
    return lineBaseCreate(buffer, material);
  }
  public createObject(definition?: arcParam, material?: ILineMaterial) {
    return ArcData.createObj(definition ?? this.definition, material ?? this.material);
  }
  public getBufferGeom(): Float32Array {
    return arcBuffer(
      this.definition.center,
      this.definition.radius,
      this.definition.azimutO,
      this.definition.angleCenter,
      this.definition.plane
    );
  }

  public cloneDefinition(): arcParam {
    return {
      center: copyIPoint(this.definition.center),
      radius: this.definition.radius,
      azimutO: this.definition.azimutO,
      angleCenter: this.definition.angleCenter,
      plane: copyIPoint(this.definition.plane),
    };
  }

  public translate(distance: IPoint): void {
    this.definition.center = addIpoint(this.definition.center, distance);
    this.regenerateObjectFromDefinition();
  }
  public rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void {
    angleX = normalizeAngle(angleX);
    angleY = normalizeAngle(angleY);
    angleZ = normalizeAngle(angleZ);

    this.definition.center = rotatePoint(
      this.definition.center,
      angleX,
      angleY,
      angleZ,
      basePoint
    );
    const { angle } = eulerAnglesToAxisAngle(angleX, angleY, angleZ);
    this.definition.azimutO = normalizeAngle(this.definition.azimutO - angle);
    this.regenerateObjectFromDefinition();
  }
  public scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void {
    this.definition.center = scalePoint(
      this.definition.center,
      factorX,
      factorY,
      factorZ,
      basePoint
    );
    this.definition.radius *= factorX;
    this.regenerateObjectFromDefinition();
  }
  public mirror(startPoint: IPoint, endPoint: IPoint): void {
    this.definition.center = mirrorPoint(
      this.definition.center,
      startPoint,
      endPoint
    );
    this.definition.azimutO = mirrorAngle(
      this.definition.azimutO,
      startPoint,
      endPoint
    );
    this.regenerateObjectFromDefinition();
  }
  public delete(): void {
    this.graphicObj.geometry.dispose();
  }
}
