// \file error-centinel.ts
// Implementacion de la clase ErrorCentinel para algun tipo de control centralizado de errores que debiera ser un
// singleton. Ademas necesitaremos una escena de Three si necesitamos acceso grafico para pintar datos.

import Singleton  from "./singleton";
import { saveFile } from "lib/apis/utils";
// Necesitamos acceso grafico.
import * as THREE from "three";

/**
 * Clase encargada de centralizar la gestion de errores del sistema.
 * Ojo, que hay una instancia ya predefinida de este singleton disponible, llamda i_ECS.
 *
 * @export
 * @class ErrorCentinel
 * @extends {Singleton<ErrorCentinel>}
 */
export class ErrorCentinel extends Singleton<ErrorCentinel> {

    /** Vector de cadenas con cada error/mensaje individual registrado en las llamadas a error().. */
    private _vErrors: string[] = [];
    
    /// Flags independientes para activar/desactivar el registro de errores, su visualizacion e incluso la posible parada
    /// en puntos de depuracion con debugger, en las llamadas a las fmc error(). 
    
    /** Activacion de registro de errores. */
    private _enableErrorRegistering: boolean = true;
    /** Activacion de visualizacion de errores. */
    private _enableErrorShowing: boolean = true;
    /** Activacion de la detencion mediante "debugger" en las llamadas a error(). */
    private _enableErrorStopping: boolean = false;
    /** Activacion de mostrar la funcion en el reporte final. */
    private _enableErrorFunction: boolean = false;

    /**
     * La bolsa magica de objetos. A veces necesitamos acceder en una zona del codigo a algo que es imposible que pueda
     * estar alli, o bien que para que este alli deberiamos modificar bastante el codigo que estamos depurando, como
     * por ejemplo en una zona de codigo tenemos disponible una THREE.Scene auxiliar que queremos usar en otra zona en
     * la que esa infraestructura no esta disponible (como en el area de exportacion de cargas del modelo). En tal caso
     * cuando podamos metemos en la bolsa magica a la escena con cierto nombre y luego cuando la necesitemos la sacamos
     * y usamos, e incluso podemos borrarla por si las moscas.
     * Otra utilidad para la bolsa seria meter un Set() para comprobar que ciertos nombres no esten repetidos.
     * Ese es el proposito de nuestra bolsa magica, indexar objetos por nombre y poderlos recuperar cuando queramos, por
     * medio de la propiedad magicBag. Es un simple map del que nos ocupamos en el exterior de esta clase.
     * WARNING: Recuerda en algun momento vaciar la bolsa para evitar posibles memory leaks.
     *
     * @private
     * @type {Map<string, any>}
     * @memberof ErrorCentinel
     */
    private _magicBag: Map<string, any> = new Map();

    /**
     * Cuando hacemos depuraciones graficas podemos tener muchas bolas en escena y eso jode el rendimiento, por ello
     * usaremos esta unica geometria compartida.
     */
    private _geo4Ball025: THREE.SphereBufferGeometry = new THREE.SphereBufferGeometry(0.25, 10, 10);

    /** El numero de bolas agregadas. */
    private _numBalls: number = 0;
    /** El numero de segmentos agregados. */
    private _numSegments: number = 0;

    /** Spatial-hashing para ver las repeticiones. */
    private _mapXYZ: Map<THREE.Vector3, string[]> = new Map();

    /**
     * A veces podemos necesitar contar ciertas cosas o procesos, para lo cual necesitaremos contadores separados.
     * Esos contadores, de la forma "cadena nombre" + valor entero, los almacenaremos en este mapa privado y solo
     * podremos acceder a los valores de los mismos por medio de una serie de fmc's get/inc/dec/reset + Counter()..
     */
    private _map4Counters: Map<string, number> = new Map();

    /**
     * Permitimos la creacion de un fichero HTML con una tabla en la que metemos los valores aqui, todos cadenas.
     * La tabla se organiza por R lineas-rows que a su vez se subdividen en una serie de C columnas, de forma que la
     * linea 0 tiene los C titulos y cada una de las siguientes R - 1 lineas contienen los valores:
     * 
     *          +----------+----------+----------+
     *          | Titulo_1 | Titulo_2 | Titulo_3 | (row-0)
     *          +----------+----------+----------+
     *          | Val_1_0  | Val_1_1  | Val_1_2  | (row-1)
     *          +----------+----------+----------+
     *          | Val_2_0  | Val_2_1  | Val_2_2  | (row-2)
     *          +----------+----------+----------+
     *          | Val_3_0  | Val_3_1  | Val_3_2  | (row-3)
     *          +----------+----------+----------+
     *          | Val_4_0  | Val_4_1  | Val_4_2  | (row-4)
     *          +----------+----------+----------+
     *          | Val_5_0  | Val_5_1  | Val_5_2  | (row-5)
     *          +----------+----------+----------+
     *            (col-0)    (col-1)    (col-2)
     */
    private _table: string[][] = [];

    /** Para acceder a los indices de columna de la tabla usando los titulos de las columnas en vez de sus indices. */
    private _mapTitle2Index: Map<string, number> = new Map();

    /**
     * Una cabecera que se saca al principio en la tabla HTML como una especie de titulo introductorio.
     * Para asignarla se usa la fmc setHeader4Table().
     */
    private _header4Table: string = "";

    constructor() {
        super();
        const date = new Date().toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            year: "numeric",
        });
        this.error("ErrorCentinel unique instance created on " + date);
    }

    reset(): void {
        this._vErrors.length = 0;
        this._enableErrorRegistering = true;
        this._enableErrorShowing = true;
        this._enableErrorStopping = false;
        this._enableErrorFunction = false;
        // En la bolsa magica solo borramos los objetos que no empiezan por "+".
        // Asi mantenemos cosas como la escena auxiliar, id y nombre del proyecto, etc...
        for (const key of this._magicBag.keys()) {
            if (key[0] !== "+") {
                this._magicBag.delete(key);
            }
        }
        
        this._numBalls = 0;
        this._numSegments = 0;
        this._mapXYZ.clear();
    
        this._map4Counters.clear();
    
        this._table.length = 0;;
    
        this._mapTitle2Index.clear();
        this._header4Table = "";
    }

    /**
     * Activa/desactiva el registro de errores en llamadas a addError. Ideal para dejar zonas de codigo sin que produzcan errores.
     * Ojo, que el registro y la visualizacion son independientes.
     *
     * @param {boolean} onOff
     * @memberof ErrorCentinel
     */
    setErrorRegistering(onOff: boolean): void {
        this._enableErrorRegistering = onOff;
    }

    /**
     * Activa/desactiva la visualizacion de errores. Ideal para dejar zonas de codigo sin que muestren errores.
     * Ojo, que el registro y la visualizacion son independientes.
     *
     * @param {boolean} onOff
     * @memberof ErrorCentinel
     */
    setErrorShowing(onOff: boolean): void {
        this._enableErrorShowing = onOff;
    }

    /**
     * Activa/desactiva la detencion en llamadas a error(), pero en aquellas que sean detenibles.
     *
     * @param {boolean} onOff
     * @memberof ErrorCentinel
     */
    setErrorStopping(onOff: boolean): void {
        this._enableErrorStopping = onOff;
    }

    setErrorFunction(onOff: boolean): void {
        this._enableErrorFunction = onOff;
    }

    /** Propiedad RO para el acceso a la bolsa magica. */
    get magicBag() {
        return this._magicBag;
    }

    get numBalls() {
        return this._numBalls;
    }

    /** Devuelve el valor numerico actual del contador especificado por el nombre. */
    getCounter(name: string): number {
        if (!this._map4Counters.has(name)) {
            // Si no existe se crea directamente con valor 0.
            this._map4Counters.set(name, 0);
        }

        return this._map4Counters.get(name) as number;
    }

    /** Incrementa el contador en una unidad y devuelve su valor actual. */
    incCounter(name: string): number {
        let value = this.getCounter(name);
        value += 1;
        this._map4Counters.set(name, value);
        return value;
    }

    /** Decrementa el contador en una unidad y devuelve su valor actual. */
    decCounter(name: string): number {
        let value = this.getCounter(name);
        value -= 1;
        this._map4Counters.set(name, value);
        return value;
    }

    /** Resetea a 0 el contador dado. Como siempre si no existe se crea. */
    resetCounter(name: string) {
        this._map4Counters.set(name, 0);
    }
    
    /**
     * Funcion principal que emite un mensaje informativo de error que es registrado y acumulado para el informe final.
     * Opcionalmente el mensaje puede ser mostrado por consola, registrado junto con su funcion caller e incluso podria
     * generarse una detencion en el codigo de depuracion mediante un debugger.
     *
     * @param {string} msg
     * @param {boolean} [isStoppable=false]
     * @memberof ErrorCentinel
     */
    error(msg: string, isStoppable: boolean = false ): void {
        if (this._enableErrorShowing) {
            console.error(msg);
        }
        if (this._enableErrorRegistering) {
            if (this._enableErrorFunction) {
                // Sacamos el nombre de la funcion generatriz del error que incorporamos al mensaje.
                const callerFunctionName = ErrorCentinel.getCallerFunctionName();
                if (callerFunctionName.length) {
                    msg += "\n\t" + callerFunctionName;
                }
            }
            this._vErrors.push(msg);
        }
        if (isStoppable && this._enableErrorStopping) {
            debugger;
        }
    }

    download(): void {
        const date = new Date();
        this.error(" ===> SAVING ERRORS REPORT on " + date.toLocaleTimeString());
        let txt = "";
        let indice = 1;
        for (const line of this._vErrors) {
            txt += `[${indice}] ` + line + "\n";
            ++indice;
        }
        saveFile(txt, "errors_report.txt", "application/json");
        this._vErrors.length = 0;
        txt = "";
    }

    /**
     * Enorme utilidad para saber el nombre de la funcion desde la que se incorporo el error.
     *
     * @private
     * @static
     * @return {*}  {string}
     * @memberof ErrorCentinel
     */
    private static getCallerFunctionName(): string {
        // Esta especie de excepcion genera un stack...
        const auxError = new Error('SyntheticError');
        // del que podemos recuperar el nivel desde el que nos llamaron externamente.
        if (auxError.stack && auxError.stack.length) {
            const vLines = auxError.stack!.split('\n').slice(1, 6);
            for (let line of vLines) {
                if (line.includes(ErrorCentinel.name)) {
                    continue;
                }
                line = line.trim();
                return line;
            }
        }        
        return "";
    }

    /**
     * Permite ver las propiedades de un objeto con una estructura arborea y nivel de recursion.
     * El nombre se suministra inicialmente para que se vea como se llama la variable implicada.
     * No usar el parametro level.
     *
     * @static
     * @param {*} obj
     * @param {string} [objName=""]
     * @param {number} [level=0]
     * @memberof ErrorCentinel
     */
    public static seeObjectProperties(obj: any, objName: string = "", level: number = 0): void {
        const N = Object.keys(obj).length;
        let tab = "    ".repeat(level);

        if (objName.length) {
            if (typeof(obj) !== "object") {
                console.log(`${tab}${objName} as ${typeof(obj)} {${N} props}:`);
            } else {
                console.log(`${tab}${objName} {${N} props}:`);
            }
        }
        if (N) {
            ++level;
            tab = "    ".repeat(level);
            let i = 0;
            for (const [key, value] of Object.entries(obj)) {
                if (typeof(value) !== "object") {
                    console.log(`${tab}[${i}/${N}] "${key}": <${value}> as ${typeof(value)}`);
                } else {
                    const name = `[${i}/${N}] "${key}"`;
                    ErrorCentinel.seeObjectProperties(value, name, level);
                }
                ++i;
            }
        }
    }

    /**
     * Agrega una bola de radio 0.25 metros y color rojo para servir como mecanismo de depuracion en la escena dada y
     * en las coordenadas dadas.
     *
     * @param {THREE.Scene} scene
     * @param {number} x
     * @param {number} y
     * @param {number} z
     * @return {*}  {THREE.Object3D}
     * @memberof ErrorCentinel
     */
    public addDebugBall(scene: THREE.Scene, x: number, y: number, z: number): THREE.Mesh {
        const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const ball = new THREE.LineSegments(this._geo4Ball025, lineMaterial);
        ball.position.set(x, y, z);
        // Le damos una rotacion aleatoria para diferenciar posibles superposiciones.
        // ball.quaternion.random();
        ball.rotateX(THREE.MathUtils.randFloatSpread(Math.PI));
        ball.rotateY(THREE.MathUtils.randFloatSpread(Math.PI));
        ball.rotateZ(THREE.MathUtils.randFloatSpread(Math.PI));

        ball.name = "debugBall_" + this._numBalls.toString().padStart(5, "0");
        scene.add(ball);
        // console.error(`Added new debug ball [${this._numBalls}] with name "${ball.name}".`);
        this._numBalls += 1;

        // Comprobamos que no se repiten posiciones.
        if (true) {
            this.addXYZ(ball as unknown as THREE.Mesh);
        }
        return ball as unknown as THREE.Mesh;
    }

    /** Permite el cambio de un color previamente asignado a un material a uno nuevo, aleatorio y no muy oscuro. */
    public static change2RandomColor(obj: THREE.Mesh): void {
        const color = ErrorCentinel.getRandomColor();
        (obj.material as THREE.LineBasicMaterial).color = color;
    }

    public static getRandomColor(): THREE.Color {
        let r = 0, g = 0, b = 0;
        // Para evitar colores demasiado oscuros.
        while (r < 0.1 && g < 0.1 && b < 0.1) {
            r = Math.random();
            g = Math.random();
            b = Math.random();
        }
        return new THREE.Color(r, g, b);
    }

    public static change2Color(obj: THREE.Mesh, red01: number, green01: number, blue01: number): void {
        (obj.material as THREE.LineBasicMaterial).color = new THREE.Color(red01, green01, blue01);
    }

    /**
     * Crea y devuelve un segmento entre los puntos dados.
     *
     * @param {THREE.Scene} scene
     * @param {number} xA
     * @param {number} yA
     * @param {number} zA
     * @param {number} xB
     * @param {number} yB
     * @param {number} zB
     * @return {*}  {THREE.Object3D}
     * @memberof ErrorCentinel
     */
    public addDebugSegmentAB(
        scene: THREE.Scene,
        xA: number, yA: number, zA: number,
        xB: number, yB: number, zB: number
    ): THREE.Mesh {
        const vPoints = [ new THREE.Vector3(xA, yA, zA), new THREE.Vector3(xB, yB, zB), ];
        const geometry = new THREE.BufferGeometry().setFromPoints(vPoints);
        const lineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const segmentAB = new THREE.Line(geometry, lineMaterial);

        segmentAB.name = "debugLine_" + this._numSegments.toString().padStart(5, "0");
        scene.add(segmentAB);
        // console.error(`Added new debug segment [${this._numSegments}] with name "${segmentAB.name}".`);
        this._numSegments += 1;
        return segmentAB as unknown as THREE.Mesh;
    }

    private addXYZ(obj: THREE.Mesh): boolean {
        // Recorremos todos los objetos para ver si alguno tiene una distancia al objeto que sea de 1 mm.
        const newPosition = obj.position;
        const newName = obj.name;
        for (const keyPosition of this._mapXYZ.keys()) {
            const distance = newPosition.distanceTo(keyPosition);
            if (distance <= 0.001) {
                const vNames = this._mapXYZ.get(keyPosition) as string[];
                vNames.push(newName);
                return false;
            }
        }
        // Llegados aqui es que no hemos encontrado donde meter el item.
        this._mapXYZ.set(newPosition, [newName]);
        return true;
    }

    /**
     * Imprime por consola un mensaje con un recuadro adecuado a las dimensiones del texto, de la forma:
     *  +------------+
     *  | Hola mundo |
     *  +------------+
     * Ademas lo separa un espacio por la izquierda. Como parametro opcional podemos agregar un texto con un formato
     * CSS para embellecer el mensaje final. Sintetizado a partir de:
     * https://www.samanthaming.com/tidbits/40-colorful-console-message/
     *
     * @static
     * @param {string} msg
     * @param {string} [cssFmt]
     * @memberof ErrorCentinel
     */
    public static printBox(msg: string, cssFmt?: string, leftGap?: string): void {
        if (!leftGap) {
            leftGap = " ";
        }
        const width = msg.length + 2;
        const bar = '-'.repeat(width);
        let txt = leftGap + "+" + bar + "+\n";
        txt += leftGap + "| " + msg + " |\n";
        txt += leftGap + "+" + bar + "+";
        if (cssFmt) {
            console.log(`%c${txt}`, cssFmt);            
        } else {
            console.log(`%c${txt}`, 'color: yellow');
        }
    }

    /**
     * Para meter todos los valores de una fila de una tacada, pero como es incomodo seria mejor meterlos de otra forma.
     *
     * @param {string[]} vRow
     * @memberof ErrorCentinel
     */
    public addTableValues(vRow: string[]): void {
        if (this._table.length && vRow.length === this._table[0].length) {
            this._table.push(vRow);
        } else {
            window.alert("Error: Non-titled or empty table or invalid number of column values.");
        }
    }

    public setTableTitles(vRow: string[]): void {
        // Solo se puede hacer cuando la tabla esta vacia.
        if (this._table.length === 0 && vRow.length) {
            // Primero hacemos el hashing de columnas para poder usar sus indices mediante sus columnas, que deberan ser
            // todas diferentes.
            if (this._mapTitle2Index.size) {
                this._mapTitle2Index.clear();
            }

            // No puede haber titulos repetidos. Hacemos un conjunto y contamos los elementos...
            const set = new Set<string>(vRow);
            if (set.size === vRow.length) {
                for (let i = 0; i < vRow.length; ++i) {
                    this._mapTitle2Index.set(vRow[i], i);
                }
                this._table.push(vRow);
                return;
            }
        }

        window.alert("Error: Non-empty table or empty/duplicated titles.");
    }

    public addTableEmptyRow(): void {
        if (this._table.length) {
            const numColumns = this._table[0].length;
            // Creamos un array con entradas vacias que ya se ira rellenando usando las facilidades del hashing.
            // A lo guarro para asegurar que no hay problemas.
            const vVoid: string[] = [];
            for (let i = 0; i < numColumns; ++i) {
                vVoid.push("");
            }
            this.addTableValues(vVoid);
        } else {
            window.alert("Error: Non-titled and empty table.");
        }
    }

    public setColumnValue(title: string, value: string): void {
        if (this._mapTitle2Index.has(title)) {
            const index = this._mapTitle2Index.get(title) as number;
            this._table[this._table.length - 1][index] = value;
        } else {
            window.alert(`Error "${title}": Invalid title for column.`);
        }
    }

    public setHeader4Table(newText: string): void {
        this._header4Table = newText;
    }

    public saveHTMLFile4Table(): void {
        let txt = [
            "<!DOCTYPE html>",
            "<html>",
            "<style type=\"text/css\">",
            "\t .tg  {border-collapse:collapse;border-color:#aabcfe;border-spacing:0;}",
            "\t .tg td{background-color:#e8edff;border-color:#aabcfe;border-style:solid;border-width:1px;color:#669;",
            "\t\t font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}",
            "\t .tg th{background-color:#b9c9fe;border-color:#aabcfe;border-style:solid;border-width:1px;color:#039;",
            "\t\t font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}",
            "\t .tg .tg-hmp3{background-color:#D2E4FC;text-align:left;vertical-align:top}",
            "\t .tg .tg-baqh{text-align:center;vertical-align:top}",
            "\t .tg .tg-mb3i{background-color:#D2E4FC;text-align:right;vertical-align:top}",
            "\t .tg .tg-lqy6{text-align:right;vertical-align:top}",
            "\t .tg .tg-0lax{text-align:left;vertical-align:top}",
            "</style>",
            "<body>",
            // Esto es opcional. Un titulo antepuesto a la tabla.
            this._header4Table.length ? `<h2>${this._header4Table}</h2>` : "",
            "<table class=\"tg\" style=\"width:100%\">",
            "<tbody>",
        ].join('\n');

        // Numero de filas/lineas horizontales.
        const R = this._table.length;
        for (let ir = 0; ir < R; ++ir) {
            const vRow = this._table[ir];
            const C = vRow.length;
            const td_th = ir ? "td" : "th";
            const theClass = (ir !== 0) ? (ir % 2 ? "tg-hmp3" : "tg-0lax") : "tg-baqh";
            txt += `\t<tr>\n`;
            
            for (let jc = 0; jc < C; ++jc) {
                const value = vRow[jc];
                txt += `\t\t<${td_th} class="${theClass}">${value}</${td_th}>\n`;
            }

            txt += `\t<tr>\n`;
        }

        txt += [
            "</body>",
            "</table>",
            "</body>",
            "</html>",
        ].join('\n');

        // Finalmente salvamos todo el texto con el HTML a disco.
        saveFile(txt, `loads_table.html`, "application/json");
        // Borramos todo una vez salvado.
        txt = "";
        this._table.length = 0;
    }

    public saveHTMLFile4Table_Old(): void {
    
        let txt = `<!DOCTYPE html>
            <html>
            <style>
            table, th, td {
            border:1px solid black;
            }
            </style>
            <body>
            
            <h2>Table with all exported loads</h2>
            
            <table style="width:100%">`;

        // Numero de filas/lineas horizontales.
        const R = this._table.length;
        for (let ir = 0; ir < R; ++ir) {
            const vRow = this._table[ir];
            const C = vRow.length;
            const td_th = ir ? "td" : "th";
            txt += `\t<tr>\n`;
            
            for (let jc = 0; jc < C; ++jc) {
                const value = vRow[jc];
                txt += `\t\t<${td_th}>${value}</${td_th}>\n`;
            }

            txt += `\t<tr>\n`;
        }

        txt += `</table>        
            </body>
            </html>`;

        // Finalmente salvamos todo el texto con el HTML a disco.
        saveFile(txt, `loads_table.html`, "application/json");
        // Borramos todo una vez salvado.
        txt = "";
        this._table.length = 0;
    }

    /**
     * Cuando queremos ejecutar opcionalmente un punto de parada con debugger en nuestro codigo y le preguntamos al
     * usuario por ello.
     *
     * @param {string} message
     * @return {*}  {boolean}
     * @memberof ErrorCentinel
     */
    public confirmDebug(message: string): boolean {
        if (window.confirm(message)) {
            debugger;
            return true;
        } else {
            return false;
        }
    }

} // class ErrorCentinel

// Creamos aqui directamente la instancia para que este disponible para el resto del universo.
export const i_ECS = ErrorCentinel.createInstance(ErrorCentinel);
const isStarted = function(): boolean {
    console.error("Starting Error Centinel Singleton object...");
    i_ECS.error("Starting ErrorCentinel singleton.");    
    i_ECS.setErrorStopping(true); // Esto no se puede subir al GIT.

    return true;
}();
