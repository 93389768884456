
import * as THREE from "three";
import { IPointMaterial, PointMaterialCache } from "./point";
import { ILineMaterial, LineBasicMaterialCache, LineMaterialType } from "./line";
import { LineMaterialCache } from "./line-thick";
import { ISolidMaterial, SolidMaterialCache } from "./solid";
import { LineDashedMaterialCache } from "./line-dashed";
import { IColor } from "lib/math/types";
import { materialType } from "lib/models/types";
import { copyColor } from "lib/styles/colors";

export interface IBaseMaterial {
  color: IColor;
};

export const defaultColor: IColor = { r: 255, g: 255, b: 255, a: 1 };
export function setDefaultColor(c: IColor) {
  defaultColor.r = c.r;
  defaultColor.g = c.g;
  defaultColor.b = c.b;
  defaultColor.a = c.a;
}
export let defaultLineWidth: number = 1;

export let defaultLineStyleId: string = "";
export function setDefaultLineStyleId(id: string) {
  defaultLineStyleId = id;
}

export type MaterialType = IPointMaterial | ILineMaterial | ISolidMaterial;
export type ThreeMaterial = THREE.PointsMaterial | LineMaterialType | THREE.MeshPhongMaterial;

// Material performance cost goes like this:
//  MeshBasicMaterial < MeshLambertMaterial < MeshPhongMaterial < MeshStandardMaterial < MeshPhysicalMaterial

class MaterialCache {

  private pointMaterialCache = new PointMaterialCache();
  private lineBasicMaterialCache = new LineBasicMaterialCache();
  private lineMaterialCache = new LineMaterialCache();
  private lineDashedMaterialCache = new LineDashedMaterialCache();
  private solidMaterialCache = new SolidMaterialCache();

  public getPointMaterial(mat: IPointMaterial) {
    return this.pointMaterialCache.getMaterial(mat);
  }

  public getSolidMaterial(mat: ISolidMaterial) {
    return this.solidMaterialCache.getMaterial(mat);
  }

  public getMaterial(mat: ILineMaterial) {
    if (isLineBasicMaterial(mat)) {
      return this.lineBasicMaterialCache.getMaterial(mat);

    } else if (isLineDashedMaterial(mat)) {
      return this.lineDashedMaterialCache.getMaterial(mat);

    } else if (isLineMaterial(mat)) {
      return this.lineMaterialCache.getMaterial(mat);
    }
    throw new Error("Material cache unknown");
  }

  public infoCacheMaterials() {
    console.log("[MATERIAL_CACHE] pointMaterialCache size = " + this.pointMaterialCache.size);
    console.log("[MATERIAL_CACHE] lineBasicMaterialCache size = " + this.lineBasicMaterialCache.size);
    console.log("[MATERIAL_CACHE] lineMaterialCache size = " + this.lineMaterialCache.size);
    console.log("[MATERIAL_CACHE] lineDashedMaterialCache size = " + this.lineDashedMaterialCache.size);
    console.log("[MATERIAL_CACHE] solidMaterialCache size = " + this.solidMaterialCache.size);
  }

  public clear() {
    this.pointMaterialCache.clear();
    this.lineBasicMaterialCache.clear();
    this.lineMaterialCache.clear();
    this.lineDashedMaterialCache.clear();
    this.solidMaterialCache.clear();
  }

  public updateLineMaterialResolution(width: number, height: number) {
    this.lineMaterialCache.updateResolution(width, height);
  }

}

export let materialCache: MaterialCache = new MaterialCache();

export function isIPointMaterial(mat: any): mat is IPointMaterial {
  return (mat.color !== undefined && mat.size !== undefined);
}

export function isLineBasicMaterial(mat: any): mat is ILineMaterial {
  return (mat.color !== undefined && mat.width === defaultLineWidth && mat.lineStyleId === defaultLineStyleId);
}

export function isLineDashedMaterial(mat: any): mat is ILineMaterial {
  return (mat.color !== undefined && mat.width === defaultLineWidth && mat.lineStyleId !== defaultLineStyleId);
}

export function isLineMaterial(mat: any): mat is ILineMaterial {
  return (mat.color !== undefined && mat.width !== defaultLineWidth);
}

export function isSolidMaterial(mat: any): mat is ISolidMaterial {
  return (mat.color !== undefined && mat.texture !== undefined);
}

export function copyMaterial<T extends materialType>(mat: T): T {
  if (isIPointMaterial(mat)) {
    return { color: copyColor(mat.color), size: mat.size } as T;
  }
  if (isSolidMaterial(mat)) {
    return { color: copyColor(mat.color), texture: mat.texture } as T;
  }
  // if (isLineBasicMaterial(mat) || isLineDashedMaterial(mat) || isLineMaterial(mat)) {
  return { color: copyColor(mat.color), width: mat.width, lineStyleId: mat.lineStyleId } as T;
}