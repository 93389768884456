import { disposeMainGraphicProcessor, GraphicProcessor, initMainGraphicProcessor } from "lib/graphic-processor";
import { createContext, useContext, useMemo, ReactNode, useEffect } from "react";

const MainGraphicContext = createContext<GraphicProcessor>({} as GraphicProcessor);

const GraphicProcessorProvider = ({ children, name }: { children: ReactNode, name?: string }) => {

  useEffect(() => {
    console.log("INSTANCIATED GRAPHICPROCESSOR-000")
    return () => {
      console.log("DISPOSE GRAPHICPROCESSOR-000")
      disposeMainGraphicProcessor();
    }
  }, [])

  const graphicProc = useMemo(() => initMainGraphicProcessor(name), [name])

  // Todo lo que este dentro de "children" podra usar el contexto del que sacaran el graphicProcessor000.
  return (
    <MainGraphicContext.Provider value={graphicProc}>
      {children}
    </MainGraphicContext.Provider>
  )
};

const useMainGraphicContext = () => {
  const context = useContext(MainGraphicContext);
  if (Object.keys(context).length === 0) {
    throw new Error(`useGraphicContext must be used within a GraphicProcessorProvider`);
  }
  return context;
};

export { GraphicProcessorProvider, useMainGraphicContext };