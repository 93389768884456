import { MeshGenParams, MeshProject } from "modules/struc/models/ecore/mesh";
import { GraphicProcessor } from "lib/graphic-processor";
import { meshManager } from "lib/models-struc/mesh/meshmodel-manager";
import { getImporterFromTypeString } from "./building-element";
import { CrossSectionShapeSet } from 'modules/struc/models/ecore/cross-section-shape';
import { getCrossSectionShapeRef } from './cross-section-shapes';
import { MaterialSet, Storey as StoreyEcore } from 'modules/struc/models/ecore/base';
import { createEmptyStorey } from 'modules/struc/utils/storeys';
import { LayerManager } from 'lib/layers/layer-manager';
import { dataModelPersistence, ICADModelPersistence } from 'lib/input-output/database/loader';
import { initAnalisisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { meshDispatcher } from "lib/models-struc/mesh/dispatcher";
import { hypothesisDispatcher } from "lib/models-struc/hypothesis/dispatcher";
import { ModelDataImporter } from "lib/input-output/database/data-model-import";
import { standard } from "modules/struc/models/codes";
import { Storey } from "lib/models-struc/struc-storey";
import { structuralType } from "modules/struc/models/ecore/structural";
import { ILogger } from "shared/utils/logger/logger";

export class EcoreMeshImporter {

  constructor(
    private graphicProcessor: GraphicProcessor,
    private logger: ILogger) { }

  importMeshProject(ecoreData: MeshProject) {
    // Clean all data
    this.cleanWorkspace();
    // Parse Data
    const { Content, storeys } = this.parseEcoreData(ecoreData);
    // Load Data
    this.loadEcoreData(Content, storeys);
  }

  private cleanWorkspace() {
    const graphProc = this.graphicProcessor;
    const strcMng = graphProc.getStructuralModelManager();
    strcMng.clearAll();
    const dataMdlMng = graphProc.getDataModelManager();
    dataMdlMng.clearAll();
    dataMdlMng.layerManager.layerObserver.dispatchLoadLayers();

    meshManager.mesh.project = undefined;
    graphProc.destroyStructModel3D();
    meshDispatcher.dispatchLoadMeshProp(meshManager.mesh.params);

    hypothesisManager.clearAll();
    hypothesisDispatcher.dispatchLoad();

    initAnalisisManager();

    const scenMng = graphProc.getSceneManager();
    scenMng.resetRootGroup();
  }

  private parseEcoreData(ecoreData: MeshProject): { Content: ICADModelPersistence; storeys: Storey[]; } {
    const matRefs = this.importMaterialSets(ecoreData.materialset);

    const ecoreVersion = ecoreData.versions[0];
    this.importMeshParams(ecoreVersion.meshgenparams);

    const cssRefs = this.importCrossSectionShapes(ecoreVersion.crosssectionshapeset);
    const projMng = this.graphicProcessor.getProjectModelManager();
    projMng.project.city = ecoreData.site.city;
    projMng.project.country = ecoreData.site.country;

    const layerMng = new LayerManager();
    const lyrStoreys = layerMng.addLayer("Storeys");
    layerMng.setCurrentLayer(lyrStoreys);

    const objDatas: dataModelPersistence[] = [];
    const ecoreStoreys = ecoreVersion.building.storeys;
    const storeys: Storey[] = [];
    for (let i = 0; i < ecoreStoreys.length; i++) {
      const ecoreStorey = ecoreStoreys[i];
      const storey = this.createStorey(ecoreStorey, i, projMng.project.footingLevel, projMng.project.concrete_standard);
      storeys.push(storey);
      const storeyLayer = layerMng.addLayer(storey.name);

      const ecoreElements = (ecoreStorey.elements ?? []) as structuralType[];
      for (const ecoreElement of ecoreElements) {
        const importer = getImporterFromTypeString(ecoreElement, this.logger);
        if (importer) {
          const data = importer.getObjData(storey, storeyLayer.id, cssRefs, matRefs);
          objDatas.push(data);
        }
      }
    }
    const Content: ICADModelPersistence = {
      layerData: layerMng.exportLayerModel(),
      objData: objDatas,
      blockData: [],
    };
    return { Content, storeys };
  }

  private loadEcoreData(Content: ICADModelPersistence, storeys: Storey[]) {
    const modelLoader = new ModelDataImporter(this.graphicProcessor);
    modelLoader.openProjectCAD(Content);

    const projMng = this.graphicProcessor.getProjectModelManager();
    const building = projMng.project.sites[0].buildings[0];
    building.clear();
    building.addStoreys(storeys);
    const strMng = this.graphicProcessor.getStructuralModelManager();
    strMng.registerStoreyLayers();

    modelLoader.drawProjectCAD();
  }


  private createStorey(ecoreStorey: StoreyEcore, i: number, footingLevel: number, concrete_standard: standard): Storey {
    const newStorey = createEmptyStorey(i, footingLevel, concrete_standard);
    newStorey.floor_name = ecoreStorey.name;
    newStorey.height = ecoreStorey.height ?? 3;
    newStorey.level = ecoreStorey.elevation ?? 0;
    const storey = new Storey();
    storey.setFromStrucStorey(newStorey);
    return storey;
  }

  private importMaterialSets(materialSet: MaterialSet): string[] {
    const mat: string[] = [];
    for (const material of materialSet.materials) {
      mat.push(material.name);
    }
    return mat;
  }

  private importCrossSectionShapes(crossSectionShapesSet: CrossSectionShapeSet): string[] {
    const ids: string[] = [];
    const css = crossSectionShapesSet.crosssectionshapes;
    for (const crossSectionShape of css) {
      const id = getCrossSectionShapeRef(crossSectionShape);
      ids.push(id);
    }
    return ids;
  }

  private importMeshParams(meshgenparams: MeshGenParams): void {
    meshManager.editMeshParam({
      beamSize: meshgenparams.beam_size,
      mergeTolerance: meshgenparams.merge_tolerance,
      mpcThres: meshgenparams.mpc_thres,
      nodalBcThres: meshgenparams.nodal_bc_thres,
      shellSize: meshgenparams.shell_size,
    });
  }
}
