import * as THREE from "three";
import { IPoint } from "../../math/types";
import { cadOpType } from "../factory";
import { copyIPoint } from "../../math/point";
import { lineAddVertex, lineAuxCreate, lineMoveVertex } from "../../geometries/line";
import { polygonBuffer, polygonCreate, polygonParam } from "../../geometries/polygon";
import { setPosBuffer } from "../../geometries";
import { vectorDist3D } from "../../math/distance";
import { lineAngle2p, normalizeAngle } from "../../math/angles";
import { PolygonCommand } from "../../commands/primitives/polygon";
import { settingsOpModes } from "../step-operations";
import { WireframeOP } from "./wireframe";
import { getCurrentLineMaterial } from "lib/materials";

export class PolygonOP extends WireframeOP {
  public opType = cadOpType.POLYGON;
  public static sampleData: polygonParam = {
    angleO: 0,
    center: { x: 0, y: 0, z: 0 },
    plane: { x: 0, y: 0, z: 0 },
    radius: 1,
    sides: 6,
    inscribed: true,
  };

  public auxPoly: THREE.Line;
  public auxLine: THREE.Line;

  public center: IPoint = { x: 0, y: 0, z: 0 };
  public inscribed: boolean = PolygonOP.sampleData.inscribed;
  public sides: number = PolygonOP.sampleData.sides;
  public radius: number = PolygonOP.sampleData.radius;
  public angleO: number = PolygonOP.sampleData.angleO;

  protected iniSettingsOp() {
    this.settingsOpManager.setCfg([
      {
        infoMsg: `Insert center or [Enter "s" to set sides]: `,
        stepMode: settingsOpModes.DEFAULTXYZ,
        cmdLineListener: (cmd: string) => {
          if (cmd === "s") {
            this.setNextStep(2);
          } else {
            this.addPointFromExt(cmd);
          }
        },
      }, {
        infoMsg: "Insert radius: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.radius).toFixed(3),
        cmdLineListener: (cmd: string) => {
          this.radius = parseFloat(cmd);
          this.endOperation();
        },
      }, {
        infoMsg: "Insert side: ",
        stepMode: settingsOpModes.ONEBOX,
        currValue: () => (this.sides).toFixed(0),
        cmdLineUpdateListener: (cm: string) => {
          this.sides = parseInt(cm)
          this.calculatePolygon();
        },
        cmdLineListener: (cmd: string) => {
          this.sides = parseInt(cmd);
          this.setNextStep(0);
        },
      },
    ]);
  }
  public start() {
    super.start();
    this.initializePolygon();
  }

  private initializePolygon(): void {
    this.center = PolygonOP.sampleData.center;
    this.auxPoly = polygonCreate(
      this.center,
      this.radius,
      this.sides,
      this.inscribed,
      this.angleO,
      undefined
    );
    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxPoly);
    this.saveToTempScene(this.auxLine);
  }

  public setLastPoint() {
    if (this.numPoints === 1) {
      const { x, y, z } = this.lastPoint;
      lineAddVertex(this.auxLine, x, y, z);
      lineAddVertex(this.auxLine, x, y, z);
      this.center = copyIPoint(this.lastPoint);

      const planeManager = this.graphicProcessor.getPlaneManager();
      planeManager.activePlane.position = this.center;
      planeManager.activePlane.locked = true;
      this.setNextStep();
    } else {
      this.radius = vectorDist3D(this.lastPoint, this.center);
      this.endOperation();
    }
  }
  public moveLastPoint(pto: IPoint) {
    if (this.numPoints === 0) {
      this.center = copyIPoint(pto);
      this.calculatePolygon();
    } else if (this.numPoints > 0) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
      this.radius = vectorDist3D(pto, this.center);
      this.calculatePolygon(pto);
    }
  }
  public calculatePolygon(secondPoint?: IPoint): void {
    if (secondPoint) {
      const c = this.currPlane.getRelativePoint(this.center);
      const p = this.currPlane.getRelativePoint(secondPoint);
      this.angleO = normalizeAngle(lineAngle2p(c, p));
    }
    let coords = polygonBuffer(
      this.center,
      this.radius,
      this.sides,
      this.inscribed,
      this.angleO,
      this.currPlane.rotation
    );
    if (!coords) coords = new Float32Array(0);
    setPosBuffer(this.auxPoly, coords);
  }

  public save() {
    if (this.graphicProcessor) {
      const polygon: polygonParam = {
        center: this.center,
        radius: this.radius,
        sides: this.sides,
        inscribed: this.inscribed,
        angleO: this.angleO,
        plane: this.currPlane.rotation,
      };
      const command = new PolygonCommand(
        polygon,
        this.getCurrentSceneId(),
        this.graphicProcessor,
        getCurrentLineMaterial({ lineStyleId: this.lineStyleId })
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }

  public endOperation(): void {
    if (this.radius <= 0) {
      this.numPoints--;
      return;
    }
    if (this.finished === false) {
      PolygonOP.sampleData = {
        angleO: this.angleO,
        center: copyIPoint(this.center),
        radius: this.radius,
        sides: this.sides,
        inscribed: this.inscribed,
        plane: this.currPlane.rotation,
      };
      this.save();
    }
    super.endOperation();
  }
}
