import { BlockManager } from "lib/blocks";
import { EventBus } from "lib/events/event-bus";
import { GraphicProcessor } from "lib/graphic-processor";
import { LayerManager } from "lib/layers/layer-manager";
import { getDataModelBuilder } from "lib/models/model-creator/datamodel-factory";
import { DataModelManager } from "lib/models/datamodel-manager";
import { ICADModelPersistence } from "lib/input-output/database/loader";
// By FGM.
import { objDataType } from "lib/models/types";

export class ModelDataImporter {

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  openProjectCAD(data: ICADModelPersistence) {
    EventBus.enableDispatch = false;
    console.info("[LOADER] Load model from JSON");
    const t0 = performance.now();

    // Read persisted data from json
    const projectHasData = data && data.layerData && data.objData && data.blockData;
    if (projectHasData) {
      this.loadDataPersisted(data);
    }

    const t1 = performance.now();
    console.info("[LOADER] Model loaded " + (t1 - t0).toFixed(3) + " milliseconds.");
    this.graphicProcessor.getDataModelManager().getModelInfo();
    EventBus.enableDispatch = true;
  }
  drawProjectCAD() {
    const modelManager = this.graphicProcessor.getDataModelManager();
    const layerManager = modelManager.layerManager;
    this.addObjDatasToLayers(layerManager, modelManager);
  }

  /**
   * Etapa final de la carga del modelo, cuando se crean todos los items individuales con sus partes graficas.
   *
   * @private
   * @param {ICADModelPersistence} dataPersistence
   * @return {*}  {boolean}
   * @memberof ModelDataImporter
   */
  private loadDataPersisted(dataPersistence: ICADModelPersistence): boolean {
    const modelManager = this.graphicProcessor.getDataModelManager();
    const layerManager = modelManager.layerManager;
    layerManager.loadLayerModel(dataPersistence.layerData);
    BlockManager.setBlocksDefinition(dataPersistence.blockData);

    let numLoads = 0;
    const objData = dataPersistence.objData;
    for (const data of objData) {
      const oldId = data.id.toString();
      const dataObj = getDataModelBuilder(data.type, data.definition, data.material);
      // Podria hacer cosas distintas en funcion del tipo...
      let isLoad = false;
      if (data.type === objDataType.LOAD) {
        numLoads += 1;
        isLoad = true;
      }

      dataObj.id = oldId;

      const layerRoot = layerManager.layerTree.props;
      const layerObj = layerManager.getLayerDataFromId(data.layerId);
      dataObj.layerObj = layerObj ? layerObj : layerRoot;
      dataObj.layerObj.objDatas.push(dataObj);

      dataObj.regenerateReferences(this.graphicProcessor);
      dataObj.createGraphicObj();
      dataObj.isDataLocked = data.isDataLocked;
      dataObj.isDataVisible = data.isDataVisible; // ?

      if (isLoad) {
        // Esto no vale para nada.
        // dataObj.isDataLocked = false;
        // dataObj.isDataVisible = true;
        // Tenemos el problema de que las cargas regeneradas aparecen por primera vez en gris y luego al seleccionarlas
        // nunca vuelven a tener el color original que debieran tener en las cargas.
      }

      modelManager.registerData(dataObj);
      modelManager.mapGraphicObjs.set(dataObj.graphicObj, dataObj);
    }

    console.log(`Generated loads: ${numLoads}`);
    console.log(`[ModelDataImporter.loadDataPersisted(items: ${objData.length})]`);
    return true;
  }

  private addObjDatasToLayers(layerManager: LayerManager, modelManager: DataModelManager) {
    layerManager.iterAllLayers((layerData) => {
      // Consistency in visibility and locked layer Info
      const lyrs = layerManager.getParentLayersDataFromId(layerData.id);
      if (lyrs) {
        const isNotVisible = lyrs.some(lyr => !lyr.visible);
        const isLayerLocked = lyrs.some(lyr => lyr.locked);
        layerData.objDatas.forEach(o => {
          o.isLayerLocked = isLayerLocked;
          o.isLayerVisible = !isNotVisible;
        });
      }
      // Add objData to Layer
      layerData.loadObjDatas(modelManager);
    });
  }

} // class ModelDataImporter

