/**
 * \file windcalculer.ts
 * Implementacion de la clase WindCalculer encargada de la realizacion de los calculos de las cargas de viento.
 * \ToDo: Rotacion del modelo cuando no coincida con los ejes...
 */
import { GraphicProcessor } from 'lib/graphic-processor';
// Vamos a ejecutar todos los calculos geospaciales usando la libreria flatten-js, que se instala asi:
// npm install --save-dev @flatten-js/core
import Flatten from '@flatten-js/core';
import { IPoint } from "lib/math/types";
import { SlabData } from 'lib/models/structural/slab';

import { i_ECS } from "lib/helpers/error-centinel";

// Para simplificar sintaxis usamos LOCALMENTE este tipo para un punto 2D. Ojo que es de uso interno a este modulo, por
// lo que no se debe exportar, aunque probablemente lo repita en otros modulos externos.
type P2D = [number, number];
type P3D = [number, number, number];

type SlabInfo = {
    // Almaceno el nombre del slab implicado, por si es necesario para evitar ambiguedades de recorrido.
    slabName: string,
    // El COG principal del slab ahora sera 3D, para incluir la Z y poder detectar problemas.
    COG3D: P3D;
    area: number;
    // Ademas del box del poligono sacaremos el x_len y el y_len.
    poly: Flatten.Polygon | null;
    index4Storey: number;
    // Los 4 poligonos fruto de las divisiones horizontal (en Y) y vertical (en X),
    polyUp: Flatten.Polygon | null;
    polyDown: Flatten.Polygon | null;
    polyLeft: Flatten.Polygon | null;
    polyRight: Flatten.Polygon | null;
    areaUp: number;
    areaDown: number;
    areaLeft: number;
    areaRight: number;
    cogUp: P2D;
    cogDown: P2D;
    cogLeft: P2D;
    cogRight: P2D;
};

/**
 * Esto es lo que se le devuelve a JaWS como resultado de cada calculo de un slab:
 * [1] El nombre del slab para evitar ambiguedades en el exterior por el orden arriba-abajo o su puta madre.
 * [2] Un vector con EXACTAMENTE los 16 valores de las cargas de viento en cierto orden para el slab implicado.
 * [3] La geometria 2D de cada una de las 4 divisiones del slab (en el orden izquierda-derecha-arriba-abajo) formada
 * cada una de ellas por sus vertices 3D (sin repeticion inicio fin) en el orden CW.
 * Pondre como Z de cada vertice 3D el valor -Infinity para denotar que realmente estamos en 2D.
 */
export interface WindDataOut {
    slabName: string,
    /**
     * El orden devuelto en los 16 valores de cargas, (8 para X mas 8 para Y):
     * Wx1Pos_1, Wx1Pos_2, Wx2Pos_1, Wx2Pos_2, Wx1Neg_1, Wx1Neg_2, Wx2Neg_1, Wx2Neg_2,
     * Wy1Pos_1, Wy1Pos_2, Wy2Pos_1, Wy2Pos_2, Wy1Neg_1, Wy1Neg_2, Wy2Neg_1, Wy2Neg_2
     */
    loads: number[],
    /**
     * Los contornos de las 4 mitades del slab, en el orden izquierda-derecha-arriba-abajo.
     */
    vHalfContours: IPoint[][],
}

export class WindCalculer {

    owner_: GraphicProcessor;
    mSlab2Info_: Map<SlabData, SlabInfo>;

    numStoreys_: number;
    numSlabs_: number;

    // Es el porcentaje (%) de excentricidad, en el intervalo [0, 100] que debiera ser asignado por planta en el futuro.
    // De momento se suele tomar el valor (dado externamente) de 5.0 como el 5%.
    eccentricity_: number;

    FxFy_: [number, number][];

    constructor(graPro: GraphicProcessor) {
        this.owner_ = graPro;
        this.mSlab2Info_ = new Map<SlabData, SlabInfo>();
        // Valor inicial invalido que se debe de incorporar externamente.
        this.eccentricity_ = -1.0;
        // Inicialmente vacio y se debe dar desde el exterior.
        this.FxFy_ = [];

        // Del GPO sacamos los datos que necesitemos.
        const structuralModelManager = this.owner_.getStructuralModelManager();
        const allStoreys = structuralModelManager.currBuilding.storeys;
        this.numStoreys_ = 0;
        this.numSlabs_ = 0;
        for (let iSt = 0; iSt < allStoreys.length; ++iSt) {
            const storey = allStoreys[iSt];
            const slabs = storey.slabs;
            for (const slab of slabs) {
                const repr = slab.definition;
                // Datos interesantes que podemos sacar de aqui:
                // Punto base del slab (coordenadas absolutas).
                const bPoint = repr.basePoint;
                // Lista de puntos del contorno (respecto al basepoint).
                const vP2D = repr.ptos2D;
                // Array de listas de puntos (IPoint[][]) (respecto al basepoint).
                const vHoles = repr.holes;
                const numHoles = vHoles.length;
                console.log(`[${this.numStoreys_}-${this.numSlabs_}:"${repr.name}"]`);
                // Ahora tendremos en cuenta el base point para tener coordenadas mundiales.
                const poly = createPolygon(vP2D, bPoint.x, bPoint.y);
                if (poly) {
                    // Por cojones y por el convenio de Flatten nos aseguramos de que el polygono isla recien creado
                    // tenga el ordering CW que le dara area positiva, mientras que sus agujeros tendran el opuesto.
                    if (!setOrderCW2SimpleIslePolygon(poly)) {
                        debugger;
                    }
                } else {
                    debugger;
                }

                // Rellenamos los datos internos.
                const data = createEmptySlabInfo();

                data.slabName = slab.strucName;
                data.index4Storey = iSt;
                if (repr.cog) {
                    // Directamente pues tratamos con coodenadas mundiales absolutas.
                    console.log(`Received COG(${repr.cog.x}, ${repr.cog.y}, ${repr.cog.z})`);
                    data.COG3D[0] = repr.cog.x;
                    data.COG3D[1] = repr.cog.y;
                    data.COG3D[2] = repr.cog.z;
                } else {
                    // Es aqui donde metemos la altura real de la planta, para luego completar los datos.
                    data.COG3D[2] = storey.level;
                }

                if (poly && poly.isValid()) {
                    const area = poly.area();
                    console.log(`\t Area sin holes: ${area}`);

                    if (numHoles) {
                        // Segun es el convenio de flatten, los holes deben darse en sentido contrario al que se dio
                        // la isla principal, aka el contorno del poligono continente.
                        for (const hole of vHoles) {
                            if (!addHole2Polygon(poly, hole, bPoint.x, bPoint.y)) {
                                console.error("No se ha podido agregar el hole al polygon.");
                                debugger;
                            }
                        }
                    }

                    if (poly.isValid()) {
                        data.area = poly.area();
                        data.poly = poly;
                        console.log(`Area con ${numHoles} holes: ${data.area}`);
                    } else {
                        debugger;
                    }

                    this.mSlab2Info_.set(slab, data);
                } else {
                    const msg = "ERROR: El poligono no es valido.";
                    console.error(msg);
                    window.alert(msg);
                    debugger;
                    continue;
                }
                ++this.numSlabs_;
            }
            ++this.numStoreys_;
        }

        console.log(`Storeys[${this.numStoreys_}] ===> Slabs[${this.numSlabs_}]`);

        // Vamos con los COG de los poligonos de los slabs, con o sin agujeros.
        let index = 0;
        for (const data of this.mSlab2Info_.values()) {
            console.log(`Previous calculations for slab [${index}/${this.numSlabs_}] +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+`);
            if (data.poly) {
                // Si no nos han dado la informacion la calculamos nosotros.
                if (data.COG3D[0] === -Infinity && data.COG3D[1] === -Infinity && data.COG3D[2] !== -Infinity) {
                    const cog = calculateCOG4Polygon(data.poly);
                    // Recuerda que la altura ya la habias precalculado.
                    const heightZ = data.COG3D[2];
                    data.COG3D = [cog[0], cog[1], heightZ];
                    console.log(`Slab(${data.slabName}) ===> Calculated by CLIENT COG(${data.COG3D[0].toFixed(3)}, ${data.COG3D[1].toFixed(3)}, ${data.COG3D[2].toFixed(3)})`);
                } else {
                    console.log(`Slab(${data.slabName}) ===> Received from SERVER COG(${data.COG3D[0].toFixed(3)}, ${data.COG3D[1].toFixed(3)}, ${data.COG3D[2].toFixed(3)})`);
                }
                // Aqui creamos las 4 divisiones posibles y sus restantes hemiplanos, areas y COG's.
                if (!calculateHemiPlanesInfo(data)) {
                    console.error("ERROR al calcular los datos de los hemiplanos.");
                }
            }
            ++index;
        }

    }

    get eccentricity(): number {
        return this.eccentricity_;
    }

    set eccentricity(value: number) {
        // Parece ser que es un porcentaje, y por lo tanto esta en el rango [0, 100].
        if (0.0 <= value && value <= 100.0) {
            this.eccentricity_ = value;
        } else {
            const msg = `ERROR: El coeficiente eccentricity no es un portentaje valido (${value}%)`;
            console.error(msg);
            window.alert(msg);
            debugger;
        }
    }

    /**
     * Para fijar desde el exterior la tabla de valores usados: ha de ser del mismo cardinal que el numero de plantas.
     * Ademas deben estar dados en este riguroso orden: De la planta mas baja a la mas alta, es decir en sentido
     * creciente de los pisos.
     */
    set FxFy(vValues: [number, number][]) {
        if (vValues.length === this.numStoreys_) {
            // Por si acaso hacemos una deepCopy.
            this.FxFy_ = vValues.slice(0);
        } else {
            this.FxFy_.length = 0;
            const msg = `ERROR: La tabla FxFy debe tener un tamaño coincidente con el numero de plantas (${this.numStoreys_}).`;
            console.error(msg);
            window.alert(msg);
            debugger;
        }
    }

    /**
     * Fija unos valores aleatorios de prueba tanto para la excentricidad como para los Fx y Fy.
     */
    setRandomValues(): void {
        this.eccentricity = 100 * Math.random();
        const vFxFy: [number, number][] = [];
        for (let iSt = 0; iSt < this.numSlabs_; ++iSt) {
            const fx = Math.random();
            const fy = Math.random();
            vFxFy.push([fx, fy]);
        }
        this.FxFy = vFxFy;
    }

    destructor() {

    }

    /**
     * Calcula y devuelve los datos de las hipotesis de viento, asociadas a cada slab. Se devuelve un array con tantas
     * entradas como slabs haya y cada entrada tiene un componente con los 16 valores de las cargas, en el orden:
     * 
     * asi como la geometria 2D del slab implicado, en orden CW y sin repeticion de puntos primero-ultimo.
     * En caso de error se devuelve el array vacio.
     *
     * @returns 
     */
    calculateWindLoads(): WindDataOut[] {
        const vRes: WindDataOut[] = [];

        // Veamos que disponemos de un coeficiente de excentricidad valido como porcentaje.
        if (0.0 <= this.eccentricity_ && this.eccentricity_ <= 100.0) {
            ; // Ok.
        } else {
            const msg = `ERROR: Por favor introduzca un % de excentricidad valido.`;
            console.error(msg);
            window.alert(msg);
            return vRes;
        }

        if (this.FxFy_.length === 0) {
            const msg = `ERROR: Por favor introduzca la tabla FxFy con un cardinal (${this.numStoreys_}).`;
            console.error(msg);
            window.alert(msg);
            return vRes;
        }

        for (const [slab, info] of this.mSlab2Info_) {
            const poly = info.poly as Flatten.Polygon;
            // De la AABB del poligono sacamos sus dimensiones.
            const xLen = poly.box.xmax - poly.box.xmin;
            const yLen = poly.box.ymax - poly.box.ymin;
            const [Fx, Fy] = this.FxFy_[info.index4Storey];
            const cogA1Y = info.cogUp[1];
            const cogA2Y = info.cogDown[1];
            const midFx = 0.5 * Fx;

            // Correcciones by JL: Ahora la formula esta bien. 2*(A - B) y la excentricidad no es tanto por ciento sino tanto por 1.
            let numerator = this.eccentricity_ * yLen * Fx;
            let denominator = 2 * (cogA1Y - cogA2Y);
            let division = numerator / denominator;
            let add = midFx + division;
            let sub = midFx - division;
            // Aqui asumo del dibujo original que F1 es el valor grande y el F2 el menor, de la unica formula presente.
            // \ToDo: Algun dia preguntar a Paul Gray...
            let F1 = (add > sub) ? add : sub;
            let F2 = (add > sub) ? sub : add;

            const S1_Wx1Pos_1 = F1;
            const S1_Wx1Pos_2 = F2;
            // Lo mismo pero invertido arriba abajo.
            const S1_Wx2Pos_1 = F2;
            const S1_Wx2Pos_2 = F1;
            // Y los cambios de signo.
            const S1_Wx1Neg_1 = -F1;
            const S1_Wx1Neg_2 = -F2;
            const S1_Wx2Neg_1 = -F2;
            const S1_Wx2Neg_2 = -F1;

            // Y ahora nos vamos por los valores con la Y, los de la division vertical.
            const cogA1X = info.cogLeft[0];
            const cogA2X = info.cogRight[0];
            const midFy = 0.5 * Fy;

            numerator = this.eccentricity_ * xLen * Fy;
            denominator = 2 * (cogA1X - cogA2X);
            division = numerator / denominator;
            add = midFy + division;
            sub = midFy - division;
            F1 = (add > sub) ? add : sub;
            F2 = (add > sub) ? sub : add;

            const S1_Wy1Pos_1 = F1;
            const S1_Wy1Pos_2 = F2;
            // Lo mismo pero invertido arriba abajo.
            const S1_Wy2Pos_1 = F2;
            const S1_Wy2Pos_2 = F1;
            // Y los cambios de signo.
            const S1_Wy1Neg_1 = -F1;
            const S1_Wy1Neg_2 = -F2;
            const S1_Wy2Neg_1 = -F2;
            const S1_Wy2Neg_2 = -F1;

            // He aqui los 16 valores de las cargas para este slab.
            const v16LoadValues = [
                S1_Wx1Pos_1, S1_Wx1Pos_2, S1_Wx2Pos_1, S1_Wx2Pos_2, S1_Wx1Neg_1, S1_Wx1Neg_2, S1_Wx2Neg_1, S1_Wx2Neg_2,
                S1_Wy1Pos_1, S1_Wy1Pos_2, S1_Wy2Pos_1, S1_Wy2Pos_2, S1_Wy1Neg_1, S1_Wy1Neg_2, S1_Wy2Neg_1, S1_Wy2Neg_2,
            ];

            // Y sacamos los 4 poligonos de contorno para las 4 divisiones a que hemos sometido el poligono slabe dado
            // inicialmente, en el orden 2 verticales + 2 horizontales = izquierda + derecha + arriba + abajo.
            const vVerticesLeft = getContourPoints(info.polyLeft as Flatten.Polygon);
            const geometryLeft = createList4FlattenPoint2IPoint(vVerticesLeft);
            const vVerticesRight = getContourPoints(info.polyRight as Flatten.Polygon);
            const geometryRight = createList4FlattenPoint2IPoint(vVerticesRight);

            const vVerticesUp = getContourPoints(info.polyUp as Flatten.Polygon);
            const geometryUp = createList4FlattenPoint2IPoint(vVerticesUp);
            const vVerticesDown = getContourPoints(info.polyDown as Flatten.Polygon);
            const geometryDown = createList4FlattenPoint2IPoint(vVerticesDown);

            const dataOut: WindDataOut = {
                slabName: info.slabName,
                loads: v16LoadValues,
                vHalfContours: [geometryLeft, geometryRight, geometryUp, geometryDown],
            };

            vRes.push(dataOut);
        }

        return vRes;
    }

} // class WindCalculer

/**
 * Creador de un poligono 2D simple a partir de sus vertices componentes 3D. No admite poligonos vacios o con cruces,
 * ni tampoco poligonos con diferentes Z en los vertices, para asegurarnos la planicie de los mismos como slabs.
 * En caso de error devuelve null.
 *
 * @param v 
 * @param offsetX 
 * @param offsetY 
 * @returns 
 */
function createPolygon(v: IPoint[], offsetX: number = 0, offsetY: number = 0): Flatten.Polygon | null {
    if (!v.length) {
        return null;
    }
    // Comprobamos que todas las z son iguales.
    if (!checkEqualHeightZ(v)) {
        console.error("ERROR: createPolygon() ===> One of the vertices differs in Z from the remaining!!!.");
        return null;
    }

    // Creamos la lista XY del tipo usado por Flatten.
    const pList = createList4IPoint2FlattenPoint(v);
    let poly = new Flatten.Polygon(pList);
    if (offsetX !== 0 || offsetY !== 0) {
        const vOffsetXY = new Flatten.Vector(offsetX, offsetY);
        poly = poly.translate(vOffsetXY);
    }
    if (!poly.isValid() || poly.isEmpty()) {
        console.error("ERROR: createPolygon() ===> Invalid polygon!!!.");
        return null;
    }

    // Tambien voy a comprobar que NUNCA se repite el primer y el ultimo vertice.
    // \ToDo: Parece que en Flatten nunca pasa y lo quitaremos...
    // if (true) {
    //     const first = poly.vertices[0];
    //     const last = poly.vertices[poly.vertices.length - 1];
    //     if ((first.x === last.x) && (first.y === last.y)) {
    //         console.error("WARNING: First and last vertex are repeated!!!.");
    //         debugger;
    //     }
    // }
    return poly;
}

function setOrderCW2SimpleIslePolygon(poly: Flatten.Polygon): boolean {
    if (!poly.isValid() || poly.isEmpty()) {
        return false;
    }
    if (poly.faces.size !== 1) {
        return false;
    }

    const isleFace = getIsleFace(poly);
    if (isleFace.orientation() === Flatten.ORIENTATION.CCW) {
        isleFace.reverse();
    }
    return true;
}

/**
 * Comprueba que todas las alturas Z dadas entre los puntos sean aproximadamente iguales con un epsilon.
 * @param v 
 * @returns 
 */
function checkEqualHeightZ(v: IPoint[]): boolean {
    // Parece que a veces las alturas no son exactamente identicas y hay que considerar un epsilon. Propongo 1 mm.
    // Lo mejor es usar un medio estadistico, ya que con un bucle a pelo podria ir tolerando holguras sucesivas entre
    // vertices sucesivos que podrian irse acumulando y tocar las narices.
    const kEpsilon = 0.001;
    let zAvg = 0.0;
    const N = v.length;

    for (let i = 0; i < v.length; ++i) {
        zAvg += v[i].z;
    }
    zAvg /= N;

    // En el segundo recorrido compruebo que no se desvie la Z de la zAvg en mas del epsilon.
    for (let i = 0; i < v.length; ++i) {
        if (Math.abs(zAvg - v[i].z) >= kEpsilon) {
            return false;
        }
    }

    return true;
}

/**
 * A partir de un vector de puntos 3D en formato IPoint-JaWS devuelve otro vector de puntos 2D en formato Flatten.
 * @param v 
 * @returns 
 */
function createList4IPoint2FlattenPoint(v: IPoint[]): Flatten.Point[] {
    const rList: Flatten.Point[] = [];
    const N = v.length;

    for (let i = 0; i < N; ++i) {
        rList.push(new Flatten.Point(v[i].x, v[i].y));
    }
    return rList;
}

function createList4FlattenPoint2IPoint(v: Flatten.Point[]): IPoint[] {
    const rList: IPoint[] = [];
    const N = v.length;

    for (let i = 0; i < N; ++i) {
        const p = v[i];
        // Ojo que es punto 2D pues la Z esta inutilizada.
        const p2D: IPoint = {
            x: p.x,
            y: p.y,
            z: -Infinity,
        }
        rList.push(p2D);
    }
    return rList;
}

function addHole2Polygon(poly: Flatten.Polygon, hole: IPoint[], offsetX: number = 0, offsetY: number = 0): boolean {
    if (!poly.isValid() || poly.isEmpty()) {
        return false;
    }

    const sourceArea = poly.area();

    // Creamos un poligono temporal con el agujero.
    const polyHole = createPolygon(hole, offsetX, offsetY) as Flatten.Polygon;
    if (!polyHole.isValid() || poly.isEmpty()) {
        return false;
    }

    const pList = createList4IPoint2FlattenPoint(hole);
    if (offsetX !== 0 || offsetY !== 0) {
        for (const p of pList) {
            p.x += offsetX;
            p.y += offsetY;
        }
    }
    const lastFace = poly.addFace(pList);

    // Los agujeros deben tener siempre area negativa, lo que implica que deben ir en sentido CCW.
    if (lastFace.orientation() === Flatten.ORIENTATION.CW) {
        lastFace.reverse();
    }

    const holeArea = lastFace.signedArea();
    const resultingArea = poly.area();
    if (Math.abs(sourceArea + holeArea - resultingArea) > 0.001) {
        console.error("Error al incorporar agujero???.");
        debugger;
        return false;
    }

    return true;
}

/**
 * NO VALE, NO USAR NUNCA!!!.
 * Hay que usar el teorema de Green, pues esto solo calcula el punto medio estadistico, que NO realmente GEOMETRICO de
 * una serie de puntos sin tener en cuenta su recorrido, por lo tanto trata siempre los mismos puntos de forma igual
 * sin pensar en los distintos recorridos alternativos que estos puntos puedan sufrir:
 * 
 *              O----O          O----O
 *              |    |          |   /
 *              O--O |          O  O
 *                 | |   !==    |  |
 *              O--O |          O  O
 *              |    |          |   \
 *              O----O          O----O
 * 
 * Quizas metiendo las propias aristas o puntos intermedios entre las mismas o triangulando o algo similar...
 * Lo importante es que NO se debe usar mas que el mecanismo dado por el teorema de Green.
 * Aunque parece ser un error muy comun cuando lo miras por interne...
 *
 * Calcula el COG para el poligono simple dado, es decir, sin tener en cuenta a sus posibles holes, solo la face de la
 * island. Pero esto puede estar mal cuando hay holes y aparecen vertices que no estan en la main isle!!!.
 * @param poly 
 */
function calculateCOG4SimplePolygon_BAD(poly: Flatten.Polygon): P2D {
    window.alert("Esto es una simple media aritmetica y no vale!!!.");
    const vVertices = poly.vertices;
    let numVertices = vVertices.length;
    // Ojo que en el vector de vertices el ultimo podria ser EXACTAMENTE igual que el primero???.
    if ((vVertices[0].x === vVertices[numVertices - 1].x) && (vVertices[0].y === vVertices[numVertices - 1].y)) {
        // Pero aparentemente esto en Flatten no debiera pasar nunca.
        debugger;
        --numVertices;
    }

    if (poly.faces.size !== 1) {
        console.error("WARNING: You are calculating using vertices for a posibly hollow polygon!!!.");
        debugger;
    }

    let px = 0.0;
    let py = 0.0;

    for (let i = 0; i < numVertices; ++i) {
        const p = vVertices[i];
        px += p.x;
        py += p.y;
    }

    const cx = px / numVertices;
    const cy = py / numVertices;

    return [cx, cy] as P2D;
}

/**
 * Calcula el centro de gravedad del poligono dado teniendo en cuenta sus posibles agujeros.
 * @param poly 
 */
function calculateCOG4Polygon(poly: Flatten.Polygon): P2D {

    const numFaces = poly.faces.size;
    if (numFaces === 1) {
        const clon = poly.clone();
        const res0 = calculateCOM4SimplePolygon_DEP(clon);
        const res = calculateCOG4SimplePolygon(poly);
        const xx = res0[0] - res[0];
        const yy = res0[1] - res[1];
        const dist = Math.sqrt(xx + yy);
        if (dist > 0.0001) {
            debugger;
        }
        return res;
    }

    // Formulamen sacado de https://math.stackexchange.com/questions/623841/finding-centroid-of-a-polygon-with-holes
    // Necesito saber el area de la isla y de los lagos.
    // Se supone que el area mayor sera la de 0 y sera positiva. Las restantes son menores y las pondre positivas.
    const vAreas: number[] = [];
    // Aqui tenemos los COG's de las faces, comenzando por la isla.
    const vCOGs: P2D[] = [];

    let i = 0;
    // Las faces solo se pueden recorrer con un for-of.
    for (const face of poly.faces) {
        let area = face.signedArea();
        if (i) {
            // Es un hole y debe tener area negativa.
            if (area > 0) {
                i_ECS.error(`ERROR: Positive area ${area} in a hole that we correct 'a hueveision'...`, false);
                area *= -1;
            }
            console.log(`[${i}] Hole   ===> Area: ${area}`);
        } else {
            // Es la isla global y debe tener area positiva.
            if (area < 0) {
                i_ECS.error(`ERROR: Negative area ${area} that we correct 'a hueveision'...`, false);
                area *= -1;
            }
            console.log(`[${i}] Island ===> Area: ${area}`);
        }
        vAreas.push(area);
        const poly4Face = face.toPolygon();
        const cog = calculateCOG4SimplePolygon(poly4Face.clone());

        // if (true) {
        //     const com = calculateCOM4SimplePolygon_DEP(poly4Face.clone());
        //     const xx = com[0] - cog[0];
        //     const yy = com[1] - cog[1];
        //     const distComCog = Math.sqrt(xx + yy);
        //     if (distComCog > 0.001) {
        //         console.error("WARNING: Divergence calculating COG: " + distComCog);
        //         console.log(`\t Green theorem COG: (${cog[0].toFixed(4)}, ${cog[1].toFixed(4)})`);
        //         console.log(`\t Alternative COG:   (${com[0].toFixed(4)}, ${com[1].toFixed(4)})`);
        //         debugger;
        //         const avgCog = calculateCOG4SimplePolygon_BAD(poly4Face.clone());
        //         console.log(`\t Average point COG: (${avgCog[0].toFixed(4)}, ${avgCog[1].toFixed(4)})`);
        //         debugger;
        //     } else {
        //         console.log("Calculos Ok.");
        //     }
        // }

        vCOGs.push(cog);

        ++i;
    }

    // Ahora nos vamos por la formula final, empezando por la isla.
    const isleArea = vAreas[0];
    const isleCOG = vCOGs[0];
    let numeratorX = isleCOG[0] * isleArea;
    let numeratorY = isleCOG[1] * isleArea;
    let denominator: number = isleArea;
    // Y descontando los holes.
    for (let i = 1; i < numFaces; ++i) {
        const iArea = vAreas[i];
        const iCOG = vCOGs[i];
        numeratorX -= iCOG[0] * iArea;
        numeratorY -= iCOG[1] * iArea;
        denominator -= iArea;
    }

    const p: P2D = [numeratorX / denominator, numeratorY / denominator];
    return p;
}

/**
 * Calcula el COM (Center Of Mass) de un poligono simple, sin agujeros, usando el teorema de Green, sacado de:
 * https://demonstrations.wolfram.com/CenterOfMassOfAPolygon/
 * Produce exactamente el mismo resultado que el metodo normal del COG.
 *
 * @param poly 
 * @returns 
 */
function calculateCOM4SimplePolygon_DEP(poly: Flatten.Polygon): P2D {
    // No se pueden usar directamente los vertices del poligono, sino que hay que usar su primera face.
    let vVertices = poly.vertices;
    let numVertices = vVertices.length;
    // Ojo que en el vector de vertices ultimo podria ser igual que el primero.
    if ((vVertices[0].x === vVertices[numVertices - 1].x) && (vVertices[0].y === vVertices[numVertices - 1].y)) {
        --numVertices;
    }

    // El convenio es que si el area es POSITIVA entonces CW.
    let signedArea = 0.0;
    let invert = false;
    for (const face of poly.faces) {
        signedArea = face.signedArea();
        if (signedArea > 0.0) {
            invert = true;
        }
        break;
    }
    const area = poly.area();

    if (invert) {
        // Le damos la vuelta a los vertices con una deep-copy.
        let vAux: Flatten.Point[] = [];
        for (let i = 0; i < numVertices; ++i) {
            const p = vVertices[i];
            const q = new Flatten.Point(p.x, p.y);
            vAux.unshift(q);
        }
        [vVertices, vAux] = [vAux, vVertices];
    }

    // Ademas los puntos del poligono deben ir dados en orden CCW para que los resultados sean correctos.
    // Y deben estar en el cuadrante positivo, asi que ponemos un offset.
    let [offsetX, offsetY] = [poly.box.xmin, poly.box.ymin];
    let [mx, my] = [0, 0];
    let [mx2, my2] = [0, 0];

    // Los offsets deben ser siempre positivos!!!
    if (offsetX < 1.0) {
        offsetX = 10 + Math.abs(offsetX);
    }
    if (offsetY < 1.0) {
        offsetY = 10 + Math.abs(offsetY);
    }

    for (let i = 0; i < numVertices; ++i) {
        // El punto actual y sus coordenadas.
        const p = vVertices[i];
        const [x, y] = [p.x + offsetX, p.y + offsetY];
        // El punto posterior y sus coordenadas.
        const pp = vVertices[(i === numVertices - 1) ? 0 : i + 1];
        const [xx, yy] = [pp.x + offsetX, pp.y + offsetY];

        const sumX = x + xx;
        const difX = (x * yy) - (xx * y);

        const sumY = y + yy;
        const difY = difX;

        mx += sumX * difX;
        my += sumY * difY;
    }

    mx /= (6.0 * area);
    my /= (6.0 * area);
    mx -= offsetX;
    my -= offsetY;

    return [mx, my];
}

function getIsleFace(poly: Flatten.Polygon): Flatten.Face {
    let isleFaceAux: Flatten.Face | null = null;
    for (const face of poly.faces) {
        isleFaceAux = face;
        break;
    }
    const isleFace = isleFaceAux as Flatten.Face;
    return isleFace;
}

/**
 * Calcula el COM/COG (Center Of Mass/Gravity) de un poligono 2D simple, sin agujeros, usando el teorema de Green,
 * sacado de:
 * https://demonstrations.wolfram.com/CenterOfMassOfAPolygon/
 * No siempre produce exactamente el mismo resultado que la media aritmetica de los vertices (problema de la figura del
 * comecocos). Este es el mecanismo que hay que usar siempre para esto, pues tiene en cuenta el recorrido de los
 * vertices.
 *
 * @param poly 
 * @returns 
 */
function calculateCOG4SimplePolygon(poly: Flatten.Polygon): P2D {
    // No se pueden usar directamente los vertices del poligono, sino que hay que usar su primera face, la mas externa.
    const isleFace = getIsleFace(poly);
    let ordering = isleFace.orientation();
    let reverted = false;
    if (ordering === Flatten.ORIENTATION.CW) {
        // Le damos la vuelta para asegurar un orden CCW para que estos calculos sean correctos.
        isleFace.reverse();
        reverted = true;
    }

    // Ademas de que los puntos de la face isla deben ir dados en orden CW para que los resultados sean correctos.
    // Tambien deben estar en el cuadrante positivo, asi que ponemos un offset para el calculo.
    let [offsetX, offsetY] = [isleFace.box.xmin, isleFace.box.ymin];
    let [mx, my] = [0, 0];
    const [gapX, gapY] = [10, 10];

    // Los offsets deben ser siempre positivos!!!
    if (offsetX < 1.0) {
        offsetX = gapX + Math.abs(offsetX);
    }
    if (offsetY < 1.0) {
        offsetY = gapY + Math.abs(offsetY);
    }

    // Recorremos los vertices de los edges de esta face y les aplicamos el offset para los calculos.
    let edge = isleFace.first;
    let cntVert = 0;
    do {
        // Los puntos de inicio y fin de la arista.
        const p = edge.start;
        const q = edge.end;

        // Sumamos los offsets.
        const [pX, pY] = [p.x + offsetX, p.y + offsetY];
        const [qX, qY] = [q.x + offsetX, q.y + offsetY];

        const sumX = pX + qX;
        const difX = (pX * qY) - (qX * pY);
        const sumY = pY + qY;

        mx += sumX * difX;
        my += sumY * difX;
        edge = edge.next;
        ++cntVert;
    } while (edge != isleFace.first)

    if (reverted) {
        isleFace.reverse();
    }

    // Este area debiera ser siempre positiva.
    const area = isleFace.area();
    if (area < 0) {
        debugger;
    }

    mx /= (6.0 * area);
    my /= (6.0 * area);
    mx -= offsetX;
    my -= offsetY;

    // Comprobacion psicopatica que desaparecera. El punto puede no estar dentro del propio poligono, pero creo yo que
    // impepinablemente debe caer dentro del box del poliganorl.
    if (false) {
        const point = new Flatten.Point(mx, my);
        if (poly.contains(point) === false) {
            debugger;
        }
    }

    return [mx, my];
}

/**
 * Dado un poligono y un punto que esta dentro del poligono (o al menos dentro de su convex-Hull), generamos una linea
 * vertical, perpendicular al eje X, que lo dividide en 2 partes. Es en el eje X porque es la coordenada X la que define
 * la zona de corte, no la Y. Ademas devolvemos siempre en el orden izquierda-derecha.
 * 
 *                [0] Left      | [1] Right
 *              +---------------+------------+
 *              |               |            |
 *              |               |            |
 *              |               +X           |
 *              |               |            |
 *              |               |            |
 *              +---------------+------------+
 *                              |
 * @param poly 
 * @param point 
 */
function cutPolygon4AxisX(poly: Flatten.Polygon, point: P2D): [Flatten.Polygon, Flatten.Polygon] | null {
    // Suponemos el punto correcto y sacamos de la AABB la linea de corte vertical, extendiendo el punto X dado con las
    // coordenadas expandidas en Y de su AABB.
    let [yMin, yMax] = [poly.box.ymin, poly.box.ymax];
    const xMed = point[0];
    // Por si las moscas expandimos las y para asegurarnos de no raspar el poligono, sino partirlo con 2 cojones.
    yMin -= 10.0;
    yMax += 10.0;
    // Creamos la linea de interseccion.
    const line = new Flatten.Line(Flatten.point(xMed, yMin), Flatten.point(xMed, yMax));
    const result = cutPolygon4Line(poly, line);
    if (!result) {
        return null;
    }
    let [pol1, pol2] = result as [Flatten.Polygon, Flatten.Polygon];
    // Para devolver en el orden left[0], right[1] usamos como referencia a xMed.
    if (pol1.box.xmin < xMed && xMed < pol2.box.xmax) {
        ;
    } else {
        [pol1, pol2] = [pol2, pol1];
    }
    return [pol1, pol2];
}

/**
 * Dado un poligono y un punto que esta dentro del poligono (o al menos dentro de su convex-Hull), generamos una linea
 * horizontal, perpendicular al eje Y, que lo dividide en 2 partes. Es en el eje Y porque es la coordenada Y la que
 * define la zona de corte, no la X. Ademas lo devolvemos en el orden up-down, primero el de arriba y luego el inferior.
 * 
 *              +----------------------------+
 *              |                            | [0] Up
 *              |               Y            |
 *             -+---------------+------------+-
 *              |                            | [1] Down
 *              |                            |
 *              +----------------------------+
 * 
 * @param poly 
 * @param point 
 */
function cutPolygon4AxisY(poly: Flatten.Polygon, point: P2D): [Flatten.Polygon, Flatten.Polygon] | null {
    // Suponemos el punto correcto y sacamos de la AABB la linea de corte horizontal, extendiendo el punto Y dado con
    // las coordenadas expandidas en X de su AABB.
    let [xMin, xMax] = [poly.box.xmin, poly.box.xmax];
    const yMed = point[1];
    // Por si las moscas expandimos las y para asegurarnos de no raspar el poligono, sino partirlo con 2 cojones.
    xMin -= 10.0;
    xMax += 10.0;
    // Creamos la linea de interseccion.
    const line = new Flatten.Line(Flatten.point(xMin, yMed), Flatten.point(xMax, yMed));
    const result = cutPolygon4Line(poly, line);
    if (!result) {
        return null;
    }
    let [pol1, pol2] = result as [Flatten.Polygon, Flatten.Polygon];
    // Para devolver en el orden up[0], down[1] usamos como referencia a yMed.
    if (pol2.box.ymin < yMed && yMed < pol1.box.ymax) {
        ;
    } else {
        [pol1, pol2] = [pol2, pol1];
    }
    return [pol1, pol2];
}

/**
 * Debiera dar los 2 posibles hemiplanos obtenidos al cortar el poligono dado con la linea dada.
 * \ToDo: Pero ojo, que podria no haber plano alguno o solo uno...
 * @param poly 
 * @param line 
 */
function cutPolygon4Line(poly: Flatten.Polygon, line: Flatten.Line): [Flatten.Polygon | null, Flatten.Polygon] | null {
    // Por si las flies...
    if (!poly.isValid() || poly.isEmpty()) {
        const msg = `ERROR en cutPolygon4Line(): El poligono dado NO es valido o es vacio.`;
        console.error(msg);
        window.alert(msg);
        debugger;
        return null;
    }

    const seeBox = (bx: Flatten.Box, msg2: string = ""): void => {
        const xMin = bx.xmin;
        const yMin = bx.ymin;
        const xMax = bx.xmax;
        const yMax = bx.ymax;
        const xDim = xMax - xMin;
        const yDim = yMax - yMin;
        console.log(`${msg2} [${xMin.toFixed(4)}, ${yMin.toFixed(4)}, ${xMax.toFixed(4)}, ${yMax.toFixed(4)}] ===> `,
            `${xDim.toFixed(4)} * ${yDim.toFixed(4)} = ${(xDim * yDim).toFixed(4)}`);
    };

    // Sacado y adaptado de https://observablehq.com/@alexbol99/cut-polygon-with-line
    // Sacamos los puntos de interseccion ordenados por el avance de la linea.
    let vIsectionPoints = getIntersectionPointsPolygon4Line(poly, line);
    // Si no hay puntos no hay interseccion.
    let nIsectionPoints = vIsectionPoints.length;
    if (nIsectionPoints <= 1) {
        return null;
    } else {
        console.log(" ================================================> Intersections: " + nIsectionPoints);
        if (nIsectionPoints !== 2) {
            console.log(`ATENCION: Interseccion de la linea con ${nIsectionPoints} puntos ===> POSIBLE HUECO!!!.`);
            return alternativeDivision4PoligonByLineNV(poly, vIsectionPoints);
        }
    }
    // Y ahora creamos una multilinea con los puntos ordenados, para buscar el corte final en 2 hemiplanos, que ya
    // sabemos que es posible.
    const multiLine = new Flatten.Multiline([line]).split(vIsectionPoints);

    const algo = poly.cutWithLine(line);

    // Cut polygon with multiline and return array of new polygons.
    // Multiline should be constructed from a line with intersection point.
    // Esto casca cuando hay holes, por eso la llamada a alternativeDivision4PoligonByLineNV() que usa otra tecnica...
    const [poly1, poly2] = poly.cut(multiLine);

    // Pero que pasa cuando hay agujeros en el original?, que no son procesados.
    const poly0NumFaces = poly.faces.size;

    if (poly0NumFaces > 1) {
        // Vamos a tener que intersectar tambien la linea con los agujeros presentes en el poligono original.
        let i = 0;
        for (const face of poly.faces) {
            const poly4Face = face.toPolygon();
            console.log(`[${i}/${poly0NumFaces}] Face ===> signedArea: ${face.signedArea()}`);
            console.log(`\t Area: ${poly4Face.area()}`);
            if (i) {
                console.log(`\t Hole face with box:`);
                seeBox(poly4Face.box);
                vIsectionPoints = line.intersect(poly4Face);
                nIsectionPoints = vIsectionPoints.length;
                if (nIsectionPoints !== 0) {
                    console.log(`\t Intersecciones: ${nIsectionPoints}`);
                    debugger;
                } else {
                    console.log(`\t Sin intersecciones.`);
                    // Si no tiene intersecciones este hueco/face puede agregarse sin problemas al hemiplano que le
                    // corresponda, pero OJO, a uno EXOR al otro. Usamos las box para ver a cual.
                    if (poly4Face.box.intersect(poly1.box)) {
                        poly1.addFace(face);
                    } else {
                        poly2.addFace(face);
                    }
                }
            } else {
                console.log(`\t Isle face with box:`);
                seeBox(poly4Face.box);
            }
            ++i;
        }
    }

    return [poly1, poly2];
}

/**
 * Otra forma de partir un poligono por una serie de varios puntos alineados vertical u horizontalmente que sabemos que
 * forman un segmento recto que corta el poligono. Siempre va a haber 2 "mitades" que devolver, salvo error.
 *
 * @param poly 
 * @param vPoints 
 */
function alternativeDivision4PoligonByLineNV(poly: Flatten.Polygon, vPoints: Flatten.Point[]): [Flatten.Polygon, Flatten.Polygon] | null {
    /*
        En este caso sabemos que Flatten-js casca, asi que intentamos sacar 2 cajas de la linea, ya sean horizontales
        o verticales y las intentamos cortar contra el poligono que es el slab:

                                    +------+--------+                            +-----------------+
                     |              |      |        |                            |                 |
                +----+------+       | +----+------+ |       +-----------+        |  +-----------+  |
                |    |      |       | |    |      | |       |           |        |  |           |  |
                |    |      | ===>  | |    |      | |     --+-----------+-- ===> +--+-----------+--+
                |    |      |       | |    |      | |       |           |        |  |           |  |
                +----+------+       | +----+------+ |       +-----------+        |  +-----------+  |
                     |              |      |        |                            +-----------------+
                                    +------+--------+
    */
    // Primero debemos determinar si es la X o la Y lo que se repite en los puntos para ver como crear las 2 cajas...
    let allEqualX = true;
    let allEqualY = true;
    const N = vPoints.length;
    let prevX = vPoints[0].x;
    let prevY = vPoints[0].y;
    const kEps = 0.00001;
    let xAvg = prevX;
    let yAvg = prevY;
    for (let i = 1; i < N; ++i) {
        const x = vPoints[i].x;
        const y = vPoints[i].y;
        xAvg += x;
        yAvg += y;
        if (allEqualX && Math.abs(x - prevX) < kEps) {
            allEqualX = allEqualX && true;
        } else {
            allEqualX = false;
        }

        if (allEqualY && Math.abs(y - prevY) < kEps) {
            allEqualY = allEqualY && true;
        } else {
            allEqualY = false;
        }

        prevX = x;
        prevY = y;
    }

    if (allEqualX && allEqualY) {
        return null;
    } else {
        if (!allEqualX && !allEqualY) {
            return null;
        }
    }

    const gapX = +10.0;
    const gapY = +10.0;
    xAvg /= N;
    yAvg /= N;

    // Llegados aqui, uno EXOR otro. Asi que creamos el par de cajas para la interseccion con el poligono.
    if (allEqualX) {
        // Linea vertical de division que pasa por la X comun, separando en caja izquierda y derecha.
        const boxLeft = new Flatten.Box(poly.box.xmin - gapX, poly.box.ymin - gapY, xAvg, poly.box.ymax + gapY);
        const boxRight = new Flatten.Box(xAvg, poly.box.ymin - gapY, poly.box.xmax + gapX, poly.box.ymax + gapY);
        // Ahora por separado buscamos el corte de cada caja con el poligono, dando 2 regiones, pero no hay interseccion
        // directa de un poligono contra una caja, por lo que tenemos que convertir ambas cajas en poliganorls.
        const polyLeft = new Flatten.Polygon(boxLeft);
        const polyRight = new Flatten.Polygon(boxRight);
        // Pero asegurandonos el convenio de CW para las islas!!!.
        setOrderCW2SimpleIslePolygon(polyLeft);
        setOrderCW2SimpleIslePolygon(polyRight);

        const polyISectL = Flatten.BooleanOperations.intersect(poly, polyLeft);
        const polyISectR = Flatten.BooleanOperations.intersect(poly, polyRight);

        if (polyISectL.faces.size !== 1 || polyISectR.faces.size !== 1) {
            // Flatten no admite colisiones con agujeros que no toquen el poligono, asi que toca pasarlos a mano...
            console.error("WARNING: Polygonos con varias faces???... Imposible.");
            debugger;
        }

        // A los 2 poligonos anteriores base les agregamos sus restantes holes que no han sido considerados en la
        // interseccion anterior.
        let index = 0;
        for (const faceHole of poly.faces) {
            if (index) {
                // Solo pillamos lo que sea hole, no el isle, ya anteriormente tratado.
                const polyHole = faceHole.toPolygon();
                // Me aseguro el CW por si las moscas.
                setOrderCW2SimpleIslePolygon(polyHole);

                const iSectLeft = polyHole.intersect(polyLeft);
                if (iSectLeft.length === 0) {
                    // const holeISectL = Flatten.BooleanOperations.intersect(polyHole, polyLeft);
                    // // Lo agregamos como hole a la parte izquierda, tras cambiarle el orden a CCW para area negativa.
                    // if (!addHolePolygon2Polygon(polyISectL, holeISectL)) {
                    //     debugger;
                    // }

                    // Como no hay intersecciones es un agujero "libre" que intento agregar directamente si es que esta
                    // dentro de la zona "de inclusion"...
                    if (!addHolePolygon2Polygon(polyISectL, polyHole)) {
                        debugger;
                    }
                }

                const iSectRight = polyHole.intersect(polyRight);
                if (iSectRight.length === 0) {
                    // const holeISectR = Flatten.BooleanOperations.intersect(polyHole, polyRight);
                    // if (!addHolePolygon2Polygon(polyISectR, holeISectR)) {
                    //     debugger;
                    // }

                    if (!addHolePolygon2Polygon(polyISectR, polyHole)) {
                        debugger;
                    }
                }
            }
            ++index;
        }

        return [polyISectL, polyISectR];
    } else {
        // allEqualY: Linea horizontal de division que pasa por la Y comun, separando en caja superior e inferior.
        const boxUp = new Flatten.Box(poly.box.xmin - gapX, yAvg, poly.box.xmax + gapX, poly.box.ymax + gapY);
        const boxDown = new Flatten.Box(poly.box.xmin - gapX, poly.box.ymin - gapY, poly.box.xmax + gapX, yAvg);
        const polyUp = new Flatten.Polygon(boxUp);
        const polyDown = new Flatten.Polygon(boxDown);
        setOrderCW2SimpleIslePolygon(polyUp);
        setOrderCW2SimpleIslePolygon(polyDown);

        const polyISectU = Flatten.BooleanOperations.intersect(poly, polyUp);
        const polyISectD = Flatten.BooleanOperations.intersect(poly, polyDown);

        let index = 0;
        for (const faceHole of poly.faces) {
            if (index) {
                // Solo pillamos lo que sea hole, no el isle, ya anteriormente tratado.
                const polyHole = faceHole.toPolygon();
                // Me aseguro el CW por si las moscas.
                setOrderCW2SimpleIslePolygon(polyHole);

                const iSectUp = polyHole.intersect(polyUp);
                if (iSectUp.length === 0) {
                    // const holeISectU = Flatten.BooleanOperations.intersect(polyHole, polyUp);
                    // // Lo agregamos como hole a la parte izquierda, tras cambiarle el orden a CCW para area negativa.
                    // if (!addHolePolygon2Polygon(polyISectU, holeISectU)) {
                    //     debugger;
                    // }
                    if (!addHolePolygon2Polygon(polyISectU, polyHole)) {
                        i_ECS.error("ERROR(I): Can't add hole to polygon???");
                        debugger;
                    }
                }

                const iSectDown = polyHole.intersect(polyDown);
                if (iSectDown.length === 0) {
                    // const holeISectD = Flatten.BooleanOperations.intersect(polyHole, polyDown);
                    // if (!addHolePolygon2Polygon(polyISectD, holeISectD)) {
                    //     debugger;
                    // }
                    if (!addHolePolygon2Polygon(polyISectD, polyHole)) {
                        i_ECS.error("ERROR(II): Can't add hole to polygon???");
                        debugger;
                    }
                }
            }
            ++index;
        }

        return [polyISectU, polyISectD];
    }
}

function addHolePolygon2Polygon(srcPoly: Flatten.Polygon, holePoly: Flatten.Polygon): boolean {
    if (!srcPoly.isValid() || srcPoly.isEmpty()) {
        return false;
    }
    if (!holePoly.isValid() || holePoly.isEmpty()) {
        return false;
    }

    // Obviamente el agujero debe estar plenamente contenido en el poligono, pero con las limitaciones de Flatten no
    // puedo usar contains(), (por la coincidencia en aristas/vertices), asi que fusiono las box de ambos poligonos y
    // si la fusion es exactamente igual a la caja original, entonces la cosa va bien...
    const fusedBox = srcPoly.box.merge(holePoly.box);
    if (srcPoly.box.equal_to(fusedBox) === false) {
        i_ECS.error("ERROR: The hole isn't contained by the source polygon.");
        debugger;
        return false;
    }

    // Nos aseguramos de que el destino viene en orden CW.
    const srcFace = getIsleFace(srcPoly);
    if (srcFace.orientation() !== Flatten.ORIENTATION.CW) {
        return false;
    }

    // El area inicial de la cara original, que debiera ser positiva, para comparar...
    const srcArea = srcFace.signedArea();
    if (srcArea < 0) {
        debugger;
    }
    // Y el area total, ya que puede tener mas agujeros.
    const srcFullArea = srcPoly.area();

    // Si la cara del bujero o furacu no esta en CCW se lo forzamos.
    const holeFace = getIsleFace(holePoly);
    if (holeFace.orientation() !== Flatten.ORIENTATION.CCW) {
        holeFace.reverse();
    }
    // Y ahora incorporamos esa cara furacu al poligano origen...
    const newHolePoly = holeFace.toPolygon();
    // ...pero para ello necesitamos obtener todos los puntos implicados y en el orden preciso, asi que recorremos la
    // nueva face sacando y acumulando sus vertices.
    const vVertices = getContourPoints(newHolePoly);
    // const vVertices: Flatten.Point[] = [];
    // for (const face of newHolePoly.faces) {    
    //     for (const edge of face) {
    //         // Dentro de los edges tenemos segmentos o arcos.
    //         const type = edge.shape.constructor.name;
    //         if (type === "Segment") {
    //             const pA = edge.start;
    //             // const pB = edge.end;
    //             vVertices.push(new Flatten.Point(pA.x, pA.y));
    //         } else {
    //             const msg = `WARNING: Type "${type} not implemented yet!!!."`;
    //             console.error(msg);
    //             window.alert(msg);
    //         }
    //     }
    //     break;
    // }

    srcPoly.addFace(vVertices);

    const resultingArea = srcPoly.area();
    if (srcArea > resultingArea) {
        console.log(`Adding new hole reduce area from ${srcFullArea.toFixed(3)}/${srcArea.toFixed(3)} to ${resultingArea.toFixed(3)}`);
    } else {
        debugger;
    }

    return true;
}

/**
 * Devuelve los puntos de interseccion entre la linea y el poligono dados en el orden de avance de la linea dada, es
 * decir ORDENADOS segun la linea, de forma que puedan ser usados para crear una multilinea...
 * En caso de no haber interseccion se devuelve un vector vacio.
 * @param poly 
 * @param line 
 * @returns 
 */
function getIntersectionPointsPolygon4Line(poly: Flatten.Polygon, line: Flatten.Line): Flatten.Point[] {
    const vIsectionPoints = line.intersect(poly);
    let nIsectionPoints = vIsectionPoints.length;
    if (!nIsectionPoints) {
        return [] as Flatten.Point[];
    }

    // Atencion: Sort given array of points that lay on line with respect to coordinate on a line.
    // This method assumes that points lay on the line and does not check this!!!.
    // https://alexbol99.github.io/flatten-js/Line.html#sortPoints
    const vIsectionPointsSorted = line.sortPoints(vIsectionPoints);
    return vIsectionPointsSorted;
}

/**
 * Devuelve un SlabInfo con todos los datos puestos a los valores por defecto.
 * @returns 
 */
function createEmptySlabInfo(): SlabInfo {
    const data: SlabInfo = {
        slabName: "",
        COG3D: [-Infinity, -Infinity, -Infinity],
        area: -1.0,
        poly: null,
        index4Storey: -1,
        polyUp: null,
        polyDown: null,
        polyLeft: null,
        polyRight: null,
        areaUp: -1.0,
        areaDown: -1.0,
        areaLeft: -1.0,
        areaRight: -1.0,
        cogUp: [-Infinity, -Infinity],
        cogDown: [-Infinity, -Infinity],
        cogLeft: [-Infinity, -Infinity],
        cogRight: [-Infinity, -Infinity],
    };
    return data;
}

/**
 * Calcula los pertinentes datos para los semiplanos up/down/left/right a partir del COG presente.
 * Los datos calculados son las subAreas y los subCOG's de cada uno de los 4 hemiplanos que se meten en el objeto dado.
 * En caso de error se devuelve false.
 *
 * @param data 
 * @returns 
 */
function calculateHemiPlanesInfo(data: SlabInfo): boolean {
    if (!data.poly) {
        return false;
    }

    const srcPoly = data.poly as Flatten.Polygon;
    const kEps = 0.0000001;
    let result = cutPolygon4AxisY(srcPoly, data.COG3D as unknown as P2D);
    if (result) {
        [data.polyUp, data.polyDown] = result;
        const areaUp = data.polyUp.area();
        const areaDown = data.polyDown.area();
        const diffUpDown = Math.abs(data.area - (areaUp + areaDown));
        if (diffUpDown > kEps) {
            console.error(`ERROR: Diferencia de areas perdida entre original y superior mas inferior: ${diffUpDown}`);
            debugger;
            seePolygon(srcPoly, "\nP O L I G O N O     O R I G E N:");
            seePolygon(data.polyUp, "\nP O L I G O N O     S U P E R I O R:");
            seePolygon(data.polyDown, "\nP O L I G O N O     I N F E R I O R:");
        }

        const cogUp = calculateCOG4Polygon(data.polyUp);
        const cogDown = calculateCOG4Polygon(data.polyDown);

        data.areaUp = areaUp;
        data.areaDown = areaDown;
        data.cogUp = cogUp;
        data.cogDown = cogDown;
    } else {
        data.polyUp = data.polyDown = null;
        data.areaUp = data.areaDown = -1.0;
        data.cogUp = [-Infinity, -Infinity];
        data.cogDown = [-Infinity, -Infinity];
    }

    result = cutPolygon4AxisX(srcPoly, data.COG3D as unknown as P2D);
    if (result) {
        [data.polyLeft, data.polyRight] = result;
        const areaLeft = data.polyLeft.area();
        const areaRight = data.polyRight.area();
        const diffLeftRight = Math.abs(data.area - (areaLeft + areaRight));
        if (diffLeftRight > kEps) {
            console.error(`ERROR: Diferencia de areas perdida entre original e izquierdo mas derecho: ${diffLeftRight}`);
            debugger;
            seePolygon(srcPoly, "\nP O L I G O N O     O R I G E N:");
            seePolygon(data.polyLeft, "\nP O L I G O N O     I Z Q U I E R D O:");
            seePolygon(data.polyRight, "\nP O L I G O N O     D E R E C H O:");
        }

        const cogLeft = calculateCOG4Polygon(data.polyLeft);
        const cogRight = calculateCOG4Polygon(data.polyRight);

        data.areaLeft = areaLeft;
        data.areaRight = areaRight;
        data.cogLeft = cogLeft;
        data.cogRight = cogRight;
    } else {
        debugger;
        data.polyLeft = data.polyRight = null;
        data.areaLeft = data.areaRight = -1.0;
        data.cogLeft = [-Infinity, -Infinity];
        data.cogRight = [-Infinity, -Infinity];
    }

    return true;
}

function seePolygon(p: Flatten.Polygon, msg: string = ""): void {
    if (msg.length) {
        console.log(msg);
    }
    if (p.isValid() == false) {
        console.error("\t ERROR: Invalid polygon!!!.");
    }
    if (p.isEmpty()) {
        console.error("\t ERROR: Empty polygon!!!.");
    }
    const N = p.vertices.length;
    console.log(`\t Vertices[${N}]:`);
    let txt = "\t ";
    for (let i = 0; i < N; ++i) {
        const v = p.vertices[i];
        if (i) {
            txt += ", ";
        }
        txt += "[" + v.x.toFixed(4) + ", " + v.y.toFixed(4) + "]"
    }
    console.log(txt);
    const area = p.area();
    console.log("\t Area: " + area.toFixed(4));
    const ordering = orientation(p);
    let orderingTxt = "ERROR???";
    if (ordering === Flatten.ORIENTATION.CCW) {
        orderingTxt = "CCW";
    } else {
        if (ordering === Flatten.ORIENTATION.CW) {
            orderingTxt = "CW!!!";
        }
    }
    console.log("\t ORDERING: " + orderingTxt);

    const seeBox = (bx: Flatten.Box, msg2: string = ""): void => {
        const xMin = bx.xmin;
        const yMin = bx.ymin;
        const xMax = bx.xmax;
        const yMax = bx.ymax;
        const xDim = xMax - xMin;
        const yDim = yMax - yMin;
        console.log(`${msg2} [${xMin.toFixed(4)}, ${yMin.toFixed(4)}, ${xMax.toFixed(4)}, ${yMax.toFixed(4)}] ===> `,
            `${xDim.toFixed(4)} * ${yDim.toFixed(4)} = ${(xDim * yDim).toFixed(4)}`);
    };

    seeBox(p.box, "\t Box for polygon:");
    const F = p.faces.size;

    console.log(`\t Faces[${F}]:`);
    let iF = 0;
    for (const face of p.faces) {
        console.log(`\t\t Face[${iF}/${F}]:`);
        const orientation = face.orientation();
        let orientationTxt = "NONE";
        if (orientation === Flatten.ORIENTATION.CCW) {
            orientationTxt = "CCW";
        } else {
            if (orientation === Flatten.ORIENTATION.CW) {
                orientationTxt = "CW!!!";
            }
        }
        console.log(`\t\t Orientation: ${orientationTxt}  signedArea: ${face.signedArea()}`);
        seeBox(face.box, "\t\t Box for face:");
        const E = face.size;
        let iE = 0;
        // Dentro de una face podemos iterar sus componentes edge asi:
        for (const edge of face) {
            console.log(`\t\t\t Edge[${iE}/${E}] ==> length: ${edge.shape.length.toFixed(4)}`);
            // Comentamos lineas para ver si podemos quitar el error del type "e" que solo se da en runTime en GreyStruc.
            // Dentro de los edges tenemos segmentos o arcos.
            // const type = edge.shape.constructor.name;
            // if (type === "Segment") {
            const pA = edge.start;
            const pB = edge.end;
            console.log(`\t\t\t\t [${pA.x.toFixed(4)}, ${pA.y.toFixed(4)}] ===> `,
                `[${pB.x.toFixed(4)}, ${pB.y.toFixed(4)}]`);
            //} else {
            //    const msg = `WARNING: Type "${type} not implemented yet!!!."`;
            //    console.error(msg);
            //    window.alert(msg);
            //}
            ++iE;
        }

        // Tambien se puede hacer asi.
        // let edge = face.first;
        // do {
        //     console.log(edge.shape.length);
        //     edge = edge.next;
        // } while (edge != face.first)

        ++iF;
    }
}

/**
 * Devuelve el orden CCW/CW/NONE del poligono dado, sacado de su face externa.
 * @param p 
 * @returns 
 */
function orientation(p: Flatten.Polygon): Flatten.ORIENTATION.PolygonOrientationType {
    let res: Flatten.ORIENTATION.PolygonOrientationType = Flatten.ORIENTATION.NOT_ORIENTABLE;
    if (p.faces.size === 0) {
        console.error("ERROR: Poligono without faces???.");
        return res;
    }
    // Solo lo hago con la primera cara, y lo hago asi de chapucero con el for-of-break porque no tengo otra forma.
    // Ademas Flatten-JS tiene el siguiente criterio:
    // Si el area es NEGATIVO ==> CCW.
    // Si es POSITIVO ===> CW.
    // Si es NULA no es orientable.
    for (const face of p.faces) {
        res = face.orientation();
        break;
    }
    return res;
}

/**
 * Devuelve un vector con los puntos del contorno exterior del poligono dado, generalmente en orden CW y sin repeticion
 * primero ultimo.
 *
 * @param p 
 */
function getContourPoints(p: Flatten.Polygon): Flatten.Point[] {
    const contourFace = getIsleFace(p);
    const vVertices: Flatten.Point[] = [];

    for (const edge of contourFace.edges) {
        // Dentro de los edges tenemos segmentos o arcos.
        // const type = edge.shape.constructor.name;
        // if (type === "Segment") {
            const pA = edge.start;
            // const pB = edge.end;
            vVertices.push(new Flatten.Point(pA.x, pA.y));
        // } else {
        //    const msg = `WARNING: Type "${type} not implemented yet!!!."`;
        //    console.error(msg);
        //    window.alert(msg);
        //}
    }

    return vVertices;
}