import * as THREE from "three";
import { Cad3dOp } from "./base";
import { IEdgeReference, IPointReference, settingsOpModes } from "./step-operations";
import { polygon } from "../math/polygon";
import { IObjData } from "lib/models/objdata";
import { arcLineToArcParam, getIsegmentFromIndex, getNearPolylineEdgeIndex } from "lib/math/line";
import { isArcData, isLineData, isPolygonData } from "lib/models/checktools";
import { copyIPoint, getFactorLinePosition2p } from "lib/math/point";
import { RectangleSelector } from "lib/selection/rectangle-selector";
import { getFactorPointArcPosition, getStartEndPointArc } from "lib/math/arc";
import { IBox, isPointInBox } from "lib/math/box";
import { rayCastResults } from "lib/coordinates/raycaster";
import { cloneDataModel } from "lib/models/model-creator/datamodel-factory";


export abstract class MultiEdition extends Cad3dOp {

  public objDataOrigin: IObjData[] = [];
  public objDataParts: Map<IObjData, number[]> = new Map();
  public objDataAux: IObjData[] = [];

  constructor(objs?: IObjData[]) {
    console.log(`[MultiEdition.constructor()]`);
    super();
    if (objs && objs.length > 0) {
      // Almost certainly unnecessary filter of objects, therefore commented
      // this.objDataOrigin = objs.filter(o => o.isVisible && !o.isLocked);
      this.objDataOrigin = objs.slice();
    }
  }

  protected setStartObjs() {
    const step = this.settingsOpManager.currCfg;
    if (step.stepMode === settingsOpModes.SELECTOBJS) {
      if (step.multiSelect === false && this.objDataOrigin.length > 1) {
        const sel = this.graphicProcessor.getSelectionManager();
        sel.unSelectObjDatas(this.objDataOrigin);
        this.objDataOrigin = [];
        return;
      }
    }
    if (this.settingsOpManager.hasFilterFun(step)) {
      this.objDataOrigin = this.objDataOrigin.filter((o) => {
        const okObj = step.filterFun(o);
        if (okObj === false) {
          this.graphicProcessor.unSelectObjData(o);
        }
        return okObj;
      });
    }
    this.setAuxObjs();
  }
  protected setAuxObjs() {
    for (const objData of this.objDataOrigin) {
      const auxObj = cloneDataModel(objData);
      this.setAuxObj(auxObj.graphicObj);
      this.objDataAux.push(auxObj);
    }
  }

  /* #################################################################### */
  /*                                RAYCAST                               */
  /* #################################################################### */
  private rectSelector: RectangleSelector;

  protected registerRaycast(): void {
    if (this.treEventNames.has("raycast"))
      return;
    this.treEventNames.add("raycast");
    this.eventManager.connectMouseMainDownEvent(this.raycastEventCallback);

    const step = this.settingsOpManager.currCfg;
    if (step.stepMode === settingsOpModes.SELECTOBJS) {
      const cb = step.multiSelect ? this.selectObjCallback : undefined;
      this.rectSelector = new RectangleSelector(this.graphicProcessor, cb);
    }
  }
  protected unRegisterRaycast() {
    if (this.treEventNames.has("raycast")) {
      this.eventManager.disconnectMouseMainDownEvent(this.raycastEventCallback);
      this.treEventNames.delete("raycast");

      const step = this.settingsOpManager.currCfg;
      if (step.stepMode === settingsOpModes.SELECTOBJS) {
        this.rectSelector.unregister();
      }
    }
  }
  private raycastEventCallback = (ev: PointerEvent): void => {
    if (this.finished)
      return;
    const step = this.settingsOpManager.currCfg;
    if (step.stepMode === settingsOpModes.SELECTOBJS) {
      const objDatas = this.graphicProcessor.getRayCastObjects().map(c => c.dataObject);
      if (objDatas.length) {
        this.selectObjCallback([objDatas[0]]);
      }
    } else if (step.stepMode === settingsOpModes.SELECTVERTEX) {
      this.vertexSelectionCallback();

    } else if (step.stepMode === settingsOpModes.SELECTEDGE) {
      this.edgeSelectionCallback();
    }
  };

  /* ------------------------ OBJECTS SELECTION ----------------------- */
  private selectObjCallback = (objs: IObjData[], bbox?: IBox) => {
    // Filtrado del STEP propia de cada operación
    const filterObjs: IObjData[] = [];
    const validObjs: IObjData[] = [];
    const step = this.settingsOpManager.currCfg;
    for (const data of objs) {
      if (validObjs.indexOf(data) === -1) {
        validObjs.push(data);
        if (this.settingsOpManager.hasFilterFun(step) && step.filterFun(data)) {
          filterObjs.push(data);
        }
      }
    }

    if (step.stepMode === settingsOpModes.SELECTOBJS) {
      // Selection object logic in operations
      for (const data of filterObjs) {
        const selectManager = this.graphicProcessor.getSelectionManager();
        if (!selectManager.isDataSelected(data)) {
          this.objDataOrigin.push(data);
          this.getVerticesReference(data, bbox);
          if (step.enableSelectMarks) {
            this.rectSelector.unPreSelectData(data);
            selectManager.selectObjDatas([data]);
          }
        } else {
          const i = this.objDataOrigin.indexOf(data);
          this.objDataOrigin.splice(i, 1);
          if (step.enableSelectMarks) {
            this.graphicProcessor.unSelectObjData(data);
          }
          this.objDataParts.delete(data);
        }
      }
      if (step.multiSelect) {
        if (step.getObjsCallback) {
          step.getObjsCallback();
        }
      } else {
        if (this.objDataOrigin.length > 0) {
          this.setNextStep();
        }
      }
    }
  };

  private getVerticesReference(data: IObjData, bbox?: IBox) {
    if (bbox) {
      const index: number[] = [];
      const viewport = this.graphicProcessor.getActiveViewport();
      const camera = viewport.camera as THREE.Camera;
      if (isLineData(data)) {
        const object = data.graphicObj;
        const pos = (object.geometry as THREE.BufferGeometry).getAttribute("position");
        const vector0 = new THREE.Vector3();
        for (let i = 0; i < data.definition.points.length; i++) {
          const { x, y, z } = data.definition.points[i];
          vector0.set(x, y, z);
          object.localToWorld(vector0);
          vector0.project(camera);
          const p0 = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);
          if (isPointInBox(p0, bbox))
            index.push(i);
        }
        if (index.length === pos.count)
          index.length = 0;
        this.objDataParts.set(data, index);

      } else if (isArcData(data)) {
        const object = data.graphicObj;
        const [p1, p2] = getStartEndPointArc(data.definition);

        const vector0 = new THREE.Vector3();
        vector0.set(p1.x, p1.y, p1.z);
        object.localToWorld(vector0);
        vector0.project(camera);
        let p = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);
        if (isPointInBox(p, bbox))
          index.push(0);

        vector0.set(p2.x, p2.y, p2.z);
        object.localToWorld(vector0);
        vector0.project(camera);
        p = this.graphicProcessor.vectorToScreenCoord(vector0.x, vector0.y);
        if (isPointInBox(p, bbox))
          index.push(1);

        if (index.length === 2)
          index.length = 0;
        this.objDataParts.set(data, index);
      }
    } else {
      this.objDataParts.set(data, []);
    }
  }

  /* ------------------------- VERTEX SELECTION ----------------------- */
  private filterRayCastData = (castObjs: rayCastResults[]) => {
    // Filtrado del STEP propia de cada operación
    const filterObjs: rayCastResults[] = [];
    const validObjs: IObjData[] = [];
    const step = this.settingsOpManager.currCfg;
    if (this.settingsOpManager.hasFilterFun(step)) {
      for (const castObj of castObjs) {
        const data = castObj.dataObject;
        if (validObjs.indexOf(data) === -1) {
          validObjs.push(data);
          if (step.filterFun(data)) {
            filterObjs.push(castObj);
          }
        }
      }
    }
    return filterObjs;
  };
  private vertexSelectionCallback = (): void => {
    if (this.finished)
      return;
    const raycasted = this.graphicProcessor.getRayCastObjects();
    if (raycasted) {
      const step = this.settingsOpManager.currCfg;
      const filterObjs = this.filterRayCastData(raycasted);
      if (step.stepMode === settingsOpModes.SELECTVERTEX) {
        if (filterObjs.length > 0) {
          this.objDataOrigin = [filterObjs[0].dataObject];
        }
        const point = this.getPointReference(filterObjs.length > 0 ? filterObjs[0] : null);
        step.getVertexCallback(point as IPointReference);
      }
    }
  };
  private getPointReference(res: rayCastResults | null): IPointReference | null {
    const pto = this.graphicProcessor?.getMouseCoordinates();
    if (res) {
      if (isLineData(res.dataObject)) {
        const indx = getNearPolylineEdgeIndex(res.dataObject.definition, pto);
        const edge = getIsegmentFromIndex(res.dataObject.definition, indx);
        const arc = edge.arc ? arcLineToArcParam(edge.p1, edge.p2, edge.arc) : null;
        const f = arc ? getFactorPointArcPosition(arc, pto) : getFactorLinePosition2p(edge.p1, edge.p2, pto);
        return {
          data: res.dataObject,
          edgeIndex: indx,
          factor: f ?? undefined,
          point: copyIPoint(pto),
        };
      } else if (isArcData(res.dataObject)) {
        const f = getFactorPointArcPosition(res.dataObject.definition, pto);
        return {
          data: res.dataObject,
          edgeIndex: 0,
          factor: f ?? undefined,
          point: copyIPoint(pto),
        };
      } else if (isPolygonData(res.dataObject) && res.index !== undefined) {
        const indx = res.index;
        const { center, radius, sides, inscribed, angleO, plane } = res.dataObject.definition;
        const ptos = polygon(center, radius, sides, inscribed, angleO, plane);
        const edge = { p1: ptos[indx], p2: ptos[indx + 1] ?? ptos[0] };
        return {
          data: res.dataObject,
          edgeIndex: indx,
          factor: getFactorLinePosition2p(edge.p1, edge.p2, pto) ?? undefined,
          point: copyIPoint(pto),
        };
      } else {
        // TODO: getter of reference solid vertex (meshes)
        return {
          data: res.dataObject,
          edgeIndex: undefined,
          factor: undefined,
          point: copyIPoint(pto),
        };
      }
    }
    return {
      data: undefined,
      edgeIndex: undefined,
      factor: undefined,
      point: copyIPoint(pto),
    };
  }

  /* -------------------------- EDGE SELECTION ------------------------ */
  private edgeSelectionCallback = (): void => {
    if (this.finished)
      return;
    const raycaster = this.graphicProcessor.getRayCastObjects();
    if (raycaster) {
      const step = this.settingsOpManager.currCfg;
      const filterObjs = this.filterRayCastData(raycaster);

      if (step.stepMode === settingsOpModes.SELECTEDGE) {
        if (filterObjs.length > 0) {
          this.objDataOrigin = [filterObjs[0].dataObject];
        }
        const edge = this.getEdgeReference(filterObjs.length > 0 ? filterObjs[0] : null);
        step.getEdgeCallback(edge as IEdgeReference);
      }
    }
  };
  private getEdgeReference(res: rayCastResults | null): IEdgeReference | null {
    if (res?.index !== undefined) {
      if (isLineData(res.dataObject)) {
        const indx = getNearPolylineEdgeIndex(res.dataObject.definition, res.point);
        const p1 = res.dataObject.definition.points[indx];
        const p2 = res.dataObject.definition.points[indx + 1] ?? res.dataObject.definition.points[0];
        const arc = res.dataObject.definition.arcs[indx];
        return {
          data: res.dataObject,
          p1Index: indx,
          p2Index: indx + 1,
          arc,
          segment: { p1, p2 },
        };
      } else if (isPolygonData(res.dataObject)) {
        const { center, radius, sides, inscribed, angleO, plane } = res.dataObject.definition;
        const points = polygon(center, radius, sides, inscribed, angleO, plane);
        const indx = res.index;
        const p1 = points[indx];
        const p2 = points[indx + 1] ?? points[0];
        return {
          data: res.dataObject,
          p1Index: indx,
          p2Index: indx + 1,
          segment: { p1, p2 },
        };
      }
    }
    // TODO: getter of solid edges (meshes)
    return null;
  }

  /* #################################################################### */
  public endOperation(): void {
    if (this.finished === false) {
      this.unRegisterRaycast();
      super.endOperation();
    }
  }
  public cancelOperation(): void {
    if (this.finished === false) {
      const step = this.settingsOpManager.currCfg;
      if (step && step.stepMode === settingsOpModes.SELECTOBJS && step.multiSelect && this.objDataOrigin.length) {
        this.setNextStep();
      } else {
        this.unRegisterRaycast();
        super.cancelOperation();
      }
    }
  }
}
