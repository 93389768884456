import { MeshProject, MeshProjectParams, MeshProjectsApi, MeshProjectsStatusApi, MeshProjectStatusMPValueEnum } from "lib/apis/mesh-projects/api";
import { MeshProject as MeshProjectEcore } from "modules/struc/models/ecore/mesh";
import { gunzipSync } from "zlib";
import { defaultBeamSize, defaultMergeTolerance, defaultMpcThres, defaultNodalBcThres, defaultShellSize, isMeshProject, MeshGenParams, MeshProjectWithErrors, MeshProperties } from "./mesh";
import { GraphicProcessor } from 'lib/graphic-processor';
import { userMessageEvents } from "lib/events/user-messages";
import { EcoreMeshExporter } from "lib/input-output/e-core/exporter/mesh-exporter";
import { getProjectErrors } from "../checkers.ts/check-project";
import { createRequestOK, downloadFileFromS3, statusRequestOK, saveFile, updateRequestOK, requestStatus, compressAndUploadToS3 } from "lib/apis/utils";
import { meshDispatcher } from "./dispatcher";
import { femStructuralManager } from "./femstructural-manager";
import { meshProjectEvent } from "modules/struc/components/mesh/hook/use-meshing";

export class MeshModelManager {

  public mesh: MeshProperties;
  public status: requestStatus = requestStatus.VOID;
  public statusDate: Date;
  public isDownload: boolean = false;

  constructor() {
    this.mesh = {
      params: {
        beamSize: defaultBeamSize,
        mergeTolerance: defaultMergeTolerance,
        mpcThres: defaultMpcThres,
        nodalBcThres: defaultNodalBcThres,
        shellSize: defaultShellSize,
      },
      project: undefined,
    }
  }

  public importFromJSON(mesh?: MeshProperties) {
    if (mesh) {
      this.mesh = {
        params: { ...mesh.params },
        project: mesh.project
      };
      this.isDownload = true;
    }
  }
  public exportToJSON(): MeshProperties {
    return this.mesh;
  }

  public editMeshParam(meshParams: Partial<MeshGenParams>) {
    if (meshParams.beamSize) this.mesh.params.beamSize = meshParams.beamSize;
    if (meshParams.mergeTolerance) this.mesh.params.mergeTolerance = meshParams.mergeTolerance;
    if (meshParams.mpcThres) this.mesh.params.mpcThres = meshParams.mpcThres;
    if (meshParams.nodalBcThres) this.mesh.params.nodalBcThres = meshParams.nodalBcThres;
    if (meshParams.shellSize) this.mesh.params.shellSize = meshParams.shellSize;
    meshDispatcher.dispatchUpdateMeshParams(this.mesh.params);
  }

  public hasMeshData(): boolean {
    return femStructuralManager.hasMeshData();
  }

  //-----------------------------------------------

  public checkMeshProjectErrors(graphicProc: GraphicProcessor): MeshProjectWithErrors | undefined {
    const projMng = graphicProc.getProjectModelManager();
    if (projMng.project) {
      const errors: string[] = []; // getProjectErrors(projMng.project, false);
      if (errors.length) {
        return { name: projMng.project.name, errors };
      }
    }
  }

  public exportMeshProjectStruct(graphicProc: GraphicProcessor): MeshProjectWithErrors | MeshProjectEcore {
    try {
      const errors = this.checkMeshProjectErrors(graphicProc);
      if (errors) {
        return errors;
      } else {
        const eCoreAdapter = new EcoreMeshExporter();
        const mesh = meshManager.mesh;
        const projMng = graphicProc.getProjectModelManager();
        return eCoreAdapter.exportMeshProject(mesh, projMng.project);
      }
    } catch (err) {
      this.status = requestStatus.ERROR;
      console.error(err);
      throw new Error("Error to export MESH ecore model")
    }
  }

  public async launchMeshingProject(graphicProc: GraphicProcessor): Promise<boolean> {
    try {
      this.statusDate = new Date();
      if (this.status !== requestStatus.RUNNING) {
        const meshProjectData = this.exportMeshProjectStruct(graphicProc);
        if (isMeshProject(meshProjectData)) {
          this.status = requestStatus.RUNNING;
          await this.createAndUploadMeshing(graphicProc, meshProjectData);
          return Promise.resolve(true);
        } else {
          this.status = requestStatus.ERROR;
          const message = "The mesh project has errors that make the analysis impossible. Please, check the downloaded file for more info ...";
          userMessageEvents.dispatchError(message);
          saveFile(JSON.stringify(meshProjectData), `${meshProjectData.name}.json`, "application/json");
          throw new Error(message);
        }
      } else {
        return Promise.resolve(false);
      }
    } catch (err) {
      throw new Error("");
    }
  }

  private async createAndUploadMeshing(graphicProc: GraphicProcessor, meshProjectData: MeshProjectEcore) {
    try {
      const meshProjectEndPoint: MeshProjectsApi = new MeshProjectsApi();
      let lastMeshProject: MeshProject | undefined;
      if (this.mesh.project) {
        const meshProjectRes = await meshProjectEndPoint.meshProjectsIdUploadGet(this.mesh.project.Id);
        console.log("[MESHING-GET] " + this.mesh.project.Id)
        if (meshProjectRes.status === updateRequestOK) {
          lastMeshProject = { ...this.mesh.project, UploadUrl: meshProjectRes.data.UploadUrl };
        }
      } else {
        const projMng = graphicProc.getProjectModelManager();
        const meshProjectParams: MeshProjectParams = { Name: projMng.project.id };
        const meshProjectRes = await meshProjectEndPoint.meshProjectsPost(meshProjectParams);
        if (meshProjectRes.status === createRequestOK) {
          console.log("[MESHING-POST] " + meshProjectRes.data.Id)
          lastMeshProject = meshProjectRes.data;
        }
      }

      if (lastMeshProject) {
        this.mesh.project = lastMeshProject;
        // Compress data and upload to S3
        await compressAndUploadToS3(JSON.stringify(meshProjectData), lastMeshProject.UploadUrl!);
      }
    } catch (error) {
      this.status = requestStatus.ERROR;
      console.error(error);
      return Promise.reject(error);
    }
  }

  public async getMeshingLastEvents(): Promise<meshProjectEvent[]> {
    const result: meshProjectEvent[] = [];
    if (this.mesh.project) {
      const meshProjectEndPoint: MeshProjectsApi = new MeshProjectsApi();
      const meshProjectRes = await meshProjectEndPoint.meshProjectsIdGet(this.mesh.project.Id);
      console.log("[MESHING-GET-LASTEVENTS] " + this.mesh.project.Id)
      if (meshProjectRes.status === updateRequestOK) {
        const project = meshProjectRes.data;
        const projectEvents = project.Events;
        if (projectEvents) {
          const lastDate = this.statusDate.toISOString();
          const events = [projectEvents[projectEvents.length - 1]]; // ?.filter(e => (e.CreatedAt ?? lastDate) >= lastDate);
          if (events && events.length > 0) {
            events.forEach(e => {
              if (e.Name !== undefined && e.CreatedAt !== undefined) {
                result.push({ name: e.Name, date: new Date(e.CreatedAt) });
              }
            });
          }
        }
      }
    }
    return result;
  }

  public async downloadMesh(graphicProc: GraphicProcessor) {
    if (this.mesh.project && this.status === requestStatus.DONE) {
      const meshProjectEndPoint = new MeshProjectsApi();
      const meshProjectRes = await meshProjectEndPoint.meshProjectsIdDownloadGet(this.mesh.project.Id);
      console.log("[MESHING-GET-DOWNLOAD] " + this.mesh.project.Id)
      if (meshProjectRes.status === statusRequestOK) {
        const downloadUrl = meshProjectRes.data.DownloadUrl;
        if (downloadUrl) {
          // Download from S3 and un-compress data
          const arrayBuffer = await downloadFileFromS3(downloadUrl);
          if (arrayBuffer) {
            meshDispatcher.dispatchDownloadedMesh();
            this.isDownload = true;
            const stringMesh = gunzipSync(Buffer.from(arrayBuffer)).toString();
            femStructuralManager.importMeshDataForLoads(graphicProc, stringMesh);
            return JSON.parse(stringMesh);
          }
        }
      }
    }
  }

  public async getMeshingStatus(): Promise<MeshProjectStatusMPValueEnum | null> {
    if (this.mesh.project) {
      const meshProjectEndPoint = new MeshProjectsStatusApi();
      const meshProjectRes = await meshProjectEndPoint.meshProjectsIdStatusGet(this.mesh.project.Id);
      if (meshProjectRes.status === statusRequestOK) {
        // Vamos a escribir la hora.
        const date = new Date();
        const timeStr = date.toLocaleTimeString();
        console.log(`[${timeStr} MESHING-GET-STATUS] ${this.mesh.project.Id} - ${meshProjectRes.data.MPValue}`);
        const status = meshProjectRes.data.MPValue;
        if (status === MeshProjectStatusMPValueEnum.Done) this.status = requestStatus.DONE;
        else if (status === MeshProjectStatusMPValueEnum.Error) this.status = requestStatus.ERROR;
        else if (status === MeshProjectStatusMPValueEnum.Running) this.status = requestStatus.RUNNING;
        else if (status === MeshProjectStatusMPValueEnum.Canceled) this.status = requestStatus.CANCELED;
        return status
      }
    }
    this.status = requestStatus.VOID;
    return Promise.resolve(null)
  }

  public async cancelMeshing(): Promise<boolean> {
    let result = false;
    if (this.mesh.project) {
      const meshProjectEndPoint = new MeshProjectsStatusApi();
      const meshProjectRes = await meshProjectEndPoint.meshProjectsIdCancelPost(this.mesh.project.Id);
      console.log("[MESHING-POST-CANCEL] " + this.mesh.project.Id);
      if (meshProjectRes.status === statusRequestOK) {
        result = true;
      }
    }
    return result;
  }

}

export let meshManager: MeshModelManager;
export function initMeshModelManager() {
  meshManager = new MeshModelManager();
  return meshManager;
}
