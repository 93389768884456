import { EcoreBeamElem } from "./beam";
import { EcoreColumnElem } from "./column";
import { EcoreSlabElem } from './slab';
import { EcoreWallElem } from './wall';
import { EcoreFooterElem } from './footer';
import { EcorePileCapElem } from './pile-cap';
import { IEcoreBuildingElementExport } from "../struc-elem-base";
import { objDataType } from "lib/models/types";
import { buildingElemType } from "lib/models-struc/types/struc-base";

const EcoreExporterBuilder = {
  [objDataType.BEAM]: EcoreBeamElem,
  [objDataType.SLAB]: EcoreSlabElem,
  [objDataType.COLUMN]: EcoreColumnElem,
  [objDataType.WALL]: EcoreWallElem,
  [objDataType.FOOTER]: EcoreFooterElem,
  [objDataType.STRIPFOOTER]: EcoreFooterElem,
  [objDataType.PILECAP]: EcorePileCapElem,
};

/**
 * Segun el tipo dado esto se comporta como una especie de factoria de objetos.
 * @param type 
 * @returns 
 */
export function getEcoreStrucElemExporter(type: buildingElemType): IEcoreBuildingElementExport {
  const builder = EcoreExporterBuilder[type];
  return new builder();
}
