import { blockParam, blockRefParam } from "lib/blocks";
import { circleParam } from "lib/geometries/circle";
import { coneParam } from "lib/geometries/solid/cone";
import { cubeParam } from "lib/geometries/solid/cube";
import { cylinderParam } from "lib/geometries/solid/cylinder";
import { ellipseParam } from "lib/geometries/ellipse";
import { ellipseArcParam } from "lib/geometries/ellipse-arc";
import { planeParam } from "lib/geometries/plane";
import { polygonParam } from "lib/geometries/polygon";
import { pprismParam } from "lib/geometries/solid/pprism";
import { rprismParam } from "lib/geometries/solid/rprism";
import { ILineMaterial, IPointMaterial, ISolidMaterial } from "lib/materials";
import { arcParam } from "lib/math/arc";
import { IPolylineParam } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { pileCapParam } from "lib/models-struc/types/pile-cap";
import { footerParam, stripfootingParam } from "lib/models-struc/types/footer";
import { loadParam } from "lib/models-struc/types/load";
import { slabParam } from "lib/models-struc/types/slab";
import { wallParam } from "lib/models-struc/types/wall";
import { columnParam } from "lib/models-struc/types/column";
import { beamParam } from "lib/models-struc/types/beam";
import { alignedDimensionParam } from "./dimension/aligned-dim";
import { angleDimensionParam } from "./dimension/angle-dim";
import { arcDimensionParam } from "./dimension/arc-dim";
import { circularDimensionParam } from "./dimension/circular-dim";
import { linealDimensionParam } from "./dimension/lineal-dim";
import { regionParam } from "../geometries/solid/region";
import { textParam } from "./text";

export enum objDataType {
  POINT,
  CIRCLE,
  POLYGON,
  REGION,
  CUBE,
  CYLINDER,
  PLANE,
  CONE,
  RRPRIM,
  PPRISM,
  ARC,
  POLYLINE,
  ELLIPSE,
  ELLIPSEARC,
  TEXT,
  BLOCK,
  BLOCKREF,

  ALIGNEDDIM,
  LINEARDIM,
  ARCDIM,
  CIRCULARDIM,
  ANGULARDIM,

  BEAM,
  COLUMN,
  SLAB,
  WALL,
  LOAD,
  FOOTER,
  STRIPFOOTER,
  PILECAP
}

type cadDefinition =
  IPoint |
  IPoint[] |
  IPolylineParam |
  arcParam |
  circleParam |
  polygonParam |
  ellipseParam |
  ellipseArcParam |
  textParam |
  blockParam |
  blockRefParam;

export type solidDefinition =
  coneParam |
  cubeParam |
  cylinderParam |
  planeParam |
  rprismParam |
  pprismParam |
  regionParam |
  struSolidDefinition;

export type dimDefinition =
  alignedDimensionParam |
  linealDimensionParam |
  arcDimensionParam |
  circularDimensionParam |
  angleDimensionParam;

type struSolidDefinition =
  beamParam |
  columnParam |
  slabParam |
  wallParam |
  footerParam |
  stripfootingParam |
  pileCapParam;

// Tenemos 4 posibles definiciones, y no todas tienen los mismos datos internos coincidentes. Solo alguna tiene "name".
export type definitionType = cadDefinition | solidDefinition | dimDefinition | loadParam;

export type materialType = IPointMaterial | ILineMaterial | ISolidMaterial;


export function getObjNameFromType(type: objDataType) {
  switch (type) {
    case objDataType.POINT: return "POINT";
    case objDataType.CIRCLE: return "CIRCLE";
    case objDataType.POLYGON: return "POLYGON";
    case objDataType.REGION: return "REGION";
    case objDataType.CUBE: return "CUBE";
    case objDataType.CYLINDER: return "CYLINDER";
    case objDataType.PLANE: return "PLANE";
    case objDataType.CONE: return "CONE";
    case objDataType.RRPRIM: return "RRPRIM";
    case objDataType.PPRISM: return "PPRISM";
    case objDataType.ARC: return "ARC";
    case objDataType.POLYLINE: return "POLYLINE";
    case objDataType.ELLIPSE: return "ELLIPSE";
    case objDataType.ELLIPSEARC: return "ELLIPSEARC";
    case objDataType.TEXT: return "TEXT";
    case objDataType.BLOCK: return "BLOCK";
    case objDataType.BLOCKREF: return "BLOCKREF";

    case objDataType.ALIGNEDDIM: return "ALIGNEDDIM";
    case objDataType.LINEARDIM: return "LINEARDIM";
    case objDataType.ARCDIM: return "ARCDIM";
    case objDataType.CIRCULARDIM: return "CIRCULARDIM";
    case objDataType.ANGULARDIM: return "ANGULARDIM";

    case objDataType.BEAM: return "BEAM";
    case objDataType.COLUMN: return "COLUMN";
    case objDataType.SLAB: return "SLAB";
    case objDataType.WALL: return "WALL";
    case objDataType.LOAD: return "LOAD";
    case objDataType.FOOTER: return "FOOTER";
    case objDataType.STRIPFOOTER: return "STRIPFOOTER";
    case objDataType.PILECAP: return "PILECAP";
    default:
      return "UNKNOWN OBJDATA"
  }
}
