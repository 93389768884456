/**
 * \file graphic-viewport.ts
 * Implementacion de la clase GraphicViewport, surgida de la refactorizacion del antiguo interface IViewportDef,
 * que llevara todo lo necesario para el establecimiento y uso de viewports graficos en pantalla.
 * Hemos puesto ese nombre para evitar posibles colisiones con el termino comun "Viewport".
 */

// Estos son los nuevos controles del japones Akihiro Oyamada aka Yomotsu. Sustituyen a los OrbitControls originales de
// Three.js que desaparecen de nuestro codigo. Han sido adaptados de:
// https://github.com/yomotsu/camera-controls
import { CameraControls } from "./helpers/camera-controls/CameraControls"
import { refViewPlane } from "./coordinates/plane-manager";
import * as THREE from "three";
import { XYZmo_RCOH8 } from "./helpers/xyzmo_rcoh8";
import { FitToOptions } from "./helpers/camera-controls/types";
import { GraphicViewportManager } from "./graphic-viewport-manager";
import { setMouseControls } from "./mouse-settings";

/**
 * El tipo de las funciones que procesan eventos.
 */
export type CallbackType = (ev: Event) => void;

/**
 * El tipo de nuestros callbacks para addEventListener() y removeEventListener(), formados por 3 elementos:
 * [1] Quien soporta el callback.
 * [2] Que callback soporta.
 * [3] La funcion llamada.
 */
export type CallbackInfo = {
  who: any;
  what: string;
  cbFunc: CallbackType;
};

export class Callbackable {

  /**
   * Aqui tenemos todos los callbacks registrados con addCallback().
   * Nunca seran borrados hasta llamar a destroy(), aunque podemos activarlos/desactivarlos a voluntad, bien sean todos o de uno en uno.
   */
  private _vCallbacks: CallbackInfo[];

  /**
   * Comprobacion de que tenemos registrado un callback determinado.
   *
   * @param who 
   * @param what 
   * @param cbFunc 
   * @returns 
   */
  public exists(who: any, what: string, cbFunc: CallbackType): boolean {
    const N = this._vCallbacks.length;
    for (let i = 0; i < N; ++i) {
      const info = this._vCallbacks[i];
      if (info.who === who && info.what === what && info.cbFunc === cbFunc) {
        return true;
      }
    }
    return false;
  }

  /**
   * Registro de un callback, sin activacion implicita, si es que no ha sido registrado previamente.
   * 
   * @param who 
   * @param what 
   * @param cbFunc 
   * @returns 
   */
  public addCallback(who: any, what: string, cbFunc: CallbackType): boolean {
    if (!this.exists(who, what, cbFunc)) {
      const info: CallbackInfo = { who, what, cbFunc };
      this._vCallbacks.push(info);
      return true;
    }
    console.error("ERROR: El callback dado ya ha sido registrado.");
    debugger;
    return false;
  }

  public destroy() {
    this.setDisabled();
    if (this._vCallbacks) {
      this._vCallbacks.length = 0;
    }
  }

  /**
   * Activa todos los callbacks registrados disponibles.
   */
  public setEnabled() {
    const N = this._vCallbacks.length;
    for (let i = 0; i < N; ++i) {
      const info = this._vCallbacks[i];
      info.who.addEventListener(info.what, info.cbFunc);
    }
  }

  /**
   * Desactiva todos los callbacks registrados disponibles.
   */
  public setDisabled() {
    const N = this._vCallbacks.length;
    for (let i = 0; i < N; ++i) {
      const info = this._vCallbacks[i];
      info.who.removeEventListener(info.what, info.cbFunc);
    }
  }

  /**
   * Activa un callback ya existente y registrado.
   *
   * @param who 
   * @param what 
   * @param cbFunc 
   * @returns 
   */
  public enableCallback(who: any, what: string, cbFunc: CallbackType): boolean {
    if (this.exists(who, what, cbFunc)) {
      const info: CallbackInfo = { who, what, cbFunc };
      info.who.addEventListener(info.what, info.cbFunc);
      return true;
    }
    console.error("ERROR: El callback dado NO ha sido registrado.");
    debugger;
    return false;
  }

  /**
   * Desactiva un callback ya existente y registrado.
   *
   * @param who 
   * @param what 
   * @param cbFunc 
   * @returns 
   */
  public disableCallback(who: any, what: string, cbFunc: CallbackType): boolean {
    if (this.exists(who, what, cbFunc)) {
      const info: CallbackInfo = { who, what, cbFunc };
      info.who.removeEventListener(info.what, info.cbFunc);
      return true;
    }
    console.error("ERROR: El callback dado NO ha sido registrado.");
    debugger;
    return false;
  }

}

/**
 * Todo viewport debe ser de alguno de estos tipos.
 *
 * @enum {number}
 */
export enum ViewportType {
  Unassigned = 0,
  ViewPerspective3D,
  ViewPlanOrtho2D,
  ViewElevOrtho2D,
  ViewRightOrtho2D
};

export const ViewportTypeTxt = [
  "Unassigned",
  "ViewPerspective3D",
  "ViewPlanOrtho2D",
  "ViewElevOrtho2D",
  "ViewRightOrtho2D"
];


/**
 * Clase encargada de la gestion para mantener un viewport en pantalla.
 *
 * @export
 * @class GraphicViewport
 */
export class GraphicViewport extends Callbackable {

  // Todo viewport tiene un nombre UNICO que lo identifica de cara a su padre contenedor GraphicProcessor.
  name: string;

  // Un elemento HTML OBLIGATORIO al que anclamos el viewport para que le brinde su RECT asi como sus eventos directos al cameraControl.
  // Ahora es obligatorio tambien para el mainView. Inicialmente nulo hasta poder ser incorporado.
  // Simplificamos codigo usando el idion del "!". Agradecimientos a JaWS.
  elemHTML: HTMLDivElement; // | null;

  /**
   * Las coordenadas de origen (left, top) se refieren a un hipotetico origen (0, 0) situado en la esquina SUPERIOR IZQUIERDA.
   * Esto es el RECT del viewport, que o bien se pilla directamente del elemHTML o bien se establece a mano. Por defecto son 0.
   * Ojo, que internamente los consideramos en el INTERVALO [0, 1] para poder normalizar con facilidad.
   * Por esa razon podemos denominarlas como coordenadas normalizadas y son lo que permite el resize.
   * Son accesibles por medio de las propiedades RW left01, top01, width01, y height01.
   */
  ltwh01: [number, number, number, number];

  // 
  /**
   * De forma paralela al dmc ltwh01 tenemos los valores equivalentes, pero en PIXELS.
   * Aqui estan los valores en PIXELES absolutos correspondientes a los valores 01 una vez aplicada la normalizacion sobre el contenedor...
   * Son accesibles por medio de las propiedades RW leftPx, topPx, widthPx, y heightPx.
   */
  ltwhPx: [number, number, number, number];

  // Color de fondo opcional para el viewport. Si no se diera lo pondremos como negro.
  backgroundColor?: THREE.Color;

  // La camara empleada en ese viewport, bien sea ortografica o de perspectiva. Null por defecto, aunque es de obligatoria incorporacion.
  camera: THREE.Camera | null;

  // El gestor de movimiento, alimentado por el elemHTML o directamente por el container general del GPO (en tal caso hay que filtrar...).
  // Es opcional, con lo que no habria movimiento si no se da.
  controls?: CameraControls;

  // Para activar/desactivar el rendering del viewport. Por defecto es inactivo (false) hasta que se active por su correcta incorporacion
  // al GPO; luego se podra activar o no a voluntad.
  enabled: boolean;

  // Para que el plane manager sepa el plano de referencia de vision al que se debe enfrentar.
  // Es decir el plano de trabajo para este viewport.
  viewPlaneType: refViewPlane;

  // Todo viewport tendra su gizmo rotacional. Ademas ese gizmo soporta los 3 botones de cerrar y subdividir.
  // Ademas el viewport es el padre del gizmo.
  gizmo: XYZmo_RCOH8 | null;

  // Todo viewport apunta al manager que lo genero, para poder acceder a otras informaciones que necesite.
  // Sera accesible en modo RO por la propiedad owner.
  private _ownerMngr: GraphicViewportManager;

  // Nuevas variables by ETabs:________________________________________________________________________________________

  /** Todo viewport puede ser de un cierto tipo especificado en el enum. Ademas el tipo podria cambiar dinamicamente. */
  type: ViewportType;

  /**
   * Asociado al tipo tenemos un titulo de la forma <*** view - subTitle>, con la parte *** que puede ser "3D"/"Plan"/"Elev."/"Right"
   * segun el tipo en curso y la parte subTitle que se fija mediante la llamada a la fmc setTitle().
   */
  title: string;

  /**
   * Este sera el item HTML donde colocaremos el contenido del dmc title como un div.
   * Sera pintado mediante una llamada a la fmc setTitle().
   *
   * @type {(HTMLDivElement | null)}
   * @memberof GraphicViewport
   */
  titleHTML: HTMLDivElement | null;

  /** Div para poner el pseudo pulsador de cerrado del vp. Llevara una 'X'. */
  closeHTML: HTMLDivElement | null;

  /** Div para poner el pseudo pulsador de agregar un nuevo vp (duplicado del actual). Llevara un '-'. */
  newHTML: HTMLDivElement | null;

  /** Div para mostrar informaciones diversas en el VP en curso. Llamese a setInfo(). */
  infoHTML: HTMLDivElement | null;

  /**
   * Un view no esta activo a menos que se pinche sobre el, al contrario de antes que bastaba con pasar el puntero del
   * raton por encima.
   * Obviamente solo puede haber uno activo como maximo simultaneamente.
   *
   * @type {boolean}
   * @memberof GraphicViewport
   */
  isActive: boolean;

  /**
   * Por el tema de los botones externos en ETabs se tiene que poder volver a un estado inicial previo de los controles
   * de la camara, que controlamos con este flag.
   */
  isStatusSaved: boolean;

  /**
   * Para no poder joder al vp ACTIVO en curso cuando el puntero del raton esta FUERA de su AABB.
   * Con esto evitamos hacer doble click fuera del tiesto.
   * Vale true cuando estamos entre nuestras 4 paredes y false cuando nos salimos.
   * Obviamente al principio deberia valer true, pero si se crea un nuevo vp lo activamos y le damos el foco, y aqui se
   * retuerce algo la cosa...
   * Lo vamos a usar para poder desconectar las teclas graficas cuando el cursor esta fuera del vp en curso, para que
   * estas no se puedan inmiscuir con otros posibles comportamientos.
   */
  isCursorOut: boolean;

  // Fin nuevas variables ETabs._______________________________________________________________________________________

  public static _activeBorderColor: string = "#6366f1";
  public static _inactiveBorderColor: string = "#52525b";

  // Identificadores de eventos gestionados, para posible cambio rapido.
  // Con el nuevo funcionamiento en modo ETabs tenemos que hacer click en el div/VP que nos interese para activarlo y
  // desactivar al previamente activo, siempre existente.
  private static readonly _eventClick = "click";
  private static readonly _eventContextMenu = "contextmenu";
  private static readonly _eventDblClick = "dblclick";

   // Los identificadores de los eventos de entrada y salida, que pueden ser otros.
   private static readonly _eventIntro = "mouseenter";
   private static readonly _eventOutro = "mouseleave";

   // El evento que finalmente se usa como sustituto del click para marcar el vp activo en vez del anterior click de
   // forma que se solucionen los problemas con las operaciones CAD.
   private static readonly _eventPointerUp = "pointerup";

  // Aqui tenemos todas las pulsaciones de teclado asociadas en pares para su rapido desregistro con removeListener...
  private _keystrokesCallbacks: [any, any][];


  // ___________________________________________Fin_de_la_declaracion_de_datos_miembro______________________________________
  // ^^^^^^^ Poner encima los nuevos DMC's que vayas agregando.

  /**
   * Constructor por defecto solo con el objeto GP como unico parametro ya que los demas seran asignados mas adelante.
   * @param {GraphicViewportManager} ownerMngr
   * @memberof GraphicViewport
   */
  constructor(ownerMngr: GraphicViewportManager) {
    // Llamando al constructor paterno.
    super();
    this.name = "";
    // Idiom called "non-null assertion operator".
    this.elemHTML = null!;
    this.ltwh01 = [0, 0, 0, 0];
    this.ltwhPx = [-1, -1, -1, -1];
    this.camera = null;

    this.enabled = false;

    // Ojo, que aunque doy por defecto el valor para la perspectiva, sera responsabilidad del exterior el asignar correctamente este campo.
    this.viewPlaneType = refViewPlane.DEF;
    this.gizmo = null;

    this._ownerMngr = ownerMngr;

    // Nuevas variables debidas al funcionamiento "a la ETabs".
    this.type = ViewportType.Unassigned;
    this.title = "";
    this.titleHTML = this.infoHTML = this.closeHTML = this.newHTML = null;
    this.isActive = false;
    this.isStatusSaved = false;

    // Lo habitual al crear un vp es que se hace desde el exterior del mismo y no esta dentro el cursor del raton.
    this.isCursorOut = false;

    this._keystrokesCallbacks = [];
  }

  get keystrokesCallbacks(): [any, any][] {
    return this._keystrokesCallbacks;
  }

  get left01(): number { return this.ltwh01[0]; }
  get top01(): number { return this.ltwh01[1]; }
  get width01(): number { return this.ltwh01[2]; }
  get height01(): number { return this.ltwh01[3]; }

  set left01(v: number) { this.setLTWH01(v, 0); }
  set top01(v: number) { this.setLTWH01(v, 1); }
  set width01(v: number) { this.setLTWH01(v, 2); }
  set height01(v: number) { this.setLTWH01(v, 3); }

  private setLTWH01(v: number, pos: number): void {
      this.ltwh01[pos] = v;
      if (!inInterval01(v)) {
          v = clamp01(v);
      }
  }

  get leftPx(): number { return this.ltwhPx[0]; }
  get topPx(): number { return this.ltwhPx[1]; }
  get widthPx(): number { return this.ltwhPx[2]; }
  get heightPx(): number { return this.ltwhPx[3]; }

  set leftPx(v: number) { this.setLTWHPx(v, 0); }
  set topPx(v: number) { this.setLTWHPx(v, 1); }
  set widthPx(v: number) { this.setLTWHPx(v, 2); }
  set heightPx(v: number) { this.setLTWHPx(v, 3); }

  get owner(): GraphicViewportManager { return this._ownerMngr; }

  private setLTWHPx(v: number, pos: number): void {
      this.ltwhPx[pos] = v;
      // \ToDo: Comprobar que no se salen de madre...
  }

  // Suprime todos los callbacks de teclado asignados a este vp.
  public removeKeystrokesCallbacks(): void {
    // window.alert(`Deleting ${this.keystrokesCallbacks.length} keystrokes for vp "${this.name}".`);
    if (0 === this.keystrokesCallbacks.length) {
      console.error(`WARNING: There are NO previous callbacks.`);
    }

    if (this._keystrokesCallbacks.length) {
      for (const [who, what] of this._keystrokesCallbacks) {
        who.removeEventListener('holding', what);
      }
      this._keystrokesCallbacks.length = 0;
    }
  }

  public destroyViewport(): void {

    this.removeKeystrokesCallbacks();

    if (this.gizmo) {
      this.gizmo.destroy();
      this.gizmo = (undefined as unknown) as XYZmo_RCOH8;
    }
    // Tambien debemos quitar el elemHTML del vp original, pues sino sigue en pantalla y roba eventos.
    if (this.elemHTML) {
      this.removeIntroOutroCallbacks2Div(this.elemHTML);
      this.unSubscribeMouseEvents();

      this.titleHTML?.remove();
      this.infoHTML?.remove();
      this.closeHTML?.remove();
      this.newHTML?.remove();

      if (this.elemHTML.parentElement) {
        this.elemHTML.remove();
      }
      this.elemHTML = undefined!;
      this.titleHTML = (undefined as unknown) as HTMLDivElement;
      this.infoHTML = (undefined as unknown) as HTMLDivElement;
      this.closeHTML = (undefined as unknown) as HTMLDivElement;
      this.newHTML = (undefined as unknown) as HTMLDivElement;
    }
    if (this.controls) {
      // Esto libera los listeners y demas...
      this.controls.dispose();
      this.controls = (undefined as unknown) as CameraControls;
    }
    if (this.camera) {
      // Creo que con esto bastaria.
      this.camera = (undefined as unknown) as THREE.Camera;
    }

    // Llamada a la destruccion paterna.
    super.destroy();
  }

  /**
   * Una lambda expresion para saber si unas coordenadas (x, y) caen dentro de un viewport, pero NORMALIZADAS al intervalo [0, 1].
   * Ojo que las coordenadas (x, y) vienen normalizadas en el intervalo [0, 1] y referenciadas a la ESI (esquina SUPERIOR izquierda).
   *
   * @static
   * @memberof GraphicViewport
   * @param {number} x
   * @param {number} y
   * @param {GraphicViewport} viewport
   * @returns {boolean}
   */
  // public static readonly inViewport = (x: number, y: number, viewport: GraphicViewport): boolean => {
  //   // Ojo, que para evitar problemas requerimos estar en el interior TOTAL, no tocando la frontera.
  //   if (x <= viewport.left01 || viewport.left01 + viewport.width01 <= x) {
  //     return false;
  //   }
  //   if (y <= viewport.top01 || viewport.top01 + viewport.height01 <= y) {
  //     return false;
  //   }
  //   return true;
  // };


  /**
   * Dado un viewport dentro de un div global, que es el que hace la llamada, con sus valores relativos internos en [0, 1]
   * devolvemos los valores absolutos del mismo, [xLeft, yTop, width, height] en numeros enteros correspondientes con pixels.
   *
   * @param divCntnr
   * @returns 
   */
  public getXYWH4ViewportInHTMLDivElement(divCntnr: HTMLDivElement | HTMLCanvasElement): [number, number, number, number] {
    const r = divCntnr.getBoundingClientRect();
    const xLeft = r.left;
    const yTop = r.top;
    const width = r.width;
    const height = r.height;
    // Por un lado tenemos los valores ABSOLUTOS en pixels dados por la anterior AABB para el div contenedor total, con sus
    // 4 valores asociados. Pero para completar la jugada tomamos los 4 valores RELATIVOS en [0, 1] presentes en el vp y correspondientes
    // con los otros 4. Asi que jugamos con ambos para sacar los valores del viewport en pixels.
    const widthVP = Math.trunc(width * this.width01);
    const heightVP = Math.trunc(height * this.height01);
    const xLeftVP = xLeft + Math.trunc(width * this.left01);
    const yTopVP = yTop + Math.trunc(height * this.top01);

    return [xLeftVP, yTopVP, widthVP, heightVP];
  }

  /**
   * Inversamente a getXYWH4ViewportInHTMLDivElement() en esta fmc se fijan los DMC's *01 y *Px en base a un gran div (el
   * contenedor global de la pantalla grafica) con respecto al div en curso del VP.
   *
   * @param {HTMLDivElement} bigDiv
   * @memberof GraphicViewport
   */
  public setParams01Px(bigDiv: HTMLDivElement): void {
    const bigAABB = bigDiv.getBoundingClientRect();
    const W = bigAABB.width;
    const H = bigAABB.height;
    const L = bigAABB.left;
    const T = bigAABB.top;
    const smallAABB = this.elemHTML.getBoundingClientRect();
    // No se deberian usar decimales, ya que van jodiendo los calculos.
    const w = /*Math.floor*/(smallAABB.width);
    const h = /*Math.floor*/(smallAABB.height);
    const l = /*Math.floor*/(smallAABB.left);
    const t = /*Math.floor*/(smallAABB.top);

    // Fijaremos los parametros *01 y *Px.
    this.width01 = w / W;
    this.height01 = h / H;
    this.left01 = (l - L) / W;
    this.top01 = (t - T) / H;

    this.widthPx = w;
    this.heightPx = h;
    this.leftPx = l;
    this.topPx = t;
  }

  /**
   * Dados 2 viewports nos dice si el primero y el segundo estan exactamente el primero a la izquierda del segundo (con una
   * arista vertical comun que separa y toca a ambos, devolviendo +1), si estan exactamente el primero encima del segundo (con
   * una arista horizontal comun separandolos, devolviendo +2) o si hay algo que los haga no adyacentes (devuelve 0).
   *
   * @param vpA 
   * @param vpB 
   * @returns 
   */
  public static areViewportsContiguous(vpA: GraphicViewport, vpB: GraphicViewport): number {
    const divA = vpA.elemHTML;
    const divB = vpB.elemHTML;
    // Recuerda que las AABB van referenciadas a la ESI.
    const aabbB = divB.getBoundingClientRect();
    const aabbA = divA.getBoundingClientRect();
    const equalWidth = (aabbA.width === aabbB.width);
    const equalHeight = (aabbA.height === aabbB.height);
    // Por cojones deben coincidir en anchura o altura para poder ser contiguos.
    if (!equalWidth && !equalHeight) {
      console.error("Dimensiones NO coincidentes.");
      return 0;
    }

    const testTopDown = (r1: DOMRect, r2: DOMRect): boolean => {
      const equalLeft = (r1.left === r2.left);
      const equalRight = (r1.right === r2.right);
      if (equalLeft && equalRight) {
        const equalBottomTop = (r1.bottom === r2.top);
        if (equalBottomTop) {
          return true;
        }
      }
      return false;
    };

    const testLeftRight = (r1: DOMRect, r2: DOMRect): boolean => {
      const equalTop = (r1.top === r2.top);
      const equalBottom = (r1.bottom === r2.bottom);
      if (equalTop && equalBottom) {
        const equalRightLeft = (r1.right === r2.left);
        if (equalRightLeft) {
          return true;
        }
      }
      return false;
    };

    let numCoincidences = 0;

    let topDown: boolean = false;
    if (equalWidth) {
      // Si tienen la misma anchura deben tener las mismas izquierda y derecha, y por tanto A estaria encima de B...
      if (testTopDown(aabbA, aabbB)) {
        ++numCoincidences;
        topDown = true;
      }
    }

    let leftRight: boolean = false;
    if (equalHeight) {
      // Si tienen la misma altura deben tener los mismos arriba y abajo, y por tanto A estaria a la izquierda de B...
      if (testLeftRight(aabbA, aabbB)) {
        ++numCoincidences;
        leftRight = true;
      }
    }

    if (divA.parentElement && divA.parentElement) {
      if (divA.parentElement !== divB.parentElement) {
        console.error("Tienen padres diferentes!!!");
      } else {
        let father = divA.parentElement as HTMLDivElement;
        const aabbF = father.getBoundingClientRect();
        if (topDown) {
          const equalWidth = (aabbA.width === aabbF.width);
          const equalHeight = (aabbA.height + aabbB.height === aabbF.height);
          if (equalWidth && equalHeight) {
            // Coincidencia entre los limites.
            const equalLeft = (aabbA.left === aabbF.left);
            const equalRight = (aabbA.right === aabbF.right);
            const equalTop = (aabbA.top === aabbF.top);
            const equalBottom = (aabbB.bottom === aabbF.bottom);

            if (equalLeft && equalRight && equalTop && equalBottom) {
              ;
            } else {
              console.error("El padre no coincide??? (1)");
            }
          }
        }
        if (leftRight) {
          const equalWidth = (aabbA.width + aabbB.width === aabbF.width);
          const equalHeight = (aabbA.height === aabbF.height);
          if (equalWidth && equalHeight) {
            // Coincidencia entre los limites.
            const equalLeft = (aabbA.left === aabbF.left);
            const equalRight = (aabbB.right === aabbF.right);
            const equalTop = (aabbA.top === aabbF.top);
            const equalBottom = (aabbA.bottom === aabbF.bottom);

            if (equalLeft && equalRight && equalTop && equalBottom) {
              ;
            } else {
              console.error("El padre no coincide??? (2)");
            }
          }

        }
      }
    } else {
      console.error("Problemas en algun padre...");
    }

    if (numCoincidences === 1) {
      if (leftRight) {
        return 1;
      } else {
        return 2;
      }
    }

    return 0;
  }

  // _____________Nueva infraestructura para decidir VP activo basada en su DIV_____________________________________________


  // Lambda funciones que se registran para los eventos.
  private lambdaMouseIntro = (event: MouseEvent) => {
    this.processMouseIntro(event);
  };

  private lambdaMouseOutro = (event: MouseEvent) => {
    this.processMouseOutro(event);
  };

  private lambdaMouseClick = (event: MouseEvent) => {
    this.processMouseClick(event);
  };

  // Esta solo servira para las pulsaciones con el boton derecho sobre vp's inactivos:
  // En tal caso los activara, pero si estaban ya activos se comportara como le toque por defecto.
  private lambdaMouseRightClick = (event: MouseEvent) => {
    this.processMouseRightClick(event);
  };

  private lambdaMouseDown = (event: MouseEvent) => {
    this.processMouseDown(event);
  };

  private lambdaMouseUp = (event: MouseEvent) => {
    this.processMouseUp(event);
  };

  // Funciones de procesamiento asociadas a las lambdaFunctions anteriores.
  private processMouseIntro(event: MouseEvent): void {
    // const div = event.currentTarget as HTMLDivElement;
    this.isCursorOut = false;
    // console.log(`*** INTRO in DIV "${div.id}" ===> VP "${this.name}" OUT:${this.isCursorOut}`);
  }

  private processMouseOutro(event: MouseEvent): void {
    // const div = event.currentTarget as HTMLDivElement;
    this.isCursorOut = true;
    // console.log(`*** OUTRO of DIV "${div.id}" ===> VP "${this.name}" OUT:${this.isCursorOut}`);
  }

  /**
   * Con la nueva filosofia ETabs ahora sera obligatorio hacer un viewport activo SOLO cuando se hace click sobre el mismo.
   *
   * @private
   * @param {MouseEvent} event
   * @memberof GraphicViewport
   */
   private processMouseClick(event: MouseEvent): void {

    // Si ya era el vp activo me piro.
    const currentActiveViewport = this.owner.getActiveViewport();
    if (this === currentActiveViewport) {
      // Si ya era el activo y lo estamos pulsando de nuevo, nada que hacer.
      // console.log("Already active VP.");
      return;
    } else {
      // En caso contrario hay cambio de vp y al anteriormente activo lo cambiariamos de color de borde, entre otras cosas,
      // como cambiarle un futurible fondo de letrerito o lo que sea...
      currentActiveViewport.graphicDeactivation();
    }

    // Y ademas al entrar en este DIV vamos a poner como activo o en curso al VP que lo contiene.
    const div = event.currentTarget as HTMLDivElement;
    console.log(`CLICK "${div.id}"" <===> VP:"${this.name}"`);
    if (this !== this.owner.getViewport4Div(div)) {
      debugger;
    } else {
      if (this.setActiveViewport()) {
        event.stopPropagation();
        console.log("ACTIVE VP: '" + this.name + "'.");
      }
    }
  }

  /**
   * El click con boton derecho sobre vp inactivo lo activa, pero si estaba ya activo no hace nada.
   *
   * @private
   * @param {MouseEvent} event
   * @returns {void}
   * @memberof GraphicViewport
   */
  private processMouseRightClick(event: MouseEvent): void {

    // Si ya era el vp activo me piro.
    let currentActiveViewport = this.owner.getActiveViewport();
    if (this === currentActiveViewport) {
      // Si ya era el activo y lo estamos pulsando de nuevo, nada que hacer.
      // console.log("Already active VP.");
      // El evento se propagaria a quien tocase...
      return;
    }

    // Pero en caso contrario hay cambio de vp y al anteriormente activo lo cambiariamos de color de borde, entre otras cosas,
    // como cambiarle un futurible fondo de letrerito o lo que sea. Pero lo primero es dejar de propagar!!!.
    event.preventDefault();
    currentActiveViewport.graphicDeactivation();

    // Y ademas al entrar en este DIV vamos a poner como activo o en curso al VP que lo contiene.
    const div = event.currentTarget as HTMLDivElement;
    console.log(`RIGHTCLICK "${div.id}"" <===> VP:"${this.name}"`);
    if (this !== this.owner.getViewport4Div(div)) {
      debugger;
    } else {
      if (this.setActiveViewport()) {
        event.stopPropagation();
        console.log("ACTIVE VP: '" + this.name + "'.");
      }
    }
  }

  private processMouseDown(event: MouseEvent): void {
    const div = event.currentTarget as HTMLDivElement;
    console.log(`DOWN "${div.id}"`);
  }

  private processMouseUp(event: MouseEvent): void {
    const div = event.currentTarget as HTMLDivElement;
    console.log(`UP "${div.id}"`);
  }

  /**
   * Registrador de callbacks.
   *
   * @param {HTMLDivElement} div
   * @memberof GraphicViewport
   */
  public addIntroOutroCallbacks2Div(div: HTMLDivElement): void {
    div.addEventListener(GraphicViewport._eventIntro, this.lambdaMouseIntro);
    div.addEventListener(GraphicViewport._eventOutro, this.lambdaMouseOutro);

    // Como en ETabs ahora todo va con un click.
    // div.addEventListener(GraphicViewport._eventClick, this.lambdaMouseClick);
    div.addEventListener(GraphicViewport._eventPointerUp, this.lambdaMouseClick);
    
    // Cambio para poder seleccionar un vp inactivo al pulsar el boton derecho sobre el mismo.
    // div.addEventListener(GraphicViewport._eventContextMenu, this.lambdaMouseRightClick);
    div.addEventListener(GraphicViewport._eventPointerUp, this.lambdaMouseRightClick);

    // div.addEventListener(GraphicViewport.eventMouseDown, this.lambdaMouseDown);
    // div.addEventListener(GraphicViewport.eventMouseUp, this.lambdaMouseUp);
  }

  /**
   * Desregistrador de callbacks.
   *
   * @param {HTMLDivElement} div
   * @memberof GraphicViewport
   */
  public removeIntroOutroCallbacks2Div(div: HTMLDivElement): void {
    div.removeEventListener(GraphicViewport._eventIntro, this.lambdaMouseIntro);
    div.removeEventListener(GraphicViewport._eventOutro, this.lambdaMouseOutro);

    // div.removeEventListener(GraphicViewport._eventClick, this.lambdaMouseClick);
    // div.removeEventListener(GraphicViewport._eventContextMenu, this.lambdaMouseRightClick);
    div.removeEventListener(GraphicViewport._eventPointerUp, this.lambdaMouseClick);
    div.removeEventListener(GraphicViewport._eventPointerUp, this.lambdaMouseRightClick);
    
    // div.removeEventListener(GraphicViewport.eventMouseDown, this.lambdaMouseDown);
    // div.removeEventListener(GraphicViewport.eventMouseUp, this.lambdaMouseUp);
  }

  /**
   * Devuelve true si alguna de las dimensiones del rect del div dado es NO entera.
   * @param div 
   */
  public static testDiv4NonIntegerDims(div: HTMLDivElement): boolean {
    let nonIntegerCnt = 0;
    const r = div.getBoundingClientRect();
    // La mejor forma de comprobar si un numero es entero: Number.isInteger().
    if (!Number.isInteger(r.left)) {
      console.log("Non integer left = " + r.left.toFixed(3));
      ++nonIntegerCnt;
    }
    if (!Number.isInteger(r.top)) {
      console.log("Non integer top = " + r.top.toFixed(3));
      ++nonIntegerCnt;
    }
    if (!Number.isInteger(r.right)) {
      console.log("Non integer right = " + r.right.toFixed(3));
      ++nonIntegerCnt;
    }
    if (!Number.isInteger(r.bottom)) {
      console.log("Non integer bottom = " + r.bottom.toFixed(3));
      ++nonIntegerCnt;
    }
    if (!Number.isInteger(r.width)) {
      console.log("Non integer width = " + r.width.toFixed(3));
      ++nonIntegerCnt;
    }
    if (!Number.isInteger(r.height)) {
      console.log("Non integer height = " + r.height.toFixed(3));
      ++nonIntegerCnt;
    }

    return 0 !== nonIntegerCnt;
  }

  /**
   * Si alguna de las 2 esquinas del div, (left, top), (right, bottom) tiene alguna coordenada no entera, se redondea perdiendo
   * la parte decimal para que todo sea entero.
   *
   * @param div 
   */
  public static assignIntegerDimensions2Div(div: HTMLDivElement): void {
    const r = div.getBoundingClientRect();
    // La mejor forma de comprobar si un numero es entero: Number.isInteger().
    if (!Number.isInteger(r.left)) {
      // Redondeamos por la derecha, pasando a N + 1 y perdiendo la parte decimal.
      const left = 1 + Math.trunc(r.left);
      div.style.left = left + "px";
    }

    if (!Number.isInteger(r.right)) {
      // Redondeamos por la izquierda, simplemente truncando los decimales.
      const right = Math.trunc(r.right);
      div.style.right = right + "px";
    }

    if (!Number.isInteger(r.top)) {
      // Redondeamos por abajo.
      const top = 1 + Math.trunc(r.top);
      div.style.top = top + "px";
    }
    if (!Number.isInteger(r.bottom)) {
      // Redondeamos por arriba.
      const bottom = Math.trunc(r.bottom);
      div.style.bottom = bottom + "px";
    }
  }

  /**
   * Funcion centralizada que activa todos los callbacks del vp.
   */
  public setEnabled(): void {
    if (this.enabled) {
      console.log("WARNING setEnabled(): Intentando activar un vp ya activo.");
      debugger;
      return;
    }
    this.enabled = true;

    // Vamos a activar todos los callbacks internos del vp.

  }

  /**
   * Funcion centralizada que manda a dormir al vp haciendo que desconecte TODOS sus callbacks.
   */
  public setDisabled(): void {
    if (!this.enabled) {
      console.log("WARNING setDisabled(): Intentando desactivar un vp ya inactivo.");
      debugger;
      return;
    }
    this.enabled = false;

    // Vamos a desactivar todos los callbacks internos del vp.
  }

  /**
   * Coloca al vp que hace la llamada como el actualmente en curso y ademas lo refleja graficamente.
   */
  public setActiveViewport(): boolean {
    if (this.owner.setActiveViewport(this)) {
      this.graphicActivation();
      return true;
    }
    return false;
  }

  /** Activacion grafica de los elementos HTML para que resalten en el vp activo. */
  public graphicActivation(): void {
    // Algo ha cambiado en el Chrome que ahora esto no funciona y se necesita lo de abajo.
    // this.elemHTML.style.borderColor = GraphicViewport._activeBorderColor;
    // Ademas hacemos que el borde activo tenga 2 pixels de grosor mientras que el inactivo tienen 1.
    this.elemHTML.style.border = "2px solid " + GraphicViewport._activeBorderColor;
    (this.titleHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._activeBorderColor;
    (this.closeHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._activeBorderColor;
    (this.newHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._activeBorderColor;
    // Quitando transparencia.
    (this.titleHTML as HTMLDivElement).style.opacity = "1.0";
    (this.closeHTML as HTMLDivElement).style.opacity = "1.0";
    (this.newHTML as HTMLDivElement).style.opacity = "1.0";
    // Visibilizamos again.
    (this.closeHTML as HTMLDivElement).style.visibility = 'visible';
    (this.newHTML as HTMLDivElement).style.visibility = 'visible';

    // Parece que esto acaba con los problemas de inercia al mariconear sobre un vp no activo.
    if (this.controls) {
      this.controls.enabled = true;
    }
  }

  /** Desactivacion grafica de los elementos HTML para que pasen a segundo plano en un vp inactivo. */
  public graphicDeactivation(): void {
    this.elemHTML.style.border = "1px solid " + GraphicViewport._inactiveBorderColor;
    (this.titleHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._inactiveBorderColor;
    (this.closeHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._inactiveBorderColor;
    (this.newHTML as HTMLDivElement).style.backgroundColor = GraphicViewport._inactiveBorderColor;
    // Agregamos transparencia.
    (this.titleHTML as HTMLDivElement).style.opacity = "0.50";
    (this.closeHTML as HTMLDivElement).style.opacity = "0.50";
    (this.newHTML as HTMLDivElement).style.opacity = "0.50";
    // Invisibilizamos para que no estorbe. El hidden no funciona, pero esto si.
    (this.closeHTML as HTMLDivElement).style.visibility = 'hidden';
    (this.newHTML as HTMLDivElement).style.visibility = 'hidden';

    // Parece que esto acaba con los problemas de inercia al mariconear sobre un vp no activo.
    if (this.controls) {
      this.controls.enabled = false;
    }    
  }

  /**
   * Aplica al elemento HTML dado, generalmente un div, una serie de estilos graficos.
   * Se usa en los titulos, boton de cierre y de nuevo vp.
   */
  public static applyStyles2ItemHTML(elem: HTMLDivElement): void {
    elem.style.color = 'white';
    elem.style.cursor = "pointer";
    elem.style.position = "absolute";

    elem.style.height = "30px";
    elem.style.borderRadius = "5px";
    elem.style.paddingLeft = "10px";
    elem.style.paddingRight = "10px";
    // Ajuste totalmente centrado.
    elem.style.display = "flex";
    elem.style.justifyContent = "center";
    elem.style.alignItems = "center";
    // Para que al reducirse la ventana no se pinte fuera.
    elem.style.overflow = "hidden";

    // Quizas sea necesario?.
    // Con esto consigo no meter mano al texto de los pseudo botones que son los divs.
    // elem.style.userSelect = 'none';

  }

  private lambdaMouseClick4CloseVP = (event: MouseEvent) => {
    this.processMouseClick4CloseVP(event);
  };

  private lambdaMouseClick4NewVP = (event: MouseEvent) => {
    this.processMouseClick4NewVP(event);
  };

  private lambdaMouseDoubleClick4TitleVP = (event: MouseEvent) => {
    this.processMouseDoubleClick4TitleVP(event);
  };

  private processMouseClick4CloseVP(event: MouseEvent): void {
    // Si estamos en modo de zoom no permitimos el borrado.
    if (this.owner.isZoomedState()) {
      let txt = "WARNING: The system doesn't allow deletion of zoomed viewport.\n";
      txt += "Please press 'Z' to reduce viewport and retry the deletion.";
      console.error(txt);
      window.alert(txt);
      return;
    }

    // Toca destruir a este viewport, (que es totalmente destruible, ya que en caso contrario no tendria ese boton
    // activado), y devolver ese espacio sobrante a su hermano generador, si es que es ello posible...
    // Ademas esa destruccion debe ser diferida para evitar problemas.
    console.log("Has pulsado el CLOSE del vp '" + this.name + "'.");
    event.stopPropagation();
    this.selfDestruction();
  }

  public selfDestruction(): void {
    if (this.owner.addViewport2Delete(this)) {
      this.graphicDeactivation();  
    }
  }

  private processMouseClick4NewVP(event: MouseEvent): void {
    console.log("Has pulsado el NEW del vp '" + this.name + "'.");
    event.stopPropagation();
    this.selfReplication();
  }

  public selfReplication(): void {
    // Lo mas logico seria activar al nuevo y desactivar al padrecico, pero al padre lo desactivamos en el estado actual,
    // antes de hacer nada mas, para asi quitar correctamente el borde?. Mas abajo activaremos al nuevo.
    this.graphicDeactivation();

    /*
      Se crea un hermano vertical u horizontal en funcion del tamaño disponible:
      a) Si el VP es mas ancho que alto entonces division VERTICAL.

            +-------------+     +------+------+
            |             |     |      |      |
            |     Src     | ==> | Src' | New  |
            |             |     |      |      |
            +-------------+     +------+------+

      b) Al contrario, mas alto que ancho, entonces division HORIZONTAL.

            +-------+           +-------+
            |       |           | Src'  |
            |       |           |       |
            |  Src  |   ==>     +-------+
            |       |           | New   |
            |       |           |       |
            +-------+           +-------+
    */
    const isVertical = this.widthPx >= this.heightPx;
    console.error(`=========> ${isVertical ? "VERTICAL" : "HORIZONTAL"} DIVISION OF VP[${this.name}]`);
    const newVP = this.owner.subdivision4Viewport(this, isVertical);
    if (!newVP) {
      console.error("\tERROR: Can't subdivide this!!!.");
    } else {
      console.log(`\tAdded new viewport "${newVP.name}".`);
      newVP.setActiveViewport();
    }
  }

  private processMouseDoubleClick4TitleVP(event: MouseEvent): void {
    event.stopPropagation();
    console.log("DobleClick sobre el TITLE del vp '" + this.name + "'.");
    // Al hacer doble click hacemos un fit sobre lo que haya en escena, concretamente sobre su AABB.
    this.autoZoom2RootAABB();
  }

  /**
   * Ejecuta un zoom automatico sobre la escena principal en curso, concretamente sobre la AABB de la parte que cuelga
   * de la capa principal aka "root". Ademas es un autocentrado que se intenta adaptar al eje mas idoneo.
   */
  public autoZoom2RootAABB(): void {

    if (this.controls) {
      const cam = this.controls.getCamera();
      // No vale toda la escena, sino SOLO la parte que cuelga de root, para evitar ejes y demas en otro nivel.
      const rootGroup = this.owner.owner.getSceneManager().rootLayer;
      let bbox = new THREE.Box3().setFromObject(rootGroup);
      if (bbox.isEmpty()) {
        bbox = new THREE.Box3(
          new THREE.Vector3(-100, -100, 0),
          new THREE.Vector3(100, 100, 0),
        )
      }
      // En la ampliacion dejamos limites laterales.
      const limits: Partial<FitToOptions> = {paddingLeft:1, paddingRight: 1, paddingBottom: 1, paddingTop: 1};

      if ((cam as THREE.OrthographicCamera).isOrthographicCamera === true) {
        const ortho = cam as THREE.OrthographicCamera;
        // Para evitar problemas, sacamos las dimensiones reales de la subEscena y las usamos para ajustar los limites
        // laterales de la camara ortografica, en vez de tocar el zoom que da problemas de saltos y zarandajas...
        const [size, _] = this.controls.getDimensions4FitToBox(bbox, false, limits) as [THREE.Vector3, THREE.Vector3];

        ortho.updateProjectionMatrix();
        // Aplico reajuste que deja la camara en estado coherente, de forma que no habra salto de tamaños.
        this.controls.fitToBox(bbox, false, limits);
        // Finalmente necesito ajustar el aspect ratio al del vp propietario, que podria no ser el activo, pero eso se
        // hara fuera de aqui por parte del manager de vp's mediante una llamada a updateViewport4Div().
      } else {
        // La camara de perspectiva es mas simple, pero a veces no se aprecia bien el borde...
        this.controls.fitToBox(bbox, false, limits);
      }
    }
  }

  /**
   * Construye los elementos HTML usados como parte ventanistica en el VP.
   * Aqui se crean titulo, pulsador de nuevo VP y pulsador de cerrar VP.
   */
  public createElementsHTML(): void {
    if (this.titleHTML != null) {
      console.log("ERROR: Parte HTML previamente creada???.");
      return;
    }

    let msg = "No camera";
    if (this.camera) {
      if ((this.camera as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
        msg = "3D";
      } else {
        if ((this.camera as THREE.OrthographicCamera).isOrthographicCamera === true) {
          msg = "Ortho";
        } else {
          msg = "ERROR";
        }
      }
    }

    // Construyo el div en que meteremos el titulo.
    this.titleHTML = document.createElement('div');
    this.titleHTML.id = "title";
    this.setTitle('VP[' + msg + ']: "' + this.name + '"');
    this.titleHTML.style.top = '5px';
    this.titleHTML.style.left = '5px';
    GraphicViewport.applyStyles2ItemHTML(this.titleHTML);
    // Intento de que no nos robe los eventos el orbitControl asociado a this.div.
    // Con +100 no funciona, pero si pongo -100 se invisibiliza.
    // this.titleDiv.style.zIndex = "-100";
    this.elemHTML.appendChild(this.titleHTML);

    // Aqui va el nuevo elemento HTML para mostrar informacion.
    this.infoHTML = document.createElement('div');
    if (this.infoHTML) {
      this.infoHTML.id = "info";
      this.infoHTML.style.top = '5px';
      GraphicViewport.applyStyles2ItemHTML(this.infoHTML);

      this.infoHTML.style.color = 'red';
      this.infoHTML.style.backgroundColor = 'rgba(0, 0, 0, 0.0)';
      this.infoHTML.style.borderBlockStyle = 'rounded';

      this.infoHTML.style.borderWidth = "2px";
      this.infoHTML.style.borderStyle = "solid";
      this.infoHTML.style.borderColor = "red";
      this.infoHTML.style.borderRadius = "5px";

      // Con esto se comporta como tabla y admite el centrado vertical.
      this.infoHTML.style.display = "flex";
      // Esto es lo que lo oculta inicialmente.
      this.infoHTML.style.visibility = "hidden";
      // Para seguir usando el cursor habitual.
      this.infoHTML.style.cursor = "default";

      // Centrado vertical del texto que aparezca.
      this.infoHTML.style.verticalAlign = "middle";
      // Esto nos pone el texto pegado a la izquierda.
      this.infoHTML.style.justifyContent = "left";

      this.elemHTML.appendChild(this.infoHTML);
    }

    // Aqui van los div para cerrar y añadir. Queremos posicionarlos respecto al margen derecho del div padre.
    this.closeHTML = document.createElement('div');
    this.closeHTML.id = "close";
    this.closeHTML.textContent = "X";
    this.closeHTML.style.top = '5px';
    this.closeHTML.style.right = '5px';
    GraphicViewport.applyStyles2ItemHTML(this.closeHTML);
    this.elemHTML.appendChild(this.closeHTML);

    this.newHTML = document.createElement('div');
    this.newHTML.id = "new";
    this.newHTML.textContent = "+";
    this.newHTML.style.top = '5px';
    this.newHTML.style.right = '37px';
    GraphicViewport.applyStyles2ItemHTML(this.newHTML);
    this.elemHTML.appendChild(this.newHTML);

    // Todo esta inicialmente desactivado, hasta que alguien desde fuera diga lo contrario.
    this.graphicDeactivation();

    // Conectamos los callbacks para las pulsaciones.
    this.subscribeMouseEvents();
  }
  
  /** Conectamos los callbacks para las pulsaciones. */
  public subscribeMouseEvents() {
    this.closeHTML?.addEventListener(GraphicViewport._eventClick, this.lambdaMouseClick4CloseVP);
    this.newHTML?.addEventListener(GraphicViewport._eventClick, this.lambdaMouseClick4NewVP);
    this.titleHTML?.addEventListener(GraphicViewport._eventDblClick, this.lambdaMouseDoubleClick4TitleVP);
  }
  
  /** Suprime los callbacks para las pulsaciones. */
  public unSubscribeMouseEvents() {
    this.closeHTML?.removeEventListener(GraphicViewport._eventClick, this.lambdaMouseClick4CloseVP);
    this.newHTML?.removeEventListener(GraphicViewport._eventClick, this.lambdaMouseClick4NewVP);
    this.titleHTML?.removeEventListener(GraphicViewport._eventDblClick, this.lambdaMouseDoubleClick4TitleVP);
  }

  /**
   * Efectua el resto de la configuracion necesaria para el viewport dado, incorporandole un DIV interno y el gizmo en las
   * coordenadas dadas con esa dimension de cuadro, pero con esas coordenadas tomadas como un offset respecto a la esquina
   * SUPERIOR DERECHA (ESD) del vp. Si no se dan valores el vp estara completamente pegado a la ESD del vp:
   * 
   *        +----------------+---+
   *        |                |   |
   *        |                +---+
   *        |                    |
   *
   * ATENCION: Necesita una camara previamente establecida en el VP para establecer su coordinacion con el gizmo.
   * Ojito: Es aqui donde se crea el div asociado al vp, y le podemos dar o no un callback para cuando se hace dobleClick
   * en el.
   * Opcionalmente le podemos dar un div totalmente creado desde el exterior, con dimensiones correctas, padre y su puta
   * madre, pero todo ello completamente correcto.
   *
   * @param {HTMLDivElement} mainDiv
   * @param {number} dimGyzmoXY
   * @param {number} [offsetGyzmoX=-1]
   * @param {number} [offsetGyzmoY=-1]
   * @param {HTMLDivElement} [parentDiv]
   * @param {boolean} [setGizmoCallback=true]
   * @param {HTMLDivElement} [externCreatedDiv]
   * @returns {void}
   * @memberof GraphicViewport
   */
  public configure(mainDiv: HTMLDivElement,
                   dimGyzmoXY: number, offsetGyzmoX: number = -1, offsetGyzmoY: number = -1,
                   parentDiv?: HTMLDivElement, setGizmoCallback: boolean = true,
                   externCreatedDiv?: HTMLDivElement): void {

    if (!this.camera) {
      console.error(`ERROR: El viewport "${this.name}" NO tiene asignada camara.`);
      return;
    }

    if (this.elemHTML) {
      this.removeIntroOutroCallbacks2Div(this.elemHTML);
    }

    if (!parentDiv) {
      parentDiv = mainDiv;
    }

    let [xVP, yVP, wVP, hVP] = this.getXYWH4ViewportInHTMLDivElement(mainDiv);

    if (!externCreatedDiv) {
      // [1] Creamos el subcontainer del canvas principal.
      const divTmp = document.createElement("div");
      parentDiv.appendChild(divTmp);
      
      // Esos pixels absolutos (sobre GPO) dados por [xVP, yVP, wVP, hVP] los relativizamos respecto al padre local.
      const [left100, top100, width100, height100] = GraphicViewportManager.getPercentages(xVP, yVP, wVP, hVP, parentDiv);

      // Damos los valores en pixels, ya que los 01 los tenemos y los aplicamos a las dimensiones totales del canvas.
      this.leftPx = xVP;
      this.topPx = yVP;
      this.widthPx = wVP;
      this.heightPx = hVP;

      divTmp.style.left = left100 + "%";
      divTmp.style.top = top100 + "%";
      divTmp.style.width = width100 + "%";
      divTmp.style.height = height100 + "%";
      divTmp.style.position = "absolute";

      // Al div le damos un borde.
      divTmp.style.borderWidth = "2px";
      divTmp.style.borderStyle = "solid";
      divTmp.style.borderColor = GraphicViewport._inactiveBorderColor;

      // Damos un container con sus callbacks...
      this.elemHTML = divTmp;
    } else {
      // Conectamos el div ya perfectamente creado.
      this.elemHTML = externCreatedDiv;
      this.setParams01Px(mainDiv);
      [xVP, yVP, wVP, hVP] = this.getXYWH4ViewportInHTMLDivElement(mainDiv);
    }

    this.addIntroOutroCallbacks2Div(this.elemHTML);

    // Si no hubiera gizmo lo creamos.
    if (this.gizmo === null) {
      this.gizmo = new XYZmo_RCOH8(this, true);

      // No queremos callback para el gizmo.
      if (!setGizmoCallback) {
        if (this.gizmo.setNonClickableAxes()) {
          setGizmoCallback = true;
        } else {
          window.alert("Que mi madre es esto???");
          debugger;
        }
      }
    }

    // Si no se dan coordenadas para el gizmo las ponemos nosotros separandolo de la esquina superior derecha segun las
    // dimensiones dadas. Ojo que son offsets a descontar de la ESD.
    if (-1 === offsetGyzmoX) {
      offsetGyzmoX = wVP - dimGyzmoXY;
    }
    if (-1 === offsetGyzmoY) {
      offsetGyzmoY = 40;
    }
    console.log("\tGizmo en (" + offsetGyzmoX + ", " + offsetGyzmoY + ")");
    this.gizmo.init(offsetGyzmoX, offsetGyzmoY, dimGyzmoXY, this.camera, this.elemHTML);
    if (setGizmoCallback) {
      this.gizmo.subscribeMouseEvents();
    }

    // Aqui se crean titulo, pulsador de nuevo VP y pulsador de cerrar VP.
    if (this.titleHTML === null) {
      this.createElementsHTML();
    }
  } // public configureViewport()

  /**
   * Tenemos un nuevo div (dstDiv) al que debemos conectar toda la infraestructura del VP caller, destruyendo el div
   * original (srcDiv) y creando todo aquello que sea necesario o reconectando lo anterior.
   * Ademas generalmente ese dstDiv suele ser el padre del srcDiv.
   * @param dstDiv 
   * @returns 
   */
  public readjust2NewDiv(dstDiv: HTMLDivElement): void {

    // Inicialmente partimos de estos datos en nuestro VP.
    let srcCam = this.camera as THREE.PerspectiveCamera | THREE.OrthographicCamera;

    // Lo primero es quitarle las keystrokes.
    this.removeKeystrokesCallbacks();

    let srcDiv = this.elemHTML;
    // let srcRect = srcDiv.getBoundingClientRect();
    // let dstRect = dstDiv.getBoundingClientRect();

    // if (true) {
    //   debugger;
    //   console.error("Antes de todo...");
    //   this.gizmo?.seeExternCamera(false);
    //   seeCam(srcCam, "SRC CAM ===> ");
    //   seeDiv(srcDiv, "SRC DIV ===> ");
    //   seeDiv(dstDiv, "DST DIV ===> ");
    //   seeDiv(srcDiv.parentElement as HTMLDivElement, "SRC DIV PARENT ===> ");
    //   seeDiv(dstDiv.parentElement as HTMLDivElement, "DST DIV PARENT ===> ");
    //   if (srcDiv.parentElement === dstDiv) {
    //     console.log("The new DST div is the father of the current SRC div.");
    //   } else {
    //     console.log("The new DST div and the current SRC div are not related???.");
    //   }
    // }

    // Cosas que tiene el VP y que hay que tocar o regenerar.
    // [1] this.elemHTML: Sera reasignado al nuevo div y el original destruido. Logicamente habra que regenerar todos los
    // valores auxiliares implicados, como this.ltwh01, this.ltwhPx,...
    // [2] this.camera: Solo habra que cambiar su aspect y lo que sea necesario para ajustarla al nuevo div, sin tener
    // que destruirla, en teoria. Ademas tiene vinculos con el this.controls y con el this.gizmo que habra que reconectar.
    // [3] this.controls: Como esta basado en el div original habra que desconectarlo y destruirlo, creando uno nuevo con
    // el nuevo div y la camara modificada, y habria que darle los mismos parametros que al control original que luego
    // destruiremos.
    // [4] this.gizmo: La camara externa no varia, pero el div nuevo obliga a que hay que reconectarlo con el, quitando
    // y poniendo callbacks.
    // [5] this.titleHTML, this.closeHTML, this.newHTML y this.infoHTML: Se desconectan del div original y se conectan
    // al div destino, creo que sin mas.

    let srcCntrls = this.controls as CameraControls;
    srcCntrls.enabled = false;
    // Del control original sacamos el target. ESTA ERA LA PUTA CLAVE!!!.
    const prevTarget = new THREE.Vector3();
    srcCntrls.getTarget(prevTarget);

    // [1] this.elemHTML
    // Evitamos que nos robe eventos en el futuro.
    this.removeIntroOutroCallbacks2Div(srcDiv);
    // Y se los damos al nuevo.
    this.addIntroOutroCallbacks2Div(dstDiv);

    this.elemHTML = dstDiv;
    this.setParams01Px(this._ownerMngr.owner.container);

    const W = this._ownerMngr.owner.width;
    const H = this._ownerMngr.owner.height;
    const aspect = (W * this.width01) / (H * this.height01);

    // [2] Camara que habra que ajustar al nuevo div.
    if ((srcCam as THREE.OrthographicCamera).isOrthographicCamera === true) {
      const orthoK = 2000;
      const frustumSize = orthoK * this.height01;
      const orthoCam = srcCam as THREE.OrthographicCamera;
      orthoCam.left = -(frustumSize * aspect) / 2;
      orthoCam.right = (frustumSize * aspect) / 2;
      orthoCam.top = frustumSize / 2;
      orthoCam.bottom = -frustumSize / 2;
      orthoCam.updateProjectionMatrix();
    } else {
      // La de perspectiva se ajusta sola.
      const perspCam = srcCam as THREE.PerspectiveCamera;
      perspCam.aspect = aspect;
    }

    // console.error("Tras ajuste de camara (I)...");
    // this.gizmo?.seeExternCamera(false);

    // [3] Nuevo control copiando lo que se pueda del anterior, que se destruira.

    // console.error("Antes de asignar al control...");
    // this.gizmo?.seeExternCamera(false);
    // seeCam(srcCam);

    // Por si las moscas desconecto el control original. Parece que no tiene nada que ver.
    srcCntrls.dispose();

    // Simplemente esta conexion del nuevo control con la camara existente hace que los parametros internos de la misma
    // varien y salga el error. Sera por concomitancias con el control anterior que la poseia?. Parece que no.
    // Optamos por salvaguardar antes algunas variables de la camara y reasignarlas despues.
    // const prevDir = new THREE.Vector3();
    // const prevPos = new THREE.Vector3();
    // srcCam.getWorldDirection(prevDir);
    // srcCam.getWorldPosition(prevPos);
    // srcCam.zoom = 1.0;
    // srcCam.updateProjectionMatrix();

    const dstCntrls = new CameraControls(srcCam, dstDiv);
    // dstCntrls.setLookAt(prevPos.x, prevPos.y, prevPos.z, prevDir.x, prevDir.y, prevDir.z, false);
    dstCntrls.setTarget(prevTarget.x, prevTarget.y, prevTarget.z, false);
    dstCntrls.update(this.owner.owner._clock.getDelta());

    // console.error("Tras asignar al control...");
    // this.gizmo?.seeExternCamera(false);
    // seeCam(srcCam);

    // Copiar algunos valores previos.
    setMouseControls(dstCntrls as CameraControls, false);
    dstCntrls.update(this.owner.owner._clock.getDelta());

    // [4] Gyzmo.
    if (this.gizmo) {
      this.gizmo.unSubscribeMouseEvents();
      this.gizmo.setNewParentContainer(dstDiv);
      this.gizmo.subscribeMouseEvents();
      this.gizmo.handleResize(this.widthPx, this.heightPx);
    }

    // [5] Los div's auxiliares.
    // Por si acaso los desuscribo, aunque creo que es innecesario.
    this.unSubscribeMouseEvents();
    // Y los conecto al nuevo padre.
    dstDiv.appendChild(this.titleHTML as HTMLDivElement);
    dstDiv.appendChild(this.infoHTML as HTMLDivElement);
    dstDiv.appendChild(this.newHTML as HTMLDivElement);
    dstDiv.appendChild(this.closeHTML as HTMLDivElement);
    // Y reconecto.
    this.subscribeMouseEvents();

    // Reasignaciones.
    this.controls = dstCntrls;
    dstCntrls.enabled = true;
    
    // Finalmente nos cargamos al viejo div y a su viejo control.
    // srcCntrls.dispose();
    srcCntrls = (undefined as unknown) as CameraControls;
    srcDiv.remove();
    srcDiv = (undefined as unknown) as HTMLDivElement;

    // Por si las moscas le pedimos al manager otra actualizacion.
    this._ownerMngr.resizeViewport(this, true);
    this._ownerMngr.resizeViewport(this, false);

    // console.error("Despues de todo...");
    // seeCam(srcCam, "SRC CAM ===> ");
    // this.gizmo?.seeExternCamera(false);

    // console.error("Acabada transformacion...");

    // Y lo ultimo reconstruir los keystrokes.
    this.owner.setKeyboardControls(this);

    this.owner.owner.dispatchViewportEvent(this);

    if (true && 2 > 1) {
      return;
    }

    // let oldControls = this.controls as CameraControls;
    // oldControls.enabled = false;

    // const heightOldDiv = srcDiv.getBoundingClientRect().height;
    // const heightNewDiw = dstDiv.getBoundingClientRect().height;

    // this.removeIntroOutroCallbacks2Div(srcDiv);
    // if (true) {
    //   // Desconectamos los eventos del gizmo.
    //   this.gizmo?.unSubscribeMouseEvents();
    // }
    // this.controls = new CameraControls(oldCamera, newDiv);

    // this.elemHTML = dstDiv;

    // Recalculamos nuevos valores de ajuste.
    // this.setParams01Px(this.owner.owner._container);
    // this.addIntroOutroCallbacks2Div(this.elemHTML);

    // dstDiv.appendChild(this.titleHTML as HTMLDivElement);
    // dstDiv.appendChild(this.infoHTML as HTMLDivElement);
    // dstDiv.appendChild(this.newHTML as HTMLDivElement);
    // dstDiv.appendChild(this.closeHTML as HTMLDivElement);
    // Quizas esto tuviera que ir mas arriba???...
    // oldControls.dispose();
    // oldControls = (undefined as unknown) as CameraControls;
    // srcDiv.remove();
    // srcDiv = (undefined as unknown) as HTMLDivElement;

    const aspectXY = this.widthPx / this.heightPx;
    // const w = this._ownerMngr.owner.width;
    // const h = this._ownerMngr.owner.height;
    // const aspectXY = (w * this.width01) / (h * this.height01);
    if ((srcCam as THREE.PerspectiveCamera).isPerspectiveCamera === true) {
      const perspCam = srcCam as THREE.PerspectiveCamera;
      perspCam.aspect = aspectXY;
      perspCam.updateProjectionMatrix();
    } else {
      debugger;
      const orthoCam = srcCam as THREE.OrthographicCamera;
      // Habria que hacer algo mas... Creo que falta variar los parametros que describen el rectangulo de vision de la
      // ortoCam para que se ajusten al div actual.
      // const frustumSize = 10000.0;
      // orthoCam.left = -0.5 * frustumSize * aspectXY;
      // orthoCam.right = +0.5 * frustumSize * aspectXY;
      // orthoCam.top = 0.5 * frustumSize;
      // orthoCam.bottom = -0.5 * frustumSize;

      // Solo modifico la altura, dejando izquierda y derecha sin cambio.
      // const heightOldDiv = oldDiv.getBoundingClientRect().width;
      // const heightNewDiw = newDiv.getBoundingClientRect().width;
      const heightOldCam = orthoCam.top - orthoCam.bottom;
      // Regla de tres.
      // const heightNewCam = heightNewDiw * heightOldCam / heightOldDiv;

      // orthoCam.left = -0.5 * heightNewCam * aspectXY;
      // orthoCam.right = +0.5 * heightNewCam * aspectXY;
      // orthoCam.top = +0.5 * heightNewCam;
      // orthoCam.bottom = -0.5 * heightNewCam;

      seeCam(orthoCam, "(I)");

      orthoCam.updateProjectionMatrix();
      seeCam(orthoCam, "(II)");
      this.controls = new CameraControls(srcCam, dstDiv);
      seeCam(orthoCam, "(III)");
      // this.controls.update(this.owner.owner._clock.getDelta());
    }

    // Ademas renombramos el vp quitandole un "'" final.
    if (this.name[this.name.length - 1] === "'") {
      this.name = this.name.slice(0, -1);
      if (this.titleHTML?.textContent) {
        this.titleHTML.textContent = this.titleHTML.textContent.slice(0, -1);
      }
    }

    // Quizas esto lo deba hacer siempre???.
    if (true) {
      // Volvemos a suscribir al gizmo con el nuevo div.
      this.gizmo?.setNewParentContainer(this.elemHTML);
      this.gizmo?.subscribeMouseEvents();
      // Esto faltaba!!!.
      // this.gizmo?.handleResize(this.widthPx, this.heightPx);
      // setMouseControls(this.controls, false);
      // this.owner.owner.dispatchViewportEvent(this);
    }

    this.gizmo?.handleResize(this.widthPx, this.heightPx);
    // setMouseControls(this.controls as CameraControls, false);
    this.owner.owner.dispatchViewportEvent(this);

    this.controls?.update(this.owner.owner._clock.getDelta());
  }

  /**
   * Pinta un titulo en el boton azul del titulo.
   * @param newTitle 
   */
  public setTitle(newTitle: string): void {
    if (this.titleHTML) {
      this.titleHTML.textContent = newTitle;
    }
  }

  /**
   * DMC privado para controlar que las visualizaciones no desaparezcan antes de tiempo.
   */
  private numInfoHTMLVisualizations: number = 0;
  /**
   * Envia al area de informacion un mensaje que sera visualizado durante el tiempo en segundos especificado y luego
   * desaparecera. Si el parametro letsDisappear es falso entonces el mensaje no desaparecera.
   * Por defecto desaparece tras 3 segundos.
   * @param newInfo 
   * @param letsDisappear 
   * @param showTimeSecs 
   * @returns 
   */
   public setInfo(newInfo: string, letsDisappear = true, showTimeSecs = 3): void {
    if (this.infoHTML) {
      if (newInfo.length === 0) {
        // Sin texto ocultamos directamente.
        this.infoHTML.style.visibility = "hidden";
        this.numInfoHTMLVisualizations = 0;
        return;
      }

      // Hace que aparezca.
      this.infoHTML.style.visibility = "visible";

      // Primero saco las posiciones de los hermanos inmediatos por izquierda y derecha para asignar las dimensiones...
      if (this.titleHTML) {
        const rect = this.titleHTML.getBoundingClientRect();
        // Ese es el truco para que funcione, usar width y no right.
        const xL = rect.width + 2 * 5;
        this.infoHTML.style.left = xL + "px";
      }

      if (this.newHTML && this.closeHTML) {
        const rect1 = this.newHTML.getBoundingClientRect();
        const rect2 = this.closeHTML.getBoundingClientRect();
        const xR = rect1.width + rect2.width + 3 * 5;
        this.infoHTML.style.right = xR + "px";
      }
      
      this.infoHTML.textContent = newInfo;

      if (letsDisappear) {
        ++this.numInfoHTMLVisualizations;
        // Demos 3 segundos para ocultar el letrerico, si es que todavia existe.
        setTimeout(() => {
          if (this.infoHTML) {
            // Asi controlo que cuando llega un mensaje nuevo el tiempo se mantenga y no desaparezca antes de su tiempo.
            if (this.numInfoHTMLVisualizations === 1) {
            // Cuando solo hay una y el timer se extingue se oculta.
            this.infoHTML.style.visibility = "hidden";
            }
            --this.numInfoHTMLVisualizations;
          }
        }, showTimeSecs * 1000);
      }
    }
  }

  public storeCameraStatus(): void {
    if (this.controls && !this.isStatusSaved) {
      this.controls.saveState();
      this.isStatusSaved = true;
    } 
  }

  /**
   * Dado un div original (div0), que siempre debiera tener dimensiones ENTERAS, sin decimales, creamos una subdivision
   * recubrimiento/particion del mismo en 2 div's (divA + divB) segun el porcentaje dado (tpc, en el intervalo [5, 95])
   * de numeros enteros. Si la division es vertical, devolvemos izquierda + derecha, mientras que si es horizontal
   * devolvemos arriba + abajo. En caso de error devolvemos null.
   * Devolvemos los divs mitades y el % de division finalmente aplicado, posiblemente distinto del dado inicialmente
   * para evitar esos decimales tocaCojones.
   *
   *   +-----+    +-----+ 0%          +-------------+    +-----+-------+
   *   |     |    |divA |             |  div0       | => | divA| divB  |
   *   |     |    |     |             |             |    |     |       |
   *   |div0 | => +-----+ Y%          +-------------+    +-----+-------+
   *   |     |    | divB|                                0%    X%     100%    
   *   |     |    |     |
   *   |     |    |     |
   *   +-----+    +-----+100%

   * 
   * @param srcDiv 
   * @param tpc 
   * @param isVertical 
   */
  public static createDivision4Div(div0: HTMLDivElement, tpc: number, isVertical: boolean = true): [HTMLDivElement, HTMLDivElement, number] | null {
    // El div original ya debe ser de dimensiones enteras.
    if (GraphicViewport.testDiv4NonIntegerDims(div0)) {
      console.error("ERROR: El container div dado NO tiene dimensiones enteras!!!.");
      // Esto es lo que le daba problemas a JaWS & JLuis... Curioso.
      // return null;
    }

    // Solo admitimos % de dimensiones en [5, 95] y ademas el numero lo queremos entero.
    if (!Number.isInteger(tpc)) {
      console.error(`ERROR: El porcentaje dado (${tpc})NO es entero!!!.`);
      return null;
    }

    if (tpc < 5 || 95 < tpc) {
      console.error("ERROR: El porcentaje dado NO esta en el intervalo [5%, 95%]!!!.");
      return null;
    }

    const r0 = div0.getBoundingClientRect();
    const W0 = r0.width;
    const H0 = r0.height;

    const divA = document.createElement("div");
    const divB = document.createElement("div");

    if (isVertical) {
      // Division vertical: Se conserva la altura y el % de division se aplica de forma en que W se reparta segun ese
      // porcentaje en 2 trozos, izquierda (a) y derecha (b) de anchuras enteras que sumen la anchura original.
      let widthA = tpc * W0 / 100;
      if (!Number.isInteger(widthA)) {
        widthA = Math.floor(widthA);
        // Ademas modificamos el tpc para que este de acuerdo con ese redondeo, aproximadamente.
        tpc = 100 * widthA / W0;
      }

      divA.style.left = "0%";
      divA.style.top = "0%";
      divA.style.width = tpc + "%";
      divA.style.height = "100%";
      divA.style.position = "absolute";
  
      divB.style.left = tpc + "%";
      divB.style.top = "0%";
      divB.style.width = (100 - tpc) + "%";
      divB.style.height = "100%";
      divB.style.position = "absolute";
    } else {
      // Division horizontal: Se conserva la anchura y el % de division se aplica de forma en que H se reparta segun ese
      // porcentaje en 2 trozos, arriba (a) y abajo (b) de alturas enteras que sumen la altura original.
      let heightA = tpc * H0 / 100;
      if (!Number.isInteger(heightA)) {
        heightA = Math.floor(heightA);
        tpc = 100 * heightA / H0;
      }

      divA.style.left = "0%";
      divA.style.top = "0%";
      divA.style.width = "100%";
      divA.style.height = tpc + "%";
      divA.style.position = "absolute";
  
      divB.style.left = "0%";
      divB.style.top = tpc + "%";
      divB.style.width = "100%";
      divB.style.height = (100 - tpc) + "%";
      divB.style.position = "absolute";
    }
    
    div0.appendChild(divA);
    div0.appendChild(divB);

    // Comprobacion psicopatica final que se eliminara: Veamos que las dimensiones y posiciones son correctas.
    if (true) {
      const rA = divA.getBoundingClientRect();
      const WA = rA.width;
      const HA = rA.height;
      const rB = divB.getBoundingClientRect();
      const WB = rB.width;
      const HB = rB.height;

      const L0 = r0.left;
      const T0 = r0.top;
      const R0 = r0.right;
      const B0 = r0.bottom;
  
      if (isVertical) {
        // La suma de las anchuras es la original. Y las alturas son comunes.
        if (WA + WB !== W0) {
          debugger;
        } else {
          if (HA !== H0 || HB !== H0) {
            debugger;
          } else {
            // Pero ademas los limites coinciden.
            if (rA.left === L0 && rB.right === R0) {
              ;
            } else {
              debugger;
            }
            if (rA.top === T0 && rB.top === T0) {
              ;
            } else {
              debugger;
            }
            if (rA.bottom === B0 && rB.bottom === B0) {
              ;
            } else {
              debugger;
            }
          }
        }
      } else {
        // La suma de las alturas es la original. Y las anchuras coinciden.
        if (HA + HB !== H0) {
          // debugger;
        } else {
          if (WA !== W0 || WB !== W0) {
            debugger;
          } else {
            // Y los limites coinciden.
            if (rA.left === L0 && rB.left === L0) {
              ;
            } else {
              debugger;
            }
            if (rA.right === R0 && rB.right === R0) {
              ;
            } else {
              debugger;
            }
            if (rA.top === T0 && rB.bottom === B0) {
              ;
            } else {
              debugger;
            }
          }
        }
      }
    }

    return [divA, divB, tpc];
  }

} // class GraphicViewport

/**
 * Como siempre las cosas no son tan faciles como pone la documentacion. Parece que una camara ortografica no se puede
 * clonar simplemente llamando a la camera.clone(), ni menos serializando con camera.toJSON(), asi que hay que hacerlo
 * a las duras: Devolvemos una DEEP COPY de la camara dada como parametro.
 * @param srcCam 
 */
export function duplicateOrthographicCamera(srcCam: THREE.OrthographicCamera): THREE.OrthographicCamera {
  // Parece que se debe hacer esto.
  srcCam.matrixAutoUpdate = false;
  const left = srcCam.left;
  const right = srcCam.right;
  const top = srcCam.top;
  const bottom = srcCam.bottom;
  const near = srcCam.near;
  const far = srcCam.far;
  // srcCam.updateMatrixWorld(true);
  console.log("Src cam matrix: ", srcCam.matrixWorld.elements.toString());

  const dstCam = new THREE.OrthographicCamera(left, right, top, bottom, near, far);
  // Segun el guru WestLangley es tan facil como:
  // dstCam.copy(srcCam, true);
  // PERO eso no funciona!!!.
  // https://stackoverflow.com/questions/49201438/threejs-apply-properties-from-one-camera-to-another-camera
  
  dstCam.updateMatrixWorld(true);
  console.log("Dst cam matrix: ", dstCam.matrixWorld.elements.toString());

  let mtrx = new THREE.Matrix4();
  mtrx.copy(srcCam.matrixWorld);
  dstCam.matrixWorld.copy(mtrx);
  // Esto jode los resultados.
  // dstCam.updateMatrixWorld(true);

  console.log("Dst cam matrix: ", dstCam.matrixWorld.elements.toString());

  // Parece que es necesario asignar tambien los valores de posicion, quaternion y escala, que los podria obtener de la
  // descomposicion de la matriz de mundo, pero eso provoca errores numericos que se acumularian...
  if (false) {
    // Esta es la solucion canonica de descomposicion...
    let positionV = new THREE.Vector3();
    let quat = new THREE.Quaternion();
    let scaleV = new THREE.Vector3();

    dstCam.matrixWorld.decompose(positionV, quat, scaleV);
    dstCam.position.copy(positionV);
    dstCam.quaternion.copy(quat);
    dstCam.scale.copy(scaleV);
  } else {
    // ...pero optamos por la copia directa.
    dstCam.position.x = srcCam.position.x;
    dstCam.position.y = srcCam.position.y;
    dstCam.position.z = srcCam.position.z;
    dstCam.scale.x = srcCam.scale.x;
    dstCam.scale.y = srcCam.scale.y;
    dstCam.scale.z = srcCam.scale.z;
    dstCam.quaternion.x = srcCam.quaternion.x;
    dstCam.quaternion.y = srcCam.quaternion.y;
    dstCam.quaternion.z = srcCam.quaternion.z;
    dstCam.quaternion.w = srcCam.quaternion.w;
  }

  // Tambien copio esto a mano y ademas llamo a ese update siempre necesario.
  dstCam.zoom = srcCam.zoom;
  dstCam.updateProjectionMatrix();

  // Tras haber copiado antes matrixWorld (obligatoria), NO voy a copiar las otras matrices, matrixWorldInverse,
  // projectionMatrix y projectionMatrixInverse, ya actualizadas en el anterior updateProjectionMatrix().
  // mtrx.copy(srcCam.matrixWorldInverse);
  // dstCam.matrixWorld.copy(mtrx);
  // mtrx.copy(srcCam.projectionMatrix);
  // dstCam.matrixWorld.copy(mtrx);
  // mtrx.copy(srcCam.projectionMatrixInverse);
  // dstCam.matrixWorld.copy(mtrx);

  // const vDir = new THREE.Vector3();
  // const dir = srcCam.getWorldDirection(vDir);

  return dstCam;
}

export function areOkDimms01(ltwh01: [number, number, number, number]): boolean {
  const [left01, top01, width01, height01] = ltwh01;
  if (!inInterval01(left01)) {
      return false;
  }
  if (!inInterval01(top01)) {
      return false;
  }
  // Tampoco se puede salir.
  if (!inInterval01(left01 + width01)) {
      return false;
  }
  if (!inInterval01(top01 + height01)) {
      return false;
  }
  return true;
}

function inInterval01(v: number): boolean {
  return (v < 0 || 1 < v) ? false : true;
}

function clamp01(v: number): number {
  if (v < 0.0) {
      v = 0.0;
  } else {
      if (v > 1.0) {
          v = 1.0;
      }
  }
  return v;
}

/**
 * Visualizador de los datos de un div.
 * @param div 
 */
function seeDiv(div: HTMLDivElement, msg: string = ""): void {
  const r = div.getBoundingClientRect();
  msg += ` Name:"${div.id}"\n`;
  msg += `\t L:${r.left}   T:${r.top}   R:${r.right}   B:${r.bottom}\n`
  msg += `\t W*H:${r.width} * ${r.height}   X/Y:${r.width / r.height}\n`;
  if (div.parentElement) {
    msg += `\t Parent: ${div.parentElement.id}`
  }
  console.log(msg);
}

function seeCam(cam: THREE.PerspectiveCamera | THREE.OrthographicCamera, msg: string = ""): void {
  const isOrtho = ((cam as THREE.OrthographicCamera).isOrthographicCamera === true);
  msg += ` [${isOrtho ? "ORTHO" : "PERSP"}] Name:"${cam.name}"\n`;
  msg += `\t N/F:[${cam.near}, ${cam.far}]\n`;
  if (isOrtho) {
    const oc = cam as THREE.OrthographicCamera;
    msg += `\t L:${oc.left}   R:${oc.right}   T:${oc.top}   B:${oc.bottom}\n`;
    const w = oc.right - oc.left;
    const h = oc.top - oc.bottom;
    msg += `\t W*H:${w} * ${h}   X/Y:${w / h}\n`;
  } else {
    const pc = cam as THREE.PerspectiveCamera;
    msg += `\t Fov:${pc.fov}   X/Y:${pc.aspect}\n`;
  }
  msg += `\t Zoom:${cam.zoom}`;
  console.log(msg);
}