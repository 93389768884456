import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { axisAngleToEulerAngles, normalizeAngle } from "../math/angles";
import { isEqual, vector3Equals } from "../math/epsilon";
import { getNormalOfPlane } from "../math/plane";
import { addIpoint, crossProductIpoint, dotProductIpoint, normalizeIpoint } from "../math/point";
import { rotatePoint } from "../math/rotate";
import { scalePoint } from "../math/scale";
import { IPoint } from "../math/types";

export function isBufferGeom(geometry: THREE.BufferGeometry | THREE.Geometry): geometry is THREE.BufferGeometry {
  if (geometry instanceof THREE.BufferGeometry) {
    console.assert(
      geometry.attributes !== undefined &&
      geometry.getAttribute("position") !== undefined,
      "No es un bufferArray"
    );
    return true;
  } else {
    return false;
  }
}

export function updateObjBboxBSphere(threeObj: THREE.Object3D): void {
  const geom = (threeObj as any).geometry;
  if (geom) {
    if (geom.getAttribute) geom.getAttribute("position").needsUpdate = true;
    geom.computeBoundingBox();
    geom.computeBoundingSphere();
  }
}

export function adjustDrawRange(threeObj: THREE.Line | THREE.Points | THREE.Mesh, count?: number, start?: number): void {
  if (
    threeObj.geometry &&
    (threeObj.geometry as THREE.BufferGeometry).drawRange
  ) {
    let max = count;
    if (max === undefined) {
      const index = (threeObj.geometry as THREE.BufferGeometry).getIndex();
      if (index) {
        max = index.count;
      } else {
        max =
          (threeObj.geometry as THREE.BufferGeometry).getAttribute("position")
            .array.length / 3;
      }
    }
    if (start === undefined) {
      start = 0;
    }
    (threeObj.geometry as THREE.BufferGeometry).setDrawRange(start, max);
  }
}

export function setPosBuffer(threeObj: Line2 | THREE.Line | THREE.Points | THREE.Mesh, buffer: Float32Array): void {
  if (threeObj.type === "Line2") {
    const geometry = threeObj.geometry as LineGeometry;
    geometry.setPositions(buffer);
    (threeObj as Line2).computeLineDistances();
    updateObjBboxBSphere(threeObj);
  } else {
    const geometry = threeObj.geometry as THREE.BufferGeometry;
    geometry.setAttribute("position", new THREE.Float32BufferAttribute(buffer, 3));
    // const position = geometry.getAttribute("position") as THREE.BufferAttribute;
    // position.array = buffer;
    // position.count = buffer.length / 3;
    adjustDrawRange(threeObj);
    updateObjBboxBSphere(threeObj);
  }
}
export function setPosRotEsc(obj: THREE.Object3D, basePoint: IPoint, offset: IPoint, rotation: IPoint, scale: IPoint) {
  let aux = addIpoint(basePoint, offset);
  aux = rotatePoint(aux, rotation.x, rotation.y, rotation.z, basePoint);
  aux = scalePoint(aux, scale.x, scale.y, scale.z, basePoint);
  obj.position.set(aux.x, aux.y, aux.z)
  obj.rotation.set(rotation.x, rotation.y, rotation.z);
  obj.scale.set(scale.x, scale.y, scale.z);
  obj.updateMatrixWorld();
}

export function getRotationSystemFrom3p(p1: IPoint, p2: IPoint, p3: IPoint): IPoint {
  const plane = { p1, p2, p3 };
  const n = getNormalOfPlane(plane);
  const normal = normalizeIpoint(n);
  const NplaneXY = { x: 0, y: 0, z: 1 };

  // Points in XY plane
  if (vector3Equals(normal, { x: 0, y: 0, z: -1 })) return { x: 0, y: 0, z: 0 };
  if (vector3Equals(normal, { x: 0, y: 0, z: 1 })) return { x: 0, y: 0, z: 0 };

  const rotateAxis = normalizeIpoint(crossProductIpoint(NplaneXY, normal));
  const angle = Math.acos(dotProductIpoint(normalizeIpoint(NplaneXY), normalizeIpoint(normal)));

  if (vector3Equals(rotateAxis, { x: -1, y: 0, z: 0 })) return axisAngleToEulerAngles(angle, { x: 1, y: 0, z: 0 });

  if (vector3Equals(rotateAxis, { x: 0, y: 1, z: 0 }) && isEqual(angle, Math.PI * 0.5)) return { x: Math.PI * 0.5, y: Math.PI * 0.5, z: 0 };
  if (vector3Equals(rotateAxis, { x: 0, y: -1, z: 0 }) && isEqual(angle, Math.PI * 0.5)) return { x: Math.PI * 0.5, y: Math.PI * 0.5, z: 0 };

  if (normal.z < 0) return axisAngleToEulerAngles(normalizeAngle(angle + Math.PI), rotateAxis);
  return axisAngleToEulerAngles(angle, rotateAxis);
}