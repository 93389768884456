import { getEcoreFloat, getEcoreInt } from './helper-ecore';
import { CodeAnalysis, DirSSEHypothesis, HYPOTHESIS_NAMES, HYPOTHESIS_TYPES, IAnalysisProject as IAnalysisProjectEcore, IAnalysisVersion as IAnalysisVersionEcore, IHypothesis as IHypothesisEcore, IHypothesisRef as IHypothesisRefEcore, IHypothesisSet as IHypothesisSetEcore, ILoad as ILoadEcore, SSEDirsEcore, SSERecord, WindHypothesis, WindTypes } from "modules/struc/models/ecore/analysis";
import { getEcoreStrucElemExporter } from "./structural/ecore-builder";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { EcoreLoadElem } from "./load";
import { Hypothesis, hypothesisTypes, isSSEHypothesis, isWindHypothesis, IwindHypothesis, loadHypothesisList, SSEDirs, SSHypothesis, windSubTypes } from "lib/models-struc/hypothesis/hypothesis";
import { analysisManager } from "lib/models-struc/analysis/analysismodel-manager";
import { baseUriModel } from "./mesh-exporter";
import { getEcoreCrossSectionShapeSet } from "./cross-section-shapes";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { LoadStructuralData } from "lib/models/structural/load";

import { ErrorCentinel, i_ECS } from "lib/helpers/error-centinel";

export class EcoreAnalysisExporter {

  private crossSectionShapesIds: string[];

  exportAnalysisProject(): IAnalysisProjectEcore {
    // [1] Punto de arranque de la exportacion del analisis, que empieza de la forma:
    // "eClass": "http://www.example.org/buildingmodel#//analysis/AnalysisProject",
    // "analysisversion": [
    // Y a continuacion iria el array con todas las hipotesis, con sus cargas, etc...

    const { crossSectionShapesIds } = getEcoreCrossSectionShapeSet();
    this.crossSectionShapesIds = crossSectionShapesIds;

    return {
      eClass: baseUriModel + "analysis/AnalysisProject",
      analysisversion: this.exportAnalysisVersionToEcore(),
    };
  }

  private exportAnalysisVersionToEcore(): IAnalysisVersionEcore[] {
    // [2] El array con el contenido de "analysisversion", que es [{todo el resto del JSON}] formado por esas 4 componentes.
    return [{
      eClass: baseUriModel + "analysis/AnalysisVersion",
      // [2.1]
      analysis: [this.exportCodeAnalisisToEcore()],
      // [2.2]
      hypothesisset: this.exportHypothesisSetToEcore(),
      meshproject: {
        eClass: baseUriModel + "mesh/MeshProject",
        $ref: "mesh_project.json#/"
      },
    }];
  }

  private exportCodeAnalisisToEcore(): CodeAnalysis {
    // [2.1] "analysis":
    const hypotesis: IHypothesisRefEcore[] = [];
    const solveHypo = analysisManager.analysis.solveHypothesis;

    for (let i = 0; i < solveHypo.length; i++) {
      const hypo: IHypothesisRefEcore = {
        eClass: baseUriModel + "analysis/Hypothesis",
        $ref: "//@analysisversion.0/@hypothesisset/@hypothesis." + i,
      };
      hypotesis.push(hypo);
    }
    return {
      eClass: baseUriModel + "analysis/CodeAnalysis",
      hypothesis: hypotesis,
    };

    // const sseHypothesis = SSEhypothesisManager.getActiveSSEHypothesis();
    // const windHypothesis = windhypothesisManager.getWindHypothesis();
    // const hypothesis = hypothesisManager.getAllHypothesis();
    // const allHypo = [...hypothesis, ...sseHypothesis, ...windHypothesis];

    // for (const hypo of solveHypo) {
    //   const indexHypo = allHypo.findIndex(h => h === hypo);
    //   if (indexHypo > -1) {
    //     const hypo: IHypothesisRefEcore = {
    //       eClass: baseUriModel + "analysis/Hypothesis",
    //       $ref: "//@analysisversion.0/@hypothesisset/@hypothesis." + indexHypo,
    //     };
    //     hypotesis.push(hypo);
    //   }
    // }
    // return {
    //   eClass: baseUriModel + "analysis/CodeAnalysis",
    //   hypothesis: hypotesis,
    // };
  }

  private exportHypothesisSetToEcore(): IHypothesisSetEcore {
    // [2.2] "hypothesisset":
    const result: IHypothesisEcore[] = [];
    const solveHypo = analysisManager.analysis.solveHypothesis;
    for (const hypothesis of solveHypo) {
      if (isWindHypothesis(hypothesis)) {
        const hypothesisEcore = this.getWindHypothesisEcore(hypothesis);
        result.push(hypothesisEcore);
      } else if (isSSEHypothesis(hypothesis)) {
        const hypothesisEcore = this.getSSEHypothesisEcore(hypothesis);
        result.push(hypothesisEcore);
      } else {
        // Aqui van las otras hipotesis, de las que exprimimos sus loads.
        const loads = hypothesisManager.getLoadsByHypotesis(hypothesis);
        const hypothesisEcore = this.getHypothesisEcore(hypothesis, loads);
        result.push(hypothesisEcore);
      }
    }
    return {
      eClass: baseUriModel + "analysis/HypothesisSet",
      hypothesis: result,
    };
  }

  private getHypothesisEcore(hypo: Hypothesis, loads: LoadStructuralData[]): IHypothesisEcore {    
    if (true) {
      // Para la generacion de la tabla con las cargas por hipotesis necesitamos saber la hipotesis en curso.
      // Nos disponemos a crear un fichero HTML con una tabla  informe de datos hacia el exterior con la informacion de
      // las cargas exportadas. Como siempre usaremos el singleton centinela de errores.
      ErrorCentinel.printBox(`Procesing hypothesis "${hypo.name}"`, "color: white", "\t\t");
      i_ECS.magicBag.set("name4Hypothesis", hypo.name);
      // Ademas reseteamos el contador de las cargas dentro de la hipotesis en curso.
      i_ECS.resetCounter("counter4Loads4Hypo");
    }

    const hypothesis: IHypothesisEcore = {
      eClass: baseUriModel + "analysis/Hypothesis",
      loads: this.exportLoadsToEcore(loads),
    };
    const name = hypo.name;
    const fav = getEcoreFloat(hypo.favourableLoadFactor, 0);
    const un_fav = getEcoreFloat(hypo.unfavourableLoadFactor, 0);
    const mass_ratio = getEcoreFloat(hypo.massRatio, 0);
    const psi_0 = getEcoreFloat(hypo.psi0, 0);
    const psi_1 = getEcoreFloat(hypo.psi1, 0);
    const psi_2 = getEcoreFloat(hypo.psi2, 0);
    const tag = (hypo.tag !== loadHypothesisList.RESIDENTIAL) ? getEcoreHypothesisTag(hypo.tag) : undefined;
    const unfav_sse = getEcoreFloat(hypo.unfavorableSse, 1);
    const service = getEcoreFloat(hypo.service, 1);
    const construction_phase = getEcoreInt(hypo.constructionPhase, 360);
    const type = (hypo.type !== "DL") ? getEcoreHypothesisType(hypo.type) : undefined;

    if (name !== undefined) {
      hypothesis.name = name;
    }
    if (fav !== undefined) {
      hypothesis.fav = fav;
    }
    if (un_fav !== undefined) {
      hypothesis.un_fav = un_fav;
    }
    if (mass_ratio !== undefined) {
      hypothesis.mass_ratio = mass_ratio;
    }
    if (psi_0 !== undefined) {
      hypothesis.psi_0 = psi_0;
    }
    if (psi_1 !== undefined) {
      hypothesis.psi_1 = psi_1;
    }
    if (psi_2 !== undefined) {
      hypothesis.psi_2 = psi_2;
    }
    if (tag !== undefined) {
      hypothesis.tag = tag;
    }
    if (unfav_sse !== undefined) {
      hypothesis.unfav_sse = unfav_sse;
    }
    if (service !== undefined) {
      hypothesis.service = service;
    }
    if (construction_phase !== undefined) {
      hypothesis.construction_phase = construction_phase;
    }
    if (type !== undefined) {
      hypothesis.type = type;
    }

    return hypothesis;
  }
  
  private getSSEHypothesisEcore(hypo: SSHypothesis): DirSSEHypothesis {
    const hypothesis = this.getHypothesisEcore(hypo, []) as DirSSEHypothesis;
    hypothesis.eClass = baseUriModel + "analysis/DirSSEHypothesis";
    const sseDir = hypo.sseDir;
    if (sseDir !== SSEDirs.SSX) hypothesis.sse_dir = getEcoreSseDir(sseDir);
    hypothesis.values = [];
    for (const val of hypo.values) {
      const sseRecord: SSERecord = { eClass: baseUriModel + "analysis/SSERecord" };
      const t = getEcoreFloat(val.T, 0);
      const ag = getEcoreFloat(val.ag, 0);
      if (t !== undefined) sseRecord.t = t;
      if (ag !== undefined) sseRecord.ag = ag;
      hypothesis.values.push(sseRecord);
    }
    return hypothesis;
  }

  private getWindHypothesisEcore(hypo: IwindHypothesis): WindHypothesis {
    const loads = windhypothesisManager.getWindLoadHypothesisByUiid(hypo.uid);
    const hypothesis = this.getHypothesisEcore(hypo, loads) as WindHypothesis;
    hypothesis.eClass = baseUriModel + "analysis/WindHypothesis";
    const windType = hypo.subType;
    if (windType !== windSubTypes.WX1P) hypothesis.wind_type = getEcoreWindType(windType);
    return hypothesis;
  }

  private exportLoadsToEcore(loads: LoadStructuralData[]): ILoadEcore[] {
    const results: ILoadEcore[] = [];

    const N = loads.length;
    let index = 0;
    console.log(`EcoreAnalysisExporter.exportLoadsToEcore() exporting ${N} loads...`);
    for (const load of loads) {
      console.log(`\tExporting load [${index}/${N}]: "${load.definition.name}" <${load.definition.type}>.`);
      const parentElem = load.parentStrucElem;
      if (parentElem) {
        // Se llama a una especie de factoria para construir el objeto receptor de los datos paternos.
        const parentExporter = getEcoreStrucElemExporter(parentElem.type);
        // Aqui no veo claro que hace.
        parentExporter.setStrucElem(parentElem, this.crossSectionShapesIds);

        const loadElem = new EcoreLoadElem();
        loadElem.setStrucElem(load, parentExporter);
        results.push(loadElem.exportToEcore());
      } else {
        // Creo que esto no deberia ser posible.
        console.log("No parent info.");
        debugger;
      }

      ++index;
    }

    return results;
  }
}

function getEcoreHypothesisTag(tag: loadHypothesisList): HYPOTHESIS_NAMES {
  if (tag === loadHypothesisList.RESIDENTIAL) return HYPOTHESIS_NAMES.RESIDENTIAL;
  if (tag === loadHypothesisList.OFFICE) return HYPOTHESIS_NAMES.OFFICE;
  if (tag === loadHypothesisList.PARKING) return HYPOTHESIS_NAMES.PARKING;
  if (tag === loadHypothesisList.SHOPPING) return HYPOTHESIS_NAMES.SHOPPING;
  if (tag === loadHypothesisList.ACCESSIBLE_ROOF) return HYPOTHESIS_NAMES.ACCESSIBLE_ROOF;
  if (tag === loadHypothesisList.NON_ACCESSIBLE_ROOF) return HYPOTHESIS_NAMES.NON_ACCESSIBLE_ROOF;
  if (tag === loadHypothesisList.SNOW_ABOVE_1000) return HYPOTHESIS_NAMES.SNOW_ABOVE_1000;
  if (tag === loadHypothesisList.SNOW_BELOW_1000) return HYPOTHESIS_NAMES.SNOW_BELOW_1000;
  if (tag === loadHypothesisList.OTHER) return HYPOTHESIS_NAMES.OTHER;
  if (tag === loadHypothesisList.DEAD_LOAD) return HYPOTHESIS_NAMES.DEAD_LOAD;
  if (tag === loadHypothesisList.SELF_WEIGHT) return HYPOTHESIS_NAMES.SELF_WEIGHT;
  if (tag === loadHypothesisList.WIND) return HYPOTHESIS_NAMES.WIND;
  if (tag === loadHypothesisList.TEMPERATURE) return HYPOTHESIS_NAMES.TEMPERATURE;
  if (tag === loadHypothesisList.EARTH_PRESSURE) return HYPOTHESIS_NAMES.EARTH_PRESSURE;
  return HYPOTHESIS_NAMES.SSE;
}

function getEcoreHypothesisType(type: hypothesisTypes): HYPOTHESIS_TYPES {
  if (type === "DL") return HYPOTHESIS_TYPES.DL;
  if (type === "LL") return HYPOTHESIS_TYPES.LL;
  if (type === "WIND") return HYPOTHESIS_TYPES.WIND;
  if (type === "TEMP") return HYPOTHESIS_TYPES.TEMP;
  if (type === "EARTH") return HYPOTHESIS_TYPES.EARTH;
  return HYPOTHESIS_TYPES.SSE;
}

function getEcoreSseDir(sseDir: SSEDirs): SSEDirsEcore {
  if (sseDir === SSEDirs.SSX) return SSEDirsEcore.SSx;
  if (sseDir === SSEDirs.SSY) return SSEDirsEcore.SSy;
  return SSEDirsEcore.SSz;
}

function getEcoreWindType(windType: windSubTypes): WindTypes {
  if (windType === windSubTypes.WX1P) return WindTypes.Wx1Pos;
  if (windType === windSubTypes.WX2P) return WindTypes.Wx2Pos;
  if (windType === windSubTypes.WX1N) return WindTypes.Wx1Neg;
  if (windType === windSubTypes.WX2N) return WindTypes.Wx2Neg;
  if (windType === windSubTypes.WY1P) return WindTypes.Wy1Pos;
  if (windType === windSubTypes.WY2P) return WindTypes.Wy2Pos;
  if (windType === windSubTypes.WY1N) return WindTypes.Wy1Neg;
  return WindTypes.Wy2Neg;
}
