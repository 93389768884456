import * as THREE from "three";
import { IObjData } from "../models/objdata";
import { GraphicProcessor } from "../graphic-processor";
import { SelectionAction, SelectionActionType } from "lib/events/select";
import { LayerAction, LayerActionType } from "lib/events/layers";
import { IObjDataSelector } from "./selector-objdata/selector-data";
import { getMarkMaterial, markTypes, selectionCenterGripColor, selectionColor, selectionGripColor, selectionGripSize, selectionOriginGripColor, selectionWith } from "./selection-tools";
import { ViewportAction } from '../events/viewports';
import { PointBufferHandler } from "lib/geometry-optimizer/point-optimizer";
import { IPoint } from "lib/math/types";
import { lineBufferHandler } from "lib/geometry-optimizer/line-optimizer";
import { LineSegmentsGeometry } from "three/examples/jsm/lines/LineSegmentsGeometry";
import { LineSegments2 } from "three/examples/jsm/lines/LineSegments2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { ObserverManager } from "lib/events/event-bus";
import { getDataSelector } from "./selector-creator";
import { EditActionType, editObjAction } from "lib/events/objectdata";

export class SelectionManager {

  private graphicProcessor: GraphicProcessor;
  // Selection Events Handler (Observer)
  private selectionObserver: ObserverManager<SelectionAction>;
  // Mapeo relación [Datos-Marcas de selección]
  private selectedData: Map<IObjData, IObjDataSelector> = new Map();
  // Mapeo relación [Marcas de selección - Datos]
  private selectMarks: Map<THREE.Object3D, IObjDataSelector | PointBufferHandler<IObjDataSelector>> = new Map();

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    this.selectionObserver = new ObserverManager();
  }

  connectEvents() {
    const lyrMng = this.graphicProcessor.getLayerManager();
    lyrMng.layerObserver.subscribe(this.handlerLayersActions);

    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.subscribe(this.handlerEditObjDataAction);

    // const container = this.graphicProcessor.container;
    // container.addEventListener("wheel", this.dispatchUpdateEdgeMark);
    // this.graphicProcessor.viewportObserver.subscribe(this.dispatchUpdateEdgeMark);
    this.iniVertexMarksSelectors();
  }

  disconnectEvents() {
    const lyrMng = this.graphicProcessor.getLayerManager();
    lyrMng.layerObserver.unsubscribe(this.handlerLayersActions);

    const dtMdlMngr = this.graphicProcessor.getDataModelManager();
    dtMdlMngr.unsubscribe(this.handlerEditObjDataAction);

    // const container = this.graphicProcessor.container;
    // container.removeEventListener("wheel", this.dispatchUpdateEdgeMark);
    // this.graphicProcessor.viewportObserver.unsubscribe(this.dispatchUpdateEdgeMark);
    this.clearVertexMarkSelectors();
  }

  private iniVertexMarksSelectors() {
    const materialOrigin = getMarkMaterial(markTypes.POINT, selectionGripSize, selectionOriginGripColor);
    this.originSelector = new PointBufferHandler(materialOrigin, false);
    this.addToSelectionScene(this.originSelector.pointObj);
    this.selectMarks.set(this.originSelector.pointObj, this.originSelector);

    const materialVertex = getMarkMaterial(markTypes.POINT, selectionGripSize, selectionGripColor);
    this.vertexSelector = new PointBufferHandler(materialVertex, false);
    this.addToSelectionScene(this.vertexSelector.pointObj);
    this.selectMarks.set(this.vertexSelector.pointObj, this.vertexSelector);

    const materialCenter = getMarkMaterial(markTypes.CENTROID, selectionGripSize, selectionCenterGripColor);
    this.centerSelector = new PointBufferHandler(materialCenter, false);
    this.addToSelectionScene(this.centerSelector.pointObj);
    this.selectMarks.set(this.centerSelector.pointObj, this.centerSelector);

    const materialCentroid = getMarkMaterial(markTypes.CENTER, selectionGripSize, selectionGripColor);
    this.centroidSelector = new PointBufferHandler(materialCentroid, false);
    this.addToSelectionScene(this.centroidSelector.pointObj);
    this.selectMarks.set(this.centroidSelector.pointObj, this.centroidSelector);

    this.lineSelector = new lineBufferHandler(undefined, false);
  }

  private clearVertexMarkSelectors() {
    this.selectedData.clear();
    this.selectMarks.clear();
    this.removeFromSelectionScene(this.originSelector.pointObj);
    this.originSelector.pointObj.geometry.dispose();
    this.removeFromSelectionScene(this.vertexSelector.pointObj);
    this.vertexSelector.pointObj.geometry.dispose();
    this.removeFromSelectionScene(this.centerSelector.pointObj);
    this.centerSelector.pointObj.geometry.dispose();
    this.removeFromSelectionScene(this.centroidSelector.pointObj);
    this.centroidSelector.pointObj.geometry.dispose();
    this.clearLineGraphicObj();
    this.lineSelector.disposeData();
    // this.removeFromSelectionScene(this.lineGraphicObject);
    // this.lineGraphicObject.geometry.dispose();
    // this.lineSelector.line[0].geometry.dispose();
  }

  private handlerLayersActions = (action: LayerAction) => {
    if (action.type === LayerActionType.DRAG_OBJECT2LAYER) {
      const isVisible = action.payload.dstLayer.visible;
      const isBlock = action.payload.dstLayer.locked;
      if (!isVisible || isBlock) {
        const data = action.payload.srcObject;
        this.unSelectObjData(data);
      }
      return;
    }
  };
  
  private handlerEditObjDataAction = (action: editObjAction) => {
    if (action.type === EditActionType.EDIT_OBJ) {
      const objDatas = action.payload.objsEdited;
      for (const objData of objDatas) {
        if (this.isDataSelected(objData)) {
          this.updateSelectObjData(objData);
        }
      }
    }
  };
  
  private dispatchUpdateEdgeMark = (event: WheelEvent | ViewportAction) => {
    this.updateAllEdgeMarkSize();
  };

  get selectedObjs() {
    return [...this.selectedData.keys()];
  }
  
  isDataSelected(objData: IObjData) {
    return this.selectedData.has(objData);
  }
  
  canBeSelected(objData: IObjData) {
    return objData.isVisible && !objData.isLocked && !this.isDataSelected(objData);
  }

  // ***************************************

  subscribe(listener: (action: SelectionAction) => void) {
    this.selectionObserver.subscribe(listener);
  }
  
  unsubscribe(listener: (action: SelectionAction) => void) {
    this.selectionObserver.unsubscribe(listener);
  }

  unSelectObjDatas(datas: IObjData[]) {
    for (const data of datas) {
      if (this.isDataSelected(data)) {
        this.removeSelectMarks(data);
      }
    }
    this.dispatchUnselectedObjects(this.selectedObjs);
  }
  
  unSelectObjData(data: IObjData) {
    if (this.isDataSelected(data)) {
      this.removeSelectMarks(data);
      this.dispatchUnselectedObjects(this.selectedObjs);
    }
  }
  
  selectObjDatas(datas: IObjData[]) {
    if (datas.length) {
      const objSelected = this.selectedObjs;
      const selected = [];
      const unSelected = [];
      for (const data of datas) {
        if (this.canBeSelected(data)) {
          // FGM: Objeto seleccionado por el raton, creo.
          if (true) {
            // El problema de la forma JaWS de usar definiciones de tipos con OR... Cuestion 47) de Tips4TypeScript.txt
            let isOk = false;
            if (data.definition) {
              // @ts-ignore
              if (data.definition.name) {
                // @ts-ignore
                console.log(`[SelectionManager.selectObjDatas("${data.definition.name}")]`);
                isOk = true;
              }
            }

            if (!isOk) {
              console.log(`[SelectionManager.selectObjDatas("NO-NAMED-OBJECT???")]`);
            }
          }

          objSelected.push(data);
          selected.push(data);
        } else {
          const indx = objSelected.indexOf(data);
          objSelected.splice(indx, 1);
          unSelected.push(data);
        }
      }

      // Esto es para la posible seleccion anterior???...
      this.removeAllSelectMarks();
      if (objSelected.length) {
        this.drawSelectMarks(objSelected.map(o => getDataSelector(o, this.graphicProcessor)));
      } else {
        this.updateLineGraphicObj();
      }
      this.dispatchSelectedObjects(selected, unSelected);
    }
  }

  selectObjData(data: IObjData) {
    const selected = [];
    const unSelected = [];
    if (this.canBeSelected(data)) {
      const dataselector = getDataSelector(data, this.graphicProcessor);
      this.drawSelectMarks([dataselector]);
      selected.push(data);
    } else {
      this.removeSelectMarks(data);
      unSelected.push(data);
    }
    this.dispatchSelectedObjects();
  }

  unselectAll() {
    this.removeAllSelectMarks();
    this.clearLineGraphicObj();
    this.selectionObserver.dispatch({
      type: SelectionActionType.UNSELECTALL,
      payload: { obj: [] },
    });
  }

  updateSelectObjData(data: IObjData) {
    this.removeSelectMarks(data);
    this.selectObjData(data);
  }

  // ***************************************

  dispatchSelectedObjects(selected: IObjData[] = [], unSelected: IObjData[] = []) {
    this.selectionObserver.dispatch({
      type: SelectionActionType.SELECT,
      payload: {
        obj: this.selectedObjs,
        selected,
        unSelected,
      },
    });
  }

  dispatchSelectedObject(objData: IObjData) {
    this.selectionObserver.dispatch({
      type: SelectionActionType.SELECT_ONE,
      payload: { objData },
    });
  }

  private dispatchUnselectedObjects(unselectedObjs: IObjData[] = []) {
    this.selectionObserver.dispatch({
      type: SelectionActionType.UNSELECT,
      payload: { obj: unselectedObjs },
    });
  }

  // ***************************************

  private updateAllEdgeMarkSize() {
    const viewPrts = this.graphicProcessor.managerVP.getEnabledViewports();
    for (const edgeMark of this.iterEdgeMarks()) {
      let sizes = Infinity;
      for (const viewPrt of viewPrts) {
        const size = this.graphicProcessor.getSizeUnitFromPixelUnit(selectionGripSize, edgeMark.position, viewPrt.camera as THREE.Camera);
        if (sizes > size) sizes = size;
      }
      edgeMark.scale.set(sizes, sizes, sizes);
    }
  }
  
  private *iterEdgeMarks(): IterableIterator<THREE.Mesh> {
    for (const data of this.selectedData.values()) {
      if (data.EDGE) {
        for (const edgeMark of data.EDGE) {
          yield edgeMark;
        }
      }
    }
  }

  // ***************************************

  originSelector: PointBufferHandler<IObjDataSelector>;
  vertexSelector: PointBufferHandler<IObjDataSelector>;
  centerSelector: PointBufferHandler<IObjDataSelector>;
  centroidSelector: PointBufferHandler<IObjDataSelector>;

  private lineSelector: lineBufferHandler<IObjData>;
  private lineGraphicObject: Map<THREE.LineSegments, LineSegments2> = new Map();

  private updateLineGraphicObj() {
    for (let i = 0; i < this.lineSelector.line.length; i++) {
      const line = this.lineSelector.line[i];
      const fatLine = this.lineGraphicObject.get(line) as LineSegments2;

      const buffGeom = line.geometry as THREE.BufferGeometry;
      const geometry = buffGeom.index ? buffGeom.toNonIndexed() : buffGeom;
      const positionAttribute = geometry.getAttribute('position');
      if (positionAttribute) {
        const lineSegmGeom = new LineSegmentsGeometry();
        lineSegmGeom.setPositions(positionAttribute.array as number[]);
        if (!fatLine) {
          const material = getMarkMaterial(markTypes.LINE, selectionWith, selectionColor) as LineMaterial;
          material.resolution.set(window.innerWidth, window.innerHeight);
          const fatLine = new LineSegments2(lineSegmGeom, material);
          this.lineGraphicObject.set(line, fatLine);
          this.addToSelectionScene(fatLine);
        } else {
          fatLine.geometry = lineSegmGeom;
          fatLine.geometry.computeBoundingBox();
          fatLine.geometry.computeBoundingSphere();
        }
      } else {
        if (fatLine) {
          fatLine.geometry.dispose();
          fatLine.material.dispose();
          this.removeFromSelectionScene(fatLine);
          this.lineGraphicObject.delete(line);
        }
      }
    }
  }

  private clearLineGraphicObj() {
    for (const line of this.lineGraphicObject.values()) {
      line.geometry.dispose();
      line.material.dispose();
      this.removeFromSelectionScene(line);
    }
    this.lineGraphicObject.clear();
  }

  // ***************************************

  private drawSelectMarks(selectors: IObjDataSelector[]) {

    const origin: { obj: IObjDataSelector, points: IPoint[] }[] = [];
    const vertex: { obj: IObjDataSelector, points: IPoint[] }[] = [];
    const center: { obj: IObjDataSelector, points: IPoint[] }[] = [];
    const centroid: { obj: IObjDataSelector, points: IPoint[] }[] = [];
    const lines: { obj: IObjData, bufferGeom: Float32Array[] }[] = [];

    for (const selector of selectors) {
      this.selectedData.set(selector.data, selector);

      const bulkMarksType = selector.buildSelectMarks();
      if (bulkMarksType?.origin) {
        origin.push({ obj: selector, points: bulkMarksType.origin });
      }
      if (bulkMarksType?.vertex) {
        vertex.push({ obj: selector, points: bulkMarksType.vertex });
      }
      if (bulkMarksType?.center) {
        center.push({ obj: selector, points: bulkMarksType.center });
      }
      if (bulkMarksType?.centroid) {
        centroid.push({ obj: selector, points: bulkMarksType.centroid });
      }
      if (bulkMarksType?.line) {
        lines.push({ obj: selector.data, bufferGeom: bulkMarksType.line });
      }

      // if (selector.EDGE) {
      //   selector.EDGE.forEach((e) => {
      //     this.addToSelectionScene(e);
      //     this.selectMarks.set(e, selector);
      //   });
      // }
      if (selector.BBOX) {
        this.addToSelectionScene(selector.BBOX);
        this.selectMarks.set(selector.BBOX, selector);
      }
    }

    if (origin.length) this.originSelector.loadDatas(origin);
    if (vertex.length) this.vertexSelector.loadDatas(vertex);
    if (center.length) this.centerSelector.loadDatas(center);
    if (centroid.length) this.centroidSelector.loadDatas(centroid);
    if (lines.length) {
      this.lineSelector.loadDatas(lines);
      this.updateLineGraphicObj();
    }
    // if (selector.MAT) {
    // if (((selector.data.graphicObj as THREE.Mesh).material as THREE.MeshPhongMaterial)?.emissive) {
    //   ((selector.data.graphicObj as THREE.Mesh).material as THREE.MeshPhongMaterial).emissive.setHex(0x232323);
    // }
    // FGM: Agregamos el objeto seleccionado.
    // const composer = this.graphicProcessor.getComposerFX();
    // composer.selectObject(selector.data.graphicObj);
    //}
  }

  private removeSelectMarks(data: IObjData) {
    this.deleteBulkSelectMarks(data);
    this.deleteSelectMarks(data);
    this.selectedData.delete(data);
  }

  private deleteBulkSelectMarks(data: IObjData) {
    const dataSelector = this.selectedData.get(data);
    if (dataSelector) {
      this.vertexSelector.removeData(dataSelector);
      this.originSelector.removeData(dataSelector);
      this.centerSelector.removeData(dataSelector);
      this.centroidSelector.removeData(dataSelector);
      this.lineSelector.removeData(data);
      dataSelector.data = undefined!
      this.updateLineGraphicObj();
    }
  }

  private deleteSelectMarks(data: IObjData) {
    const dataSelector = this.selectedData.get(data)!;
    // if (dataSelector?.EDGE) {
    //   dataSelector.EDGE.forEach((mark) => {
    //     this.removeFromSelectionScene(mark);
    //     this.selectMarks.delete(mark);
    //   });
    //   dataSelector.EDGE.length = 0;
    // }
    if (dataSelector?.BBOX) {
      this.removeFromSelectionScene(dataSelector.BBOX);
      dataSelector.BBOX.geometry.dispose();
      this.selectMarks.delete(dataSelector.BBOX);
      dataSelector.BBOX = undefined;
    }
    //if (dataSelector?.MAT) {
    // if (((data.graphicObj as THREE.Mesh).material as THREE.MeshPhongMaterial)?.emissive) {
    //   ((data.graphicObj as THREE.Mesh).material as THREE.MeshPhongMaterial).emissive.setHex(0x000000);
    // }
    // FGM: Quitar el objeto de los seleccionados.
    // const composer = this.graphicProcessor.getComposerFX();
    // composer.unselectObject(data.graphicObj);
    //}
  }

  private removeAllSelectMarks() {
    for (const data of this.selectedData.keys()) {
      this.deleteSelectMarks(data);
      this.selectedData.delete(data);
    }
    this.originSelector.clearData();
    this.vertexSelector.clearData();
    this.centerSelector.clearData();
    this.centroidSelector.clearData();
    this.lineSelector.clearData();
    this.clearLineGraphicObj();
  }

  private addToSelectionScene(obj: THREE.Object3D) {
    const selectionScene = this.graphicProcessor.getSelectionScene()
    selectionScene.add(obj);
  }

  private removeFromSelectionScene(obj: THREE.Object3D) {
    const selectionScene = this.graphicProcessor.getSelectionScene()
    selectionScene.remove(obj);
  }

  // ***************************************

  getMarksOperation(mark: THREE.Intersection) {
    const selector = this.selectMarks.get(mark.object);
    if (selector) {
      const res = selector.getDataFromBufferIndex(mark.index!);
      if (res) {
        const selctr = this.selectedData.get(res.data.data)!;
        const opParam = selctr.getMarkOP(this, mark.object, res.index);
        if (opParam) {
          return opParam;
        }
      }
    }
  }

} // class SelectionManager
