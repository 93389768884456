import { userMessageEvents } from "lib/events/user-messages";
import { lineAuxCreate, lineAddVertex, lineMoveVertex, lineCreate } from "lib/geometries/line";
import { filledPolygon2DPoints, updateFilledPolygon2DPoints } from "lib/geometries/solid/region";
import { IPoint } from "lib/math/types";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { isLineClosedData, isLineData, isStructData } from "lib/models/checktools";
import { IObjData } from "lib/models/objdata";
import { LineData } from "lib/models/primitives/line";
import { IStrucElementData } from "lib/models/structural/structural";
import { LoadCommand } from "../../commands/structural/load";
import { copyIPoint, substractIpoint } from "../../math/point";
import { loadType } from "../../models-struc/types/load";
import { dataInfoProperty, infoProperty } from "../../properties/properties";
import { cadOpType } from "../factory";
import { propSetting, settingsOpModes, settingOp } from "../step-operations";
import { StructBaseOP } from "./structural";

interface ILoadSettings {
  hypothesisId: string;
  value: number;
  polyline: undefined;
}

abstract class LoadBaseOP extends StructBaseOP {

  protected type: loadType;
  protected hypothesisList: [string, string][] = hypothesisManager.getAllHypothesis().map(h => [h.name, h.uid]);
  protected hypothesisId: string = this.hypothesisList[0][1];
  protected value: number = 50000; // Newtons
  protected parentStructData: IStrucElementData;
  protected points: IPoint[] = [];

  protected iniSettingsOp(): void {
    console.log("iniSettingsOp()");
    this.settingsOpManager.setCfg([this.selectStructuralElementStep,
    {
      infoMsg: "Insert load point: ",
      stepMode: settingsOpModes.DEFAULTXYZ,
      cmdLineListener: this.addPointFromExt.bind(this),
      panelProp: this.setPanelProperties(),
    }]);
  }
  
  protected abstract setPanelProperties(): propSetting<ILoadSettings>

  public setBasePanelProperties(): infoProperty {
    return {
      publicName: "Hypothesis",
      type: "tagList",
      editable: true,
      tagList: this.hypothesisList,
      value: this.hypothesisId,
    }
  }

  protected updatePanel(newLoadProp: ILoadSettings) {
    this.hypothesisId = newLoadProp.hypothesisId;
    this.value = newLoadProp.value;

    this.settingsOpManager.currCfg = {
      ...this.settingsOpManager.currCfg,
      panelProp: this.setPanelProperties(),
    }
    this.settingsOpManager.dispatchUpdateCurrStep();
  }

  protected selectStructuralElementStep: settingOp = {
    infoMsg: "Select structural element",
    stepMode: settingsOpModes.SELECTOBJS,
    enableSelectMarks: false,
    multiSelect: false,
    filterFun: isStructData,
    startStepCallback: () => {
      console.log("startStepCallback()");
      const selMng = this.graphicProcessor.getSelectionManager();
      selMng.unselectAll();
    },
    endStepCallback: () => {
      console.log("endStepCallback()");
      this.unRegisterRaycast();
      this.parentStructData = this.objDataOrigin[0] as IStrucElementData;
      this.objDataOrigin.length = 0;
      this.endSelectStructuralElementStep();

      this.initializeSnap();
      this.initializeWorkingPlane();
      this.registerInputs();
      this.registerUpdaters();
    }
  }

  protected endSelectStructuralElementStep() {
    // In base class => nothing to do.
    // Override in child classes
  }

  public async start() {
    this.iniSettingsOp();
    this.registerCancel();
    this.registerRaycast()
  }

  public save(): void {
    const hypothesis = hypothesisManager.getHypothesisByid(this.hypothesisId);
    if (this.graphicProcessor && hypothesis) {
      const command = new LoadCommand(
        this.type,
        hypothesis.uid,
        this.value,
        this.points,
        this.basePoint,
        this.rotation,
        this.parentStructData,
        true,
        this.graphicProcessor
      );
      if (command) this.graphicProcessor.storeAndExecute(command);
    }
  }
}

export class LoadConcentratedOP extends LoadBaseOP {
  public opType = cadOpType.LOADCONCENTRATED;
  protected type = loadType.CONCENTRATED;

  protected setPanelProperties() {
    const panelProp: dataInfoProperty<ILoadSettings> = {};
    panelProp.hypothesisId = this.setBasePanelProperties();
    panelProp.value = {
      type: "number",
      publicName: "Value",
      value: this.value / 1000,
      editable: true,
      precision: 3,
      units: "kN",
      parseFun: (v: number) => v * 1000,
    }
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  public setLastPoint(): void {
    this.basePoint = copyIPoint(this.lastPoint);
    this.endOperation();
  }
}

export class LoadLinealOP extends LoadBaseOP {
  public opType = cadOpType.LOADLINEAL;
  protected type = loadType.LINEAL;

  private auxLine: THREE.Line;

  protected override iniSettingsOp(): void {
    super.iniSettingsOp();
    this.settingsOpManager.cfg.push({
      infoMsg: "Select Polyline",
      stepMode: settingsOpModes.SELECTOBJS,
      enableSelectMarks: false,
      multiSelect: false,
      filterFun: (obj: IObjData) => {
        if (!isLineData(obj)) {
          userMessageEvents.dispatchError("Select a polyline");
          return false;
        }
        return true;
      },
      endStepCallback: () => {
        const lineData = this.objDataOrigin[0] as LineData;
        const ptos = lineData.definition.points;
        this.basePoint = copyIPoint(ptos[0]);
        this.points = ptos.map((p => (substractIpoint(p, this.basePoint))));
        super.endOperation();
      },
      startStepCallback: (cfg: settingOp) => {
        this.unRegisterInputs();
        this.closeSnap();
        this.clearWorkingPlane();
        this.registerRaycast();
      },
    });
  }

  protected endSelectStructuralElementStep() {
    this.initializeLine();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.locked = true;
  }

  private initializeLine(): void {
    this.auxLine = lineAuxCreate();
    this.saveToTempScene(this.auxLine);
  }

  protected setPanelProperties() {
    const panelProp: dataInfoProperty<ILoadSettings> = {};
    panelProp.hypothesisId = this.setBasePanelProperties()
    panelProp.value = {
      type: "number",
      publicName: "Value",
      value: this.value / 1000,
      editable: true,
      precision: 3,
      units: "kN/m",
      parseFun: (v: number) => v * 1000,
    };
    panelProp.polyline = {
      publicName: "Select polyline",
      type: "button",
      buttonCb: () => {
        this.setNextStep(2);
      }
    };
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  public setLastPoint(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (this.numPoints === 1) {
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
    }
    this.points.push(planeManager.activePlane.getRelativePoint(this.lastPoint));

    const { x, y, z } = this.lastPoint;
    lineAddVertex(this.auxLine, x, y, z);
    if (this.numPoints === 1) {
      lineAddVertex(this.auxLine, x, y, z);
      this.basePoint = copyIPoint(this.lastPoint);
      this.rotation = copyIPoint(planeManager.activePlane.rotation);
    }
  }

  public moveLastPoint(pto: IPoint) {
    if (this.numPoints > 0) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z);
    }
  }

  public cancelOperation(): void {
    if (!this.finished) {
      if (this.numPoints > 1) {
        this.endOperation();
      } else {
        super.cancelOperation();
      }
    }
  }
}

export class LoadSuperficialOP extends LoadBaseOP {
  public opType = cadOpType.LOADSUPERFICIAL;
  protected type = loadType.SUPERFICIAL;

  private line: THREE.Line;
  private auxLine: THREE.Line;
  protected auxRegion: THREE.Mesh;

  protected override iniSettingsOp(): void {
    super.iniSettingsOp();
    this.settingsOpManager.cfg.push({
      infoMsg: "Select Polyline",
      stepMode: settingsOpModes.SELECTOBJS,
      enableSelectMarks: false,
      multiSelect: false,
      filterFun: (obj: IObjData) => {
        if (!isLineClosedData(obj)) {
          userMessageEvents.dispatchError("Select a closed polyline");
          return false;
        }
        return true;
      },
      endStepCallback: () => {
        const lineData = this.objDataOrigin[0] as LineData;
        const ptos = lineData.definition.points;
        this.basePoint = copyIPoint(ptos[0]);
        this.points = ptos.map((p => (substractIpoint(p, this.basePoint))));
        super.endOperation();
      },
      startStepCallback: (cfg: settingOp) => {
        this.unRegisterInputs();
        this.closeSnap();
        this.clearWorkingPlane();
        this.registerRaycast();
      },
    });
  }

  protected endSelectStructuralElementStep() {
    this.initializeAux();
    const planeManager = this.graphicProcessor.getPlaneManager();
    planeManager.activePlane.locked = true;
  }

  private initializeAux(): void {
    this.line = lineCreate(undefined, undefined, "#ffffff");
    this.auxLine = lineAuxCreate();
    this.auxRegion = filledPolygon2DPoints([]);
    lineAddVertex(this.auxLine, 0, 0, 0);
    lineAddVertex(this.auxLine, 0, 0, 0);
    this.saveToTempScene(this.line);
    this.saveToTempScene(this.auxLine);
    this.saveToTempScene(this.auxRegion);
  }

  protected setPanelProperties() {
    const panelProp: dataInfoProperty<ILoadSettings> = {};
    panelProp.hypothesisId = this.setBasePanelProperties()
    panelProp.value = {
      type: "number",
      publicName: "Value",
      value: this.value / 1000,
      editable: true,
      precision: 3,
      units: "kN/m²",
      parseFun: (v: number) => v * 1000,
    };
    panelProp.polyline = {
      publicName: "Select polyline",
      type: "button",
      buttonCb: () => {
        this.setNextStep(2);
      }
    };
    return {
      propValue: panelProp,
      propCallback: this.updatePanel.bind(this),
    };
  }

  public setLastPoint(): void {
    const planeManager = this.graphicProcessor.getPlaneManager();
    if (this.numPoints === 1) {
      planeManager.activePlane.position = copyIPoint(this.lastPoint);
    }

    const { x, y, z } = this.lastPoint;
    lineAddVertex(this.line, x, y, z);

    this.points.push(planeManager.activePlane.getRelativePoint(this.lastPoint));

    updateFilledPolygon2DPoints(this.auxRegion, this.points);
    const { position, rotation } = planeManager.activePlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxRegion.position.set(position.x, position.y, position.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);

    lineMoveVertex(this.auxLine, x, y, z, 0);
    if (this.numPoints === 1) {
      lineMoveVertex(this.auxLine, x, y, z);
    }
  }

  public moveLastPoint(pto: IPoint) {
    if (this.numPoints > 0) {
      lineMoveVertex(this.auxLine, pto.x, pto.y, pto.z, 1);
    }
    this.updateRegion(pto);
  }

  protected updateRegion(pto: IPoint) {
    const ptos2D = this.points.concat(this.currPlane.getRelativePoint(pto));
    updateFilledPolygon2DPoints(this.auxRegion, ptos2D);
    const { position, rotation } = this.currPlane;
    this.basePoint = position;
    this.rotation = rotation;
    this.auxRegion.position.set(position.x, position.y, position.z);
    this.auxRegion.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  public cancelOperation(): void {
    if (!this.finished) {
      if (this.numPoints > 2) {
        this.endOperation();
      } else {
        super.cancelOperation();
      }
    }
  }
}
