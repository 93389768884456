import * as THREE from "three";
import { getRotationVector, rotationToQuaternion } from "lib/math/rotate";
import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcorePointGeometry } from "../geometry";
import { baseUriModel } from "../mesh-exporter";
import { CrossSectionRepresentation, ExtrudedRepresentation, GeoRepresentation } from "modules/struc/models/ecore/representation";
import { Vector } from "modules/struc/models/ecore/location";
import { Beam } from "modules/struc/models/ecore/structural";
import { normalizeIpoint } from "lib/math/point";
import { BeamStructuralElementEcore } from "modules/struc/models/ecore/mesh";
import { BeamData } from "lib/models/structural/beam";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { Point } from "modules/struc/models/ecore/geometry";
import { representationUriModel, structuralUriModel } from "modules/struc/models/ecore/uri";

export class EcoreBeamElem extends EcoreStrucElemBase {

  private strucElem: BeamData;
  private crossSectionRef: number;

  setStrucElem(strElem: BeamData, crossSectionShapesIds: string[]) {
    this.strucElem = strElem;
    const repr = this.strucElem.definition;
    this.crossSectionRef = crossSectionShapesIds.indexOf(repr.crossSectionId);
  }

  exportToEcore(materialRef: number): Beam {
    const repr = this.strucElem.definition;
    const beamEcore: Beam = {
      eClass: `${structuralUriModel}Beam`,
      id: this.strucElem.id,
      name: repr.name,
      material: this.getEcoreMaterialRef(materialRef),
      placement: getEcorePlacement({ ...repr.basePoint, z: 0 }, this.getWorldRotation()),
      representation: this.getRepresentation(this.crossSectionRef),
    }
    return beamEcore;
  }
  private getRepresentation(cssIndexRef: number): ExtrudedRepresentation<CrossSectionRepresentation> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      orientation: this.getOrientationVectorY(),
      base: this.getEcoreCrossSectionRepresentation(cssIndexRef),
    }
  }

  exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): BeamStructuralElementEcore {
    const femStr: BeamStructuralElementEcore = {
      eClass: baseUriModel + "mesh/BeamStructuralElement",
      structuralelement: {
        eClass: baseUriModel + "structural/Beam",
        $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
      },
      section: {
        eClass: baseUriModel + "mesh/CrossSection",
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
      },
      representation: this.getFEMStrcElemRepresentation(),
      orientation: this.getOrientationVectorY(),
    }
    return femStr;
  }
  private getFEMStrcElemRepresentation(): ExtrudedRepresentation<GeoRepresentation<Point>> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      orientation: this.getOrientationVectorY(),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcorePointGeometry({ x: 0, y: 0, z: 0 }),
      },
    }
  }

  private getWorldRotation() {
    const repr = this.strucElem.definition;
    const xVect = normalizeIpoint(repr.points[1]);
    const rot = getRotationVector(xVect);
    return rotationToQuaternion(rot);
  }
  private getOrientationVectorY(): Vector {
    const repr = this.strucElem.definition;
    const xVec = normalizeIpoint(repr.points[1]);
    const rotL = getRotationVector(xVec, repr.orientation);
    const yVec = new THREE.Vector3(0, 0, 1);
    yVec.applyEuler(rotL);
    // FGM: No rounding.
    return getEcoreVector3(yVec);
  }
  getEcoreDirection(): Vector {
    const repr = this.strucElem.definition;
    const xVec = repr.points[1];
    // const dist = repr.points.length > 1 ? vectorDist2D(repr.points[0], repr.points[1]) : 0;
    // const direction = rotateVector({ x: 0, y: 0, z: dist }, repr.rotation.x, repr.rotation.y, repr.rotation.z);
    // FGM: No rounding.
    return getEcoreVector3(xVec);
  }
}
