import { ViewportAction } from "lib/events/viewports";
import { GraphicProcessor } from "lib/graphic-processor";
import { IBox } from "lib/math/box";
import { IObjData } from "lib/models/objdata";
import { SelectionBox } from "./select-box";
import { RectangleSelectionHelper } from "./rectangle-helper";
import { preSelector } from "./pre-selection";
import { isSelectButtonClicked, isSelectButtonPressed } from "lib/mouse-settings";

export class RectangleSelector {

  private graphicProcessor: GraphicProcessor;

  private preSelect: preSelector;
  private rectangleHelper: RectangleSelectionHelper;
  private selectionBox: SelectionBox;

  private viewPortContainer: HTMLElement;
  private isSelecting: boolean = false;

  private resultsCb: (objDatas: IObjData[], bbox?: IBox) => void | undefined;

  constructor(graphicProcessor: GraphicProcessor, cb?: (objDatas: IObjData[]) => void) {
    this.preSelect = new preSelector(graphicProcessor);
    if (cb) {
      this.graphicProcessor = graphicProcessor;
      this.resultsCb = cb;

      const viewport = graphicProcessor.getActiveViewport();
      this.rectangleHelper = new RectangleSelectionHelper(viewport);

      const camera = viewport.camera!;
      this.selectionBox = new SelectionBox(camera, graphicProcessor);

      this.viewPortContainer = viewport.elemHTML!;
      this.registerSelectionRectangleActions();
      this.graphicProcessor.viewportObserver.subscribe(this.onChangeViewport);
    }
  }

  unregister() {
    this.preSelect.unregisterMouseMoveEvent();
    if (this.resultsCb !== undefined) {
      this.unregisterSelectionRectangleActions();
      this.graphicProcessor.viewportObserver.unsubscribe(this.onChangeViewport);
      this.rectangleHelper.unregister();
    }
  }

  unPreSelectData(data: IObjData) {
    this.preSelect.unPreSelectData(data);
  }

  private onChangeViewport = (action: ViewportAction) => {
    this.rectangleHelper.onChangeViewport(action);
    if (!this.isSelecting) {
      this.unregisterSelectionRectangleActions();
      const viewport = action.payload.viewport;
      this.viewPortContainer = viewport.elemHTML!;
      this.registerSelectionRectangleActions();
      this.selectionBox.camera = viewport.camera!;
    }
  }

  private onMouseDown = (event: PointerEvent) => {
    event.preventDefault();
    if (isSelectButtonClicked(event)) {
      this.rectangleHelper.onMouseDown(event);
      this.isSelecting = true;
      this.selectionBox.startPoint = this.eventToScreenCoord(event);
      this.selectionBox.endPoint = this.selectionBox.startPoint;
    }
  }

  private onMouseMove = (event: PointerEvent) => {
    event.preventDefault();
    if (isSelectButtonPressed(event)) {
      this.rectangleHelper.onMouseMove(event);
    }
  }

  private onMouseUp = (event: PointerEvent) => {
    event.preventDefault();
    if (isSelectButtonClicked(event)) {
      this.rectangleHelper.onMouseUp(event);
      if (this.isSelecting) {
        this.selectionBox.endPoint = this.eventToScreenCoord(event);
        this.selectionBox.intersect = this.rectangleHelper.isLeft;

        const allSelected = this.selectionBox.select();
        if (this.resultsCb) {
          const bbox = this.selectionBox.intersect ? this.selectionBox.bbox : undefined;
          this.resultsCb(allSelected, bbox);
        }
        this.isSelecting = false;
        this.selectionBox.startPoint = undefined;
        this.selectionBox.endPoint = undefined;
        this.preSelect.enable();
      }
    }
  }

  private eventToScreenCoord(event: PointerEvent) {
    const rec = this.viewPortContainer.getBoundingClientRect();
    const x = event.clientX - rec.left;
    const y = event.clientY - rec.top;
    const xx = (2 * x) / rec.width - 1;
    const yy = 1 - (2 * y) / rec.height;
    return { x: xx, y: yy, z: 0.5 };
  }


  private registerSelectionRectangleActions() {
    this.viewPortContainer.addEventListener("pointerdown", this.onMouseDown);
    this.viewPortContainer.addEventListener("pointermove", this.onMouseMove);
    document.addEventListener("pointerup", this.onMouseUp);
  }

  private unregisterSelectionRectangleActions() {
    this.viewPortContainer.removeEventListener("pointerdown", this.onMouseDown);
    this.viewPortContainer.removeEventListener("pointermove", this.onMouseMove);
    document.removeEventListener("pointerup", this.onMouseUp);
  }

} // class RectangleSelector
