import { isOutsideInput } from "lib/helpers/dom";
import { isRotateButtonClicked, isSelectButtonClicked } from "lib/mouse-settings";
import { GraphicProcessor } from "../graphic-processor";

/** Manager mouse and keyboard events linked to graphicContext
 *
 * @export
 * @class EventManager
 */
export class EventManager {

  private mouseEventManager: MouseEventManager;
  private keyBoardEventManager: keyBoardEventsManager;

  constructor(graphicProcessor: GraphicProcessor) {
    this.mouseEventManager = new MouseEventManager(graphicProcessor);
    this.keyBoardEventManager = new keyBoardEventsManager();
  }

  public connectMouseMainUpEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.connectMouseMainUpEvent(fun);
  }

  public disconnectMouseMainUpEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.disconnectMouseMainUpEvent(fun);
  }

  public connectMouseMainDownEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.connectMouseMainDownEvent(fun);
  }

  public disconnectMouseMainDownEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.disconnectMouseMainDownEvent(fun);
  }

  public connectMouseSecondUpEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.connectMouseSecondUpEvent(fun);
  }

  public disconnectMouseSecondUpEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.disconnectMouseSecondUpEvent(fun);
  }

  public connectMouseMoveEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.connectMouseMoveEvent(fun);
  }

  public disconnectMouseMoveEvent(fun: (ev: PointerEvent) => void) {
    this.mouseEventManager.disconnectMouseMoveEvent(fun);
  }

  public connectKeyEvent(fun: (e: KeyboardEvent) => void) {
    this.keyBoardEventManager.connectKeyEvent(fun);
  }

  public connectCustomKeyEvent(fun: () => void, key: string) {
    this.keyBoardEventManager.connectCustomKeyEvent(fun, key);
  }

  public disconnectKeyEvent(fun: (e: KeyboardEvent) => void) {
    this.keyBoardEventManager.disconnectKeyEvent(fun);
  }

} // class EventManager


class MouseEventManager {
  private timeStart = 0;

  private mouseDownMainEvents: Array<(ev: PointerEvent) => void> = [];
  private mouseDownSecondEvents: Array<(ev: PointerEvent) => void> = [];
  private mouseUpMainEvents: Array<(ev: PointerEvent) => void> = [];
  private mouseUpSecondEvents: Array<(ev: PointerEvent) => void> = [];
  private mouseMoveEvents: Array<(ev: PointerEvent) => void> = [];

  private graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  private registerMouseDown(): void {
    const rend = this.graphicProcessor.container;
    rend.addEventListener("pointerdown", this.handleMouseDown);
  }

  private unRegisterMouseDown(): void {
    const rend = this.graphicProcessor.getRenderer();
    rend.domElement.removeEventListener("pointerdown", this.handleMouseDown);
  }

  private handleMouseDown = (ev: PointerEvent): void => {
    this.timeStart = performance.now();
    if (isSelectButtonClicked(ev)) {
      for (const dispatchEvent of this.mouseDownMainEvents) {
        dispatchEvent(ev);
      }
    } else if (isRotateButtonClicked(ev)) {
      for (const dispatchEvent of this.mouseDownSecondEvents) {
        dispatchEvent(ev);
      }
    }
  };

  public connectMouseMainDownEvent(fun: (ev: PointerEvent) => void) {
    if (this.mouseDownMainEvents.length + this.mouseDownSecondEvents.length === 0) {
      this.registerMouseDown();
    }
    this.mouseDownMainEvents.push(fun);
  }

  public disconnectMouseMainDownEvent(fun: (ev: PointerEvent) => void) {
    const funIndex = this.mouseDownMainEvents.indexOf(fun);
    this.mouseDownMainEvents.splice(funIndex, 1);
    if (this.mouseDownMainEvents.length + this.mouseDownSecondEvents.length === 0) {
      this.unRegisterMouseDown();
    }
  }

  public connectMouseSecondDownEvent(fun: () => void) {
    if (this.mouseDownMainEvents.length + this.mouseDownSecondEvents.length === 0) {
      this.registerMouseDown();
    }
    this.mouseDownSecondEvents.push(fun);
  }

  public disconnectMouseSecondDownEvent(fun: () => void) {
    const funIndex = this.mouseDownSecondEvents.indexOf(fun);
    this.mouseDownSecondEvents.splice(funIndex, 1);
    if (this.mouseDownMainEvents.length + this.mouseDownSecondEvents.length === 0) {
      this.unRegisterMouseDown();
    }
  }

  public connectMouseMainUpEvent(fun: (ev: PointerEvent) => void) {
    if (this.mouseUpMainEvents.length + this.mouseUpSecondEvents.length === 0) {
      this.registerMouseDown();
      this.registerMouseUp();
    }
    this.mouseUpMainEvents.push(fun);
  }

  public disconnectMouseMainUpEvent(fun: (ev: PointerEvent) => void) {
    const funIndex = this.mouseUpMainEvents.indexOf(fun);
    this.mouseUpMainEvents.splice(funIndex, 1);
    if (this.mouseUpMainEvents.length + this.mouseUpSecondEvents.length === 0) {
      this.unRegisterMouseDown();
      this.unRegisterMouseUp();
    }
  }

  public connectMouseSecondUpEvent(fun: (ev: PointerEvent) => void) {
    if (this.mouseUpMainEvents.length + this.mouseUpSecondEvents.length === 0) {
      this.registerMouseDown();
      this.registerMouseUp();
    }
    this.mouseUpSecondEvents.push(fun);
  }

  public disconnectMouseSecondUpEvent(fun: (ev: PointerEvent) => void) {
    const funIndex = this.mouseUpSecondEvents.indexOf(fun);
    this.mouseUpSecondEvents.splice(funIndex, 1);
    if (this.mouseUpMainEvents.length + this.mouseUpSecondEvents.length === 0) {
      this.unRegisterMouseDown();
      this.unRegisterMouseUp();
    }
  }

  private registerMouseUp(): void {
    const rend = this.graphicProcessor.container;
    rend.addEventListener("pointerup", this.handleMouseUp);
  }

  private unRegisterMouseUp(): void {
    const rend = this.graphicProcessor.getRenderer();
    rend.domElement.removeEventListener("pointerup", this.handleMouseUp);
  }

  private handleMouseUp = (ev: PointerEvent): void => {
    const timeEnd = performance.now();
    const timeVar = timeEnd - this.timeStart;
    if (timeVar < 200) {
      if (isSelectButtonClicked(ev)) {
        for (const dispatchEvent of this.mouseUpMainEvents) {
          dispatchEvent(ev);
        }
      } else if (isRotateButtonClicked(ev)) {
        for (const dispatchEvent of this.mouseUpSecondEvents) {
          dispatchEvent(ev);
        }
      }
    }
    this.timeStart = 0;
  };

  public connectMouseMoveEvent(fun: (ev: PointerEvent) => void) {
    if (this.mouseMoveEvents.length === 0) {
      this.registerMouseMove();
    }
    this.mouseMoveEvents.push(fun);
  }

  public disconnectMouseMoveEvent(fun: (ev: PointerEvent) => void) {
    const funIndex = this.mouseMoveEvents.indexOf(fun);
    this.mouseMoveEvents.splice(funIndex, 1);
    if (this.mouseMoveEvents.length === 0) {
      this.unRegisterMouseMove();
    }
  }
  
  private registerMouseMove(): void {
    const rend = this.graphicProcessor.container;
    rend.addEventListener("pointermove", this.handleChangeLastPoint);
  }
  
  private unRegisterMouseMove(): void {
    const rend = this.graphicProcessor.getRenderer();
    rend.domElement.removeEventListener("pointermove", this.handleChangeLastPoint);
  }
  
  /**
   * Cada vez que movamos el raton desde esta fmc seran detonados todos los callbacks registrados sobre el evento
   * "pointermove", por medio de alguna llamada externa a la fmc connectMouseMoveEvent().
   *
   * @private
   * @param {PointerEvent} e
   * @memberof MouseEventManager
   */
  private handleChangeLastPoint = (e: PointerEvent) => {
    for (const dispatchEvent of this.mouseMoveEvents) {
      dispatchEvent(e);
    }
  };

} // class MouseEventManager


class keyBoardEventsManager {
  private keyBoardEvents: Array<(e: KeyboardEvent) => void> = [];
  private customKeyBoardEvents: Map<string, Array<(e: KeyboardEvent) => void>> = new Map();

  public connectKeyEvent(fun: (e: KeyboardEvent) => void) {
    const size = this.customKeyBoardEvents.size;
    if (this.keyBoardEvents.length + size === 0) {
      this.registerKeyPress();
    }
    this.keyBoardEvents.push(fun);
  }
  public connectCustomKeyEvent(fun: (e: KeyboardEvent) => void, key: string) {
    const size = this.customKeyBoardEvents.size;
    if (this.keyBoardEvents.length + size === 0) {
      this.registerKeyPress();
    }
    const event = this.customKeyBoardEvents.get(key);
    if (event) {
      event.push(fun);
    } else {
      this.customKeyBoardEvents.set(key, [fun]);
    }
  }
  public disconnectKeyEvent(fun: (e: KeyboardEvent) => void) {
    const funIndex = this.keyBoardEvents.indexOf(fun);
    if (funIndex !== -1) {
      this.keyBoardEvents.splice(funIndex, 1);
    }
    this.disconnectCustomKeyEvent(fun);

    const size = this.customKeyBoardEvents.size;
    if (this.keyBoardEvents.length + size === 0) {
      this.unRegisterKeyPress();
    }
  }

  private disconnectCustomKeyEvent(fun: (e: KeyboardEvent) => void) {
    for (const [key, val] of this.customKeyBoardEvents) {
      const funIndex = val.indexOf(fun);
      if (funIndex !== -1) {
        val.splice(funIndex, 1);
        if (val.length === 0) {
          this.customKeyBoardEvents.delete(key);
          break;
        }
      }
    }
  }
  private registerKeyPress(): void {
    document.addEventListener("keyup", this.handleKeyPress);
  }
  private unRegisterKeyPress(): void {
    document.removeEventListener("keyup", this.handleKeyPress);
  }
  private handleKeyPress = (e: KeyboardEvent) => {
    if (isOutsideInput(e)) {
      for (const dispatchEvent of this.keyBoardEvents) {
        dispatchEvent(e);
      }
      const keyBoarEvent = this.customKeyBoardEvents.get(e.key);
      if (keyBoarEvent) {
        for (const dispatchEvent of keyBoarEvent) {
          dispatchEvent(e);
        }
      }
    }
  };
}
