import { ProjectFilesApi } from "lib/apis/struc-project/api";
import { compressAndUploadToS3 } from "lib/apis/utils";
import { userMessageEvents } from "lib/events/user-messages";
import { beamCrossSectionCache } from "lib/models-struc/cross-sections-shape/beam-cross-sections/cache";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";
import { useParams } from "react-router-dom";
import { useAuth } from "shared/components/auth/context";

type resourceType = "pile_cap_templates" | "beam_cross_section" | "shell_cross_section";

export function useResources(resourceKey: resourceType) {

  const { id } = useParams<{ id: string }>();
  const { user } = useAuth();

  const updateResource2 = async () => {
    try {
      const fileProj = new ProjectFilesApi();
      const response = await fileProj.projectsUserIdIdRefreshGet(user!.id, id);
      if (resourceKey === "beam_cross_section") {
        const url = response.data.BeamCrossSections.Write;
        const bcss = beamCrossSectionCache.exportStyles();
        await compressAndUploadToS3(JSON.stringify(bcss), url);

      } else if (resourceKey === "shell_cross_section") {
        const url = response.data.ShellCrossSections.Write;
        const scss = shellCrossSectionCache.exportStyles();
        await compressAndUploadToS3(JSON.stringify(scss), url);

      } else if (resourceKey === "pile_cap_templates") {
        const url = response.data.PileCapTemplates.Write;
        const pilecaps = shellCrossSectionCache.exportStyles();
        await compressAndUploadToS3(JSON.stringify(pilecaps), url);
      }
    } catch (error) {
      console.log(error);
      userMessageEvents.dispatchError("Error updating resources");
    }
  };

  return {
    updateResource2,
  }
}

export const updateAllFileResource = async (userId: string, id: string) => {
  try {
    const fileProj = new ProjectFilesApi();
    const response = await fileProj.projectsUserIdIdRefreshGet(userId, id);

    const beamUrl = response.data.BeamCrossSections.Write;
    const bcss = beamCrossSectionCache.exportStyles();
    await compressAndUploadToS3(JSON.stringify(bcss), beamUrl);

    const shellUrl = response.data.ShellCrossSections.Write;
    const scss = shellCrossSectionCache.exportStyles();
    await compressAndUploadToS3(JSON.stringify(scss), shellUrl);

    const pileCapUrl = response.data.PileCapTemplates.Write;
    const pilecaps = shellCrossSectionCache.exportStyles();
    await compressAndUploadToS3(JSON.stringify(pilecaps), pileCapUrl);

  } catch (error) {
    console.log(error);
    userMessageEvents.dispatchError("Error updating resources");
    throw error;
  }
}

