import { rayCastResults } from "lib/coordinates/raycaster";
import { GraphicProcessor } from "lib/graphic-processor";
import { LayerData } from "lib/layers/layer-data";
import { LayerManager } from "lib/layers/layer-manager";
import { IObjData } from './objdata';
import { getObjNameFromType, objDataType } from "./types";
import { EventBus, ObserverManager } from '../events/event-bus';
import { EditActionType, editObjAction } from "lib/events/objectdata";
import { cadOpType } from "lib/operations/factory";
import { DimStyleBuilder } from "lib/dimension/style";
import { dimensionCache } from "lib/dimension/cache";
import { cacheAction, CacheActionType } from "lib/styles/style-cache";
import { isDimensionData } from "./checktools";
import { dataModelPersistence } from "lib/input-output/database/loader";

export class DataModelManager {

  public graphicProcessor: GraphicProcessor;

  private mapIdObjs: Map<string, IObjData> = new Map();
  public mapGraphicObjs: WeakMap<THREE.Object3D, IObjData> = new WeakMap();
  public mapGraphicObjsBulkData: WeakMap<THREE.Object3D, LayerData> = new WeakMap();

  public layerManager: LayerManager;

  private ObjDataObserver: ObserverManager<editObjAction> = new ObserverManager();

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
    const sceneMng = this.graphicProcessor.getSceneManager();
    this.layerManager = new LayerManager(sceneMng.rootLayer);
  }

  public connectEvents() {
    dimensionCache.subscribe(this.handleDimensionChangeStyle);
  }
  public disconnectEvents() {
    dimensionCache.unsubscribe(this.handleDimensionChangeStyle);
  }
  private handleDimensionChangeStyle = (action: cacheAction<DimStyleBuilder>) => {
    if (action.type === CacheActionType.UPDATE_CACHE) {
      const id = action.payload.id;
      const editedObj = [];
      for (const objData of this.iterAllData()) {
        if (isDimensionData(objData) && objData.definition.styleId === id) {
          objData.regenerateObjectFromDefinition();
          editedObj.push(objData);
        }
      }
      this.dispatchEditObjs(editedObj, cadOpType.CHANGESTYLE);
    }
  }

  public getModelInfo() {
    console.info("DATAMODEL INFO:");
    console.info("===============");
    console.info(" # Numero de capas: " + this.layerManager.getAllLayers().length);
    console.info(" # Numero de datos: " + this.mapIdObjs.size);
    console.info("===============");
    const setObjs: Map<objDataType, IObjData[]> = new Map();
    for (const obj of this.mapIdObjs.values()) {
      const objType = obj.type;
      if (setObjs.has(objType)) {
        setObjs.get(objType)!.push(obj);
      } else {
        setObjs.set(objType, [obj]);
      }
    }
    for (const [k, v] of setObjs) {
      console.info(`· ${v.length}\t-> ${getObjNameFromType(k)}`)
    }
    console.info("===============");
  }

  // -----------------------------------------------------

  public registerData(data: IObjData): void {
    this.mapIdObjs.set(data.id, data);
  }
  public registerGraphicData(data: IObjData): void {
    if (data.graphicObj) {
      this.mapGraphicObjs.set(data.graphicObj, data);
      const layerId = data.layerId;
      const layer = this.layerManager.getLayerDataFromId(layerId);
      if (layer) {
        const obj = layer.isBulkData ? layer.getlineOptimized() : data.graphicObj;
        this.mapGraphicObjsBulkData.set(obj as THREE.Object3D, layer);
      }
    }
  }
  public unregisterData(data: IObjData): void {
    this.mapIdObjs.delete(data.id);
    this.mapGraphicObjs.delete(data.graphicObj);
    this.mapGraphicObjsBulkData.delete(data.graphicObj);
  }
  public isRegister(data: IObjData): boolean {
    return this.mapIdObjs.has(data.id);
  }

  public getData(id: string) {
    const data = this.mapIdObjs.get(id);
    if (data) {
      return data;
    }
    return null;
  }
  public getDataFromGraphicId(graphicId: number) {
    for (const data of this.mapIdObjs.values()) {
      if (data.graphicObj.id === graphicId) return data;
    }
    return null;
  }
  public getDataFromGraphic(i: THREE.Intersection): rayCastResults | null {

    const getData = (objThree: THREE.Object3D): IObjData | null => {
      const data = this.mapGraphicObjs.get(objThree);
      if (data) return data;
      if (objThree.parent) {
        return getData(objThree.parent);
      }
      return null;
    };

    const data = getData(i.object);
    if (data && data.isVisible) {
      return { ...i, dataObject: data };
    } else {
      // Bulk data
      const layerData = this.mapGraphicObjsBulkData.get(i.object);
      if (layerData && i.index !== undefined) {
        const geometry = (i.object as THREE.LineSegments).geometry as THREE.BufferGeometry;
        if (layerData && i.index !== undefined && geometry.index) {
          const vertexIndx = geometry.index.getX(i.index);
          const data = layerData.getDataFromGraphicObject(vertexIndx);
          if (data) {
            return { ...i, dataObject: data };
          }
        } else {
          console.warn("[MODEL_DATA] getDataFromGraphic() obj grafico sin elance a objeto de modelo");
        }
      }
    }
    return null;
  }

  public *iterAllData(): IterableIterator<IObjData> {
    for (const data of this.mapIdObjs.values()) {
      yield data;
    }
  }
  public *iterDataByFilter<T extends IObjData>(filterFunc: (objData: IObjData) => objData is T): IterableIterator<T> {
    for (const data of this.mapIdObjs.values()) {
      if (filterFunc(data)) {
        yield data;
      }
    }
  }
  public iterAllDataFromLayers(layerIds: string[], cb: (data: IObjData) => void) {
    if (layerIds.length) {
      for (const layerId of layerIds) {
        this.layerManager.iterAllLayers((layerData: LayerData) => {
          for (const data of layerData.objDatas) {
            cb(data);
          }
        }, layerId);
      }
    } else {
      for (const data of this.mapIdObjs.values()) {
        cb(data);
      }
    }
  }

  public clearAll() {
    this.graphicProcessor.unselectAll();
    this.mapIdObjs.clear();
    this.mapGraphicObjs = new WeakMap();
    this.mapGraphicObjsBulkData = new WeakMap();
    this.layerManager.clear();
  }

  public exportDataModel(): dataModelPersistence[] {
    const objDatas: dataModelPersistence[] = [];
    for (const data of this.iterAllData()) {
      const dataObj = data.exportToJSON();
      objDatas.push(dataObj);
    }
    return objDatas;
  }

  // -----------------------------------------------------

  public showHideObjectData(objDataId: string, show: boolean) {
    const objData = this.getData(objDataId);
    if (objData) {
      objData.isDataVisible = show;
      objData.visibleGraphicObject = show;
      if (!show) {
        const selectionManager = this.graphicProcessor.getSelectionManager();
        selectionManager.unSelectObjData(objData);
      }
    }
  }
  public lockUnlockObjectData(objDataId: string, lock: boolean) {
    const objData = this.getData(objDataId);
    if (objData) {
      const selectionManager = this.graphicProcessor.getSelectionManager();
      selectionManager.unSelectObjData(objData);
      objData.isDataLocked = lock;
    }
  }

  // -----------------------------------------------------  

  public subscribe(listener: (action: editObjAction) => void) {
    this.ObjDataObserver.subscribe(listener);
  }
  public unsubscribe(listener: (action: editObjAction) => void) {
    this.ObjDataObserver.unsubscribe(listener);
  }
  public dispatchAddedObjs(objs: IObjData[]) {
    if (EventBus.enableDispatch) {
      this.ObjDataObserver.dispatch({
        type: EditActionType.ADD_OBJ, payload: {
          objsAdded: objs,
        }
      });
    }
  }
  public dispatchDeletedObjs(objs: IObjData[]) {
    if (EventBus.enableDispatch) {
      this.ObjDataObserver.dispatch({
        type: EditActionType.DELETE_OBJ, payload: {
          objsDeleted: objs,
        }
      });
    }
  }
  public dispatchEditObjs(objsEdited: IObjData[], opType: cadOpType) {
    if (EventBus.enableDispatch) {
      this.ObjDataObserver.dispatch({
        type: EditActionType.EDIT_OBJ, payload: {
          objsEdited,
          opType,
        }
      });
    }
  }
}
