import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcoreRectangle } from "../geometry";
import { ExtrudedRepresentation, GeoRepresentation } from "modules/struc/models/ecore/representation";
import { Vector as EcoreVector } from "modules/struc/models/ecore/location";
import { Rectangle as EcoreRectangle } from "modules/struc/models/ecore/geometry";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { baseUriModel } from "../mesh-exporter";
import { Footing } from "modules/struc/models/ecore/structural";
import { generateRectangularSection } from "lib/models-struc/cross-sections-shape/beam-cross-sections/utils";
import { FooterData } from "lib/models/structural/footer";
import { FEMStructuralElement } from "modules/struc/models/ecore/mesh";
import { representationUriModel } from "modules/struc/models/ecore/uri";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";


export class EcoreFooterElem extends EcoreStrucElemBase {
  public exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement {
    // TODO: throw new Error("Method not implemented.");
    return undefined!;
  }

  private strucElem: FooterData;

  public setStrucElem(strElem: FooterData) {
    this.strucElem = strElem;
  }
  public exportToEcore(materialRef: number): Footing {
    const repr = this.strucElem.definition;
    return {
      eClass: baseUriModel + "structural/Footing",
      id: this.strucElem.id,
      name: repr.name,
      material: this.getEcoreMaterialRef(materialRef),
      placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
      representation: this.getRepresentation(),
    }
  }
  private getRepresentation(): ExtrudedRepresentation<GeoRepresentation<EcoreRectangle>> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: this.getEcoreGeometryRectangleColumn()
      },
    }
  }
  public getEcoreDirection(): EcoreVector {
    const repr = this.strucElem.definition;
    const section = shellCrossSectionCache.loadStylefromCache(repr.shellSectionId)!;
    const direction = { x: 0, y: 0, z: -section.thickness };
    // FGM: No rounding.
    return getEcoreVector3(direction);
  }
  private getEcoreGeometryRectangleColumn(): EcoreRectangle {
    const { width, length } = this.strucElem.definition;
    const sectionPoints = generateRectangularSection(length, width);
    return getEcoreRectangle(sectionPoints[0], sectionPoints[2]);
  }
}
