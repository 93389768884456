// \file singleton.ts
// Creamos una clase templatizada Singleton<T> para implementar el patron de diseño.

/**
 * Singleton generico.
 * WARNING: Aunque el compilador de TS nos avise en la declaracion mas abajo con el mensaje de warning:
 * 'T' is defined but never used, es necesario incluir el <T> para su uso en las clases derivadas.
 *
 * @export
 * @class Singleton
 * @template T
 */
export default class Singleton<T> {

    // La unica instancia existente, pero no puede ser de tipo T ya que da el error:
    // "Static members cannont reference class type parameters". Asi que la hacemos any.
    private static _instance: any = null;

    public static createInstance<T>(theClass: { new(): T; }): T {
        if (this._instance === null) {
            this._instance = new theClass();
        }

        return this._instance;
    }

    public static get instance() {
        return this._instance;
    }

    public static getInstance<T>(): T {
        return this._instance as T;
    }

} // class Singleton<T>