import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { LayerAction, LayerActionType } from "lib/events/layers";
import { LayerData } from "lib/layers/layer-data";
import { LayerManager } from '../../../../../lib/layers/layer-manager';
import { GraphicProcessor } from '../../../../../lib/graphic-processor';
import { CompositeLeaf, ICompositeComponent } from "lib/helpers/composite-tree";
import { NodeData } from "shared/components/ui/tree/tree";
import { IObjData } from "lib/models/objdata";
import { LayerChangeVisibility } from "lib/layers/application/layer-visibility";
import { LayerChangeLock } from "lib/layers/application/layer-lock";

interface LayerState {
  currentNodeTree: string;
  layerTree: ICompositeComponent<LayerData | IObjData>;
}

function getTreeLayers(showObjects: boolean, lyrMng: LayerManager): ICompositeComponent<any> {
  function clone(node: ICompositeComponent<LayerData>, parent?: ICompositeComponent<LayerData>) {
    const cloned = node.cloneNode();
    if (parent) {
      parent.children?.push(cloned);
      cloned.parent = parent;
    }
    if (node.children) {
      for (const childNode of node.children) {
        clone(childNode, cloned);
      }
      if (showObjects) {
        const lyrObjs = node.props.objDatas
        for (const objs of lyrObjs) {
          const objDataNode = new CompositeLeaf<IObjData>(objs.objName, objs);
          objDataNode.id = objs.id;
          cloned.children?.push(objDataNode);
          objDataNode.parent = cloned;
        }
      }
    }
    return cloned;
  }
  const rootNode = clone(lyrMng.layerTree);
  return rootNode;
}

function layerReducer(state: LayerState, action: LayerAction, showObjects: boolean): LayerState {
  switch (action.type) {
    case LayerActionType.SET_CURRENT_LAYER:
      return {
        ...state,
        currentNodeTree: action.payload.currentLayer.id,
      };
    case LayerActionType.LOAD_LAYERS:
    case LayerActionType.DELETE_LAYER:
      return {
        layerTree: getTreeLayers(showObjects, action.payload.layerManager),
        currentNodeTree: action.payload.layerManager.currentLayer.id,
      };
    case LayerActionType.CLEAR_LAYER:
    case LayerActionType.ADD_LAYER:
    case LayerActionType.DRAG_LAYERS:
    case LayerActionType.DRAG_OBJECT2LAYER:
    case LayerActionType.ADD_OBJECT2LAYER:
      return {
        ...state,
        layerTree: getTreeLayers(showObjects, action.payload.layerManager),
      };
    case LayerActionType.VISIBILITY_LAYERS:
    case LayerActionType.LOCK_LAYER:
      return state;
    default:
      throw new Error(`Action is not defined.`);
  }
}

export default function useLayers(graphicProcessor: GraphicProcessor) {

  const [showObjects, setShowObjects] = useState(false);

  const expandedKeys = useRef<string[]>([]);
  const scrollOffset = useRef<{ offset: number }>({ offset: 0 });

  const [state, dispatch] = useReducer(reducer, {
    currentNodeTree: graphicProcessor.getLayerManager().currentLayer.id,
    layerTree: graphicProcessor.getLayerManager().layerTree,
  });

  useEffect(() => {
    const lyrMngr = graphicProcessor.getLayerManager();
    lyrMngr.layerObserver.subscribe(dispatch);
    lyrMngr.iterAllLayers((lyr) => expandedKeys.current.push(lyr.id));
    return () => {
      graphicProcessor.getLayerManager().layerObserver.unsubscribe(dispatch);
    }
  }, [graphicProcessor]);

  useEffect(() => {
    graphicProcessor.getLayerManager().layerObserver.dispatchLoadLayers();
  }, [graphicProcessor, showObjects])

  function reducer(state: LayerState, action: LayerAction): LayerState {
    return layerReducer(state, action, showObjects);
  }

  const toggleVisibility = (node: NodeData<LayerData | IObjData>, onOff: boolean) => {
    if (!node.isLeaf) {
      const action = new LayerChangeVisibility(graphicProcessor);
      action.run(node.id, onOff);
    } else {
      const mdlmang = graphicProcessor.getDataModelManager();
      mdlmang.showHideObjectData(node.id, onOff);
    }
  };
  const toggleLayerLock = (node: NodeData<LayerData | IObjData>, onOff: boolean) => {
    if (!node.isLeaf) {
      const action = new LayerChangeLock(graphicProcessor);
      action.run(node.id, onOff);
    } else {
      const mdlmang = graphicProcessor.getDataModelManager()
      mdlmang.lockUnlockObjectData(node.id, onOff);
    }
  };
  const setCurrentNode = (node: NodeData<LayerData | IObjData>) => {
    if (!node.isLeaf) {
      // Select layer
      const lyrMng = graphicProcessor.getLayerManager();
      lyrMng.setCurrentLayerById(node.id);
      const slctnMngr = graphicProcessor.getSelectionManager();
      slctnMngr.dispatchSelectedObjects();
    } else {
      // Select Object
      const mdlmang = graphicProcessor.getDataModelManager()
      const objData = mdlmang.getData(node.id);
      if (objData) {
        const slctnMngr = graphicProcessor.getSelectionManager();
        if (slctnMngr.canBeSelected(objData)) {
          slctnMngr.unselectAll();
          slctnMngr.selectObjData(objData);
        }
        slctnMngr.dispatchSelectedObject(objData);
        const lyrMng = graphicProcessor.getLayerManager();
        lyrMng.setCurrentLayerById(objData.layerId);
      }
    }
  };
  const dragObject = (sourceNode: NodeData<LayerData | IObjData>, targetLayer: NodeData<LayerData | IObjData>) => {
    if (targetLayer.isLeaf) return
    if (!sourceNode.isLeaf) {
      // Drag and Drop layer
      const lyrMng = graphicProcessor.getLayerManager();
      lyrMng.moveLayer2Layer(sourceNode.id, targetLayer.id);
    } else {
      // Drag and Drop ObjData
      addObjectToLayer(sourceNode.id, targetLayer.id);
    }
  };
  const addObjectToLayer = (objectId: string, layerId: string) => {
    const objectDM = graphicProcessor.getDataModelManager().getData(objectId);
    if (objectDM) {
      const lyrMng = graphicProcessor.getLayerManager();
      lyrMng.moveObjData2Layer(objectDM, layerId)
    }
  };

  const getLayerList = useMemo(() => {
    const lyrMng = graphicProcessor.getLayerManager();
    return lyrMng.getAllLayers();
  }, [state.layerTree]);

  return {
    layerTree: state.layerTree,
    currentLayer: state.currentNodeTree,
    expandedKeys,
    scrollOffset,

    setCurrentNode,
    toggleVisibility,
    toggleLayerLock,
    dragObject,
    addObjectToLayer,

    setShowObjects,
    getLayerList,
  };
}
