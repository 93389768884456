
import { GraphicProcessor } from "lib/graphic-processor";
import { Site, Project } from "lib/models-struc/struc-project";
import { Storey } from "lib/models-struc/struc-storey";
import { Building } from "lib/models-struc/struc-building";
import { IStrucElementData, SupportElementTypes } from "lib/models/structural/structural";
import { StrucSite, StrucBuilding, StrucStorey, StrucProject } from "modules/struc/models/project";
import { LoadStructuralData } from "lib/models/structural/load";
import { isSupportData } from "lib/models/checktools";

export class StrucDataImporter {

  protected graphicProcessor: GraphicProcessor;

  constructor(graphicProcessor: GraphicProcessor) {
    this.graphicProcessor = graphicProcessor;
  }

  openProjectStruct(projectData: StrucProject) {
    if (projectData) {
      const proj = new Project();
      proj.id = projectData.id;
      proj.name = projectData.name;
      proj.remarks = projectData.remarks;
      proj.city = projectData.city;
      proj.country = projectData.country;

      proj.steel_type = projectData.steel_type;
      proj.steel_standard = projectData.steel_standard;
      proj.concrete_code = projectData.concrete_code;          // "EHE-08"
      proj.concrete_standard = projectData.concrete_standard;  // "EHE-08"
      proj.load_code = projectData.load_code;                  // "CTE-DB-SE-AE"

      const sites = this.getStrucSites(projectData.sites);
      proj.addSites(sites);

      const projMng = this.graphicProcessor.getProjectModelManager();
      projMng.addProject(proj);
    }
  }

  private getStrucSites(siteDatas: StrucSite[]): Site[] {
    const result: Site[] = [];
    if (siteDatas) {
      for (let siteData of siteDatas) {
        const site = new Site();
        site.id = siteData.id;
        site.name = siteData.name;
        site.remarks = siteData.remarks;

        const buildings = this.getStrucBuildings(siteData.buildings);
        site.addBuildings(buildings);

        result.push(site);
      }
    }
    return result;
  }

  private getStrucBuildings(buildingDatas: StrucBuilding[]): Building[] {
    const result: Building[] = [];
    if (buildingDatas) {
      for (let buildingData of buildingDatas) {
        const building = new Building();
        building.id = buildingData.id;
        building.name = buildingData.name;
        building.remarks = buildingData.remarks;

        const storeys = this.getStrucStoreys(buildingData.struc_storeys);
        building.addStoreys(storeys);

        const strucMng = this.graphicProcessor.getStructuralModelManager();
        strucMng.setCurrBuilding(building);
        this.loadStrucElements(buildingData.struc_elements);
        this.loadLoadElements(buildingData.load_elements, buildingData.struc_elements);
        this.loadStrucLinkedObjects(buildingData.struc_elements);
        this.loadStrucCompoundElements(buildingData.compound_columns);
        this.loadStrucCompoundElements(buildingData.compound_walls);
        // Recreate next element to generate next automatic structural element name
        building.recreateNextElemNames(buildingData.struc_counters);

        this.checkNumSupportElements(building, buildingData);

        result.push(building);
      }
    }
    return result;
  }

  private checkNumSupportElements(building: Building, buildingData: StrucBuilding) {
    let buildCol = 0;
    let builWall = 0;
    for (const storey of building.storeys) {
      buildCol += storey.columns.length;
      builWall += storey.walls.length;
    }

    const { compound_columns, compound_walls } = buildingData;
    let numcol = 0;
    for (const strucElemIds in compound_columns) {
      numcol += compound_columns[strucElemIds].length;
    }
    console.assert(numcol === buildCol, `Numero de columnas ${buildCol} != a Compound group ${numcol}`);
    let numWall = 0;
    for (const strucElemIds in compound_walls) {
      numWall += compound_walls[strucElemIds].length;
    }
    console.assert(numWall === builWall, `Numero de muros ${builWall} != a Compound group ${numWall}`);
  }

  private getStrucStoreys(struc_storeys: StrucStorey[]): Storey[] {
    const storeys: Storey[] = [];
    if (struc_storeys) {
      for (let storey of struc_storeys) {
        const stry = new Storey();
        stry.setFromStrucStorey(storey);
        storeys.push(stry);
      }
    }
    return storeys;
  }

  private loadStrucElements(struc_elements_id: string[]) {
    const modelMng = this.graphicProcessor.getDataModelManager();
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    for (let id of struc_elements_id) {
      const strucData = modelMng.getData(id) as IStrucElementData;
      if (strucData) strucMng.registerStrucData(strucData);
    }
  }
  private loadLoadElements(load_elements_id: string[] = [], struc_elements: any[]) {
    const modelMng = this.graphicProcessor.getDataModelManager();
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    for (let id of load_elements_id) {
      const loadData = modelMng.getData(id) as LoadStructuralData;
      const parentData = modelMng.getData(loadData.definition.parentStructElemId) as IStrucElementData;
      loadData.parentStrucElem = parentData;
      parentData.loads.push(loadData);
      strucMng.registerLoadData(loadData);
    }
  }

  private loadStrucLinkedObjects(struc_elements_id: string[]) {
    const strucMng = this.graphicProcessor.getStructuralModelManager();
    for (let id of struc_elements_id) {
      const elem = strucMng.getStructElemFromId(id)!;
      if (elem && elem.definition.lnkObjIds) {
        for (let objId of elem.definition.lnkObjIds) {
          const linkedObj = strucMng.getStructElemFromId(objId)!;
          const strucElem = strucMng.getStructElemFromId(elem.id)!;
          if (linkedObj && strucElem) strucElem.addLinkedObject(linkedObj);
        }
      }
    }
  }

  private loadStrucCompoundElements(compounds: Record<string, string[]> | undefined) {
    if (compounds) {
      const strucMng = this.graphicProcessor.getStructuralModelManager();
      for (let [compoundName, strucElemIds] of Object.entries(compounds)) {
        const strucElems: SupportElementTypes[] = [];
        for (let objId of strucElemIds) {
          const compoundElem = strucMng.getStructElemFromId(objId);
          if (compoundElem && isSupportData(compoundElem)) {
            strucElems.push(compoundElem);
          }
        }
        if (strucElems.length) {
          strucMng.currBuilding.addCompoundElements(compoundName, strucElems);
        }
      }
    }
  }
}