import { isEqual, isZero } from "lib/math/epsilon";
import { IPoint } from "lib/math/types";
import { Placement, Quaternion as EcoreQuaternion, Vector } from "modules/struc/models/ecore/location";
import { Quaternion } from '../../../math/rotate';
import { locationUriModel } from "modules/struc/models/ecore/uri";

/**
 * Devuelve un objeto de tipo "Vector" (2D) con el formato Ecore. En caso de que "doRounding" sea verdadero someteremos
 * a los valores del vector a un redondeo a decimas de milimetro (0.0001 m).
 * @param point 
 * @param doRounding 
 * @returns 
 */
export function getEcoreVector2(point: IPoint, doRounding = false): Vector {
  const vector: Vector = { eClass: `${locationUriModel}Vector` };
  if (!isZero(point.x)) {
    vector.x = point.x;
  }
  if (!isZero(point.y)) {
    vector.y = point.y;
  }

  if (doRounding) {
    // Redondeo con precision hasta decima de milimetro, es decir 4 decimales.
    if (vector.x) {
      vector.x = 0.0001 * Math.round((vector.x + Number.EPSILON) * 10000);
    }
    if (vector.y) {
      vector.y = 0.0001 * Math.round((vector.y + Number.EPSILON) * 10000);
    }
  }

  return vector;
}

/**
 * Devuelve un objeto de tipo "Vector" (3D) con el formato Ecore. En caso de que "doRounding" sea verdadero someteremos
 * a los valores del vector a un redondeo a decimas de milimetro (0.0001 m).
 * @param point 
 * @param doRounding 
 * @returns 
 */
export function getEcoreVector3(point: IPoint, doRounding = false): Vector {
  const vector = getEcoreVector2(point);
  if (!isZero(point.z)) {
    vector.z = point.z;
  }

  if (doRounding) {
    // Redondeo con precision hasta decima de milimetro, es decir 4 decimales.
    if (vector.x) {
      vector.x = 0.0001 * Math.round((vector.x + Number.EPSILON) * 10000);
    }
    if (vector.y) {
      vector.y = 0.0001 * Math.round((vector.y + Number.EPSILON) * 10000);
    }
    if (vector.z) {
      vector.z = 0.0001 * Math.round((vector.z + Number.EPSILON) * 10000);
    }
  }

  return vector;
}

function getEcoreRotation(rot: Quaternion): EcoreQuaternion {
  const rotation: EcoreQuaternion = { eClass: `${locationUriModel}Quaternion` };
  if (!isZero(rot.x)) { rotation.x = rot.x; }
  if (!isZero(rot.y)) { rotation.y = rot.y; }
  if (!isZero(rot.z)) { rotation.z = rot.z; }
  if (!isEqual(rot.w, 1)) { rotation.w = rot.w; }
  return rotation;
}
const defQuaternion: Quaternion = { x: 0, y: 0, z: 0, w: 1 };

export function getEcorePlacement(point: IPoint, rotation: Quaternion = defQuaternion): Placement {
  return {
    eClass: `${locationUriModel}Placement`,
    // FGM: Let's round  for placement..
    base: getEcoreVector3(point, true),
    rotation: getEcoreRotation(rotation),
  };
}
