import { IPoint } from "../../math/types";
import { solidParam } from "../../models/solids/solid";
import { getAuxMeshBasicMaterial } from "lib/materials";
import * as THREE from "three";

export interface regionParam extends solidParam {
  ptos2D: IPoint[];
}

export function filledPolygon2DPoints(points: IPoint[], material?: THREE.MeshPhongMaterial): THREE.Mesh {
    const v = points.length > 0 ? points.map(p => new THREE.Vector2(p.x, p.y)) : undefined;
    const shape = new THREE.Shape(v);
    shape.autoClose = true;
    const geometry = new THREE.ShapeGeometry(shape);
    const mat = material ?? getAuxMeshBasicMaterial();
    const mesh = new THREE.Mesh(geometry, mat);
    mesh.geometry.computeBoundingBox();
    mesh.geometry.computeBoundingSphere();
    return mesh;
}

export function updateFilledPolygon2DPoints(mesh: THREE.Mesh, points: IPoint[]) {
    const shape = new THREE.Shape(points.map(p => new THREE.Vector2(p.x, p.y)));
    shape.autoClose = true;
    mesh.geometry = new THREE.ShapeGeometry(shape);
}