import { useOperation } from "../../operations/context";
import ToolbarButton from "../toolbar-button";
import ToolbarSection from "../toolbar-section";
import MoveIcon from "../../icons/move";
import CopyIcon from "../../icons/copy";
import RotateIcon from "../../icons/rotate";
import TrimIcon from "../../icons/trim";
import ScaleIcon from "../../icons/scale";
import MirrorIcon from "../../icons/mirror";
import ExtendIcon from "../../icons/extend";
import { cadOpType } from "lib/operations/factory";
import OffsetIcon from "../../icons/offset";
import ChamferIcon from "../../icons/chamfer";
import MatrixIcon from "../../icons/matrix";

const EditionSection = () => {
  const {
    move,
    copy,
    rotate,
    trim,
    extend,
    stretch,
    scale,
    mirror,
    launchOperation,
  } = useOperation();
  return (
    <ToolbarSection title="Edition"  className={"border-b border-gray-600"}>
      <ToolbarSection.Row>
        <ToolbarButton onClick={move} tooltip="Move">
          <MoveIcon />
        </ToolbarButton>
        <ToolbarButton onClick={copy} tooltip="Copy">
          <CopyIcon />
        </ToolbarButton>
        <ToolbarButton onClick={mirror} tooltip="Mirror">
          <MirrorIcon />
        </ToolbarButton>
        <ToolbarButton onClick={rotate} tooltip="Rotate">
          <RotateIcon />
        </ToolbarButton>
        <ToolbarButton onClick={scale} tooltip="Scale">
          <ScaleIcon />
        </ToolbarButton>
        <ToolbarButton onClick={trim} tooltip="Trim">
          <TrimIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.CHAMFER)}
          tooltip="Chamfer">
          <ChamferIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.ALIGN)}
          tooltip="Align">
          <ChamferIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.OFFSETCORRIDOR, true)}
          tooltip="Offset">
          <OffsetIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.MATRIX, true)}
          tooltip="Matrix">
          <MatrixIcon />
        </ToolbarButton>
        <ToolbarButton onClick={extend} tooltip="Extend">
          <ExtendIcon />
        </ToolbarButton>
        <ToolbarButton
          onClick={() => launchOperation(cadOpType.MATCHPROP)}
          tooltip="MatchProp">
        </ToolbarButton>
        <ToolbarButton onClick={stretch} tooltip="Stretch">
          <ExtendIcon />
        </ToolbarButton>
      </ToolbarSection.Row>
    </ToolbarSection>
  );
};

export default EditionSection;
