import { getEcorePlacement, getEcoreVector3 } from "../locations";
import { getEcoreOffsetGeometry, getEcoreLineSegment } from "../geometry";
import { baseUriModel } from "../mesh-exporter";
import { wallParam, wallTypes } from "lib/models-struc/types/wall";
import { ExtrudedRepresentation, GeoRepresentation, Representation as EcoreRepresentation } from "modules/struc/models/ecore/representation";
import { Vector as EcoreVector } from "modules/struc/models/ecore/location";
import { EcoreStrucElemBase } from "../struc-elem-base";
import { Wall } from "modules/struc/models/ecore/structural";
import { addIpoint, mulIpoint } from "lib/math/point";
import { FEMSEComp, FEMStructuralElement, ShellStructuralElement } from "modules/struc/models/ecore/mesh";
import { iterPolylineEdges } from "lib/math/line";
import { IPoint } from "lib/math/types";
import { WallData } from "lib/models/structural/wall";
import { LineSegment, OffsetGeometry } from "modules/struc/models/ecore/geometry";
import { representationUriModel, structuralUriModel } from "modules/struc/models/ecore/uri";
import { shellCrossSectionCache } from "lib/models-struc/cross-sections-shape/shell-cross-sections/cache";

export class EcoreWallElem extends EcoreStrucElemBase {

  private strucElem: WallData;
  private crossSectionRef: number;

  async setStrucElem(strElem: WallData, crossSectionShapesIds: string[]) {
    this.strucElem = strElem;
    const repr = this.strucElem.definition;
    this.crossSectionRef = crossSectionShapesIds.indexOf(repr.shellCrossSectionId);
  }

  exportToEcore(materialRef: number): Wall {
    const repr = this.strucElem.definition;
    const retaining = (repr.wallType === wallTypes.RETAINING);
    const wall: Wall = {
      eClass: `${structuralUriModel}Wall`,
      id: this.strucElem.id,
      name: repr.name,
      material: this.getEcoreMaterialRef(materialRef),
      placement: getEcorePlacement({ ...repr.basePoint, z: 0 }),
      representation: this.getRepresentation(),
      css: this.getEcoreCrossSectionRef(this.crossSectionRef),
    }
    const isRetaining = this.getEcoreIsRetainingWall(retaining);
    if (isRetaining !== undefined) wall.isRetaining = isRetaining;
    return wall;
  }
  private getRepresentation(): ExtrudedRepresentation<GeoRepresentation<OffsetGeometry>> {
    const repr = this.strucElem.definition;
    const points = repr.ptos2D.points;
    if (repr.ptos2D.isClosed) points.push(points[0]);  
    const section = shellCrossSectionCache.loadStylefromCache(repr.shellCrossSectionId)!;

    const extruderRepr: ExtrudedRepresentation<GeoRepresentation<OffsetGeometry>> = {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcoreOffsetGeometry(points, repr.widthType, section.thickness),
      },
    }
    const substractions = this.getEcoreSubstractionsWall(repr);
    if (substractions) extruderRepr.substractions = substractions;
    return extruderRepr;
  }

  exportFemStructuralElementToEcore(storeyRef: number, elemRef: number): FEMStructuralElement | FEMSEComp {
    const repr = this.strucElem.definition;
    const orientations = this.getOrientationVectors();

    if (repr.stretch.length === 1) {
      const shellSE: ShellStructuralElement = {
        eClass: baseUriModel + "mesh/ShellStructuralElement",
        structuralelement: {
          eClass: baseUriModel + "structural/Wall",
          $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
        },
        section: {
          eClass: baseUriModel + "mesh/CrossSection",
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
        },
        orientation: orientations[0],
        representation: this.getFEMStrcElemRepresentation(repr.ptos2D.points[0], repr.ptos2D.points[1]),
      };
      return shellSE;
    }

    const shellStructElem: ShellStructuralElement[] = [];
    for (const edge of iterPolylineEdges(repr.ptos2D)) {
      const { p0, p1, i } = edge;
      const shellCS: ShellStructuralElement = {
        eClass: baseUriModel + "mesh/ShellStructuralElement",
        section: {
          eClass: baseUriModel + "mesh/CrossSection",
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          shape: this.getEcoreCrossSectionRef(this.crossSectionRef),
        },
        orientation: orientations[i],
        representation: this.getFEMStrcElemRepresentation(p0, p1),
      }
      shellStructElem.push(shellCS);
    }


    const femStr: FEMSEComp = {
      eClass: `${baseUriModel}mesh/FEMSEComp`,
      structuralelement: {
        eClass: baseUriModel + "structural/Wall",
        $ref: `//@versions.0/@building/@storeys.${storeyRef}/@elements.${elemRef}`,
      },
      elements: shellStructElem,
    }
    return femStr;
  }

  private getFEMStrcElemRepresentation(p0: IPoint, p1: IPoint): ExtrudedRepresentation<GeoRepresentation<LineSegment>> {
    return {
      eClass: `${representationUriModel}ExtrudedRepresentation`,
      placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
      direction: this.getEcoreDirection(),
      base: {
        eClass: `${representationUriModel}GeoRepresentation`,
        placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
        base: getEcoreLineSegment(p0, p1),
      },
      // TODO: manage HOLES substractions: this.getEcoreSubstractionsWall(repr),
    }
  }


  private getOrientationVectors(): EcoreVector[] {
    const repr = this.strucElem.definition;
    // FGM: No rounding.
    return repr.stretch.map(s => getEcoreVector3(mulIpoint(s.normal, s.orientation)));
  }

  getEcoreDirection(): EcoreVector {
    const repr = this.strucElem.definition;
    const direction = { x: 0, y: 0, z: -repr.height };
    // FGM: No rounding.
    return getEcoreVector3(direction);
  }

  private getEcoreSubstractionsWall(repr: wallParam): EcoreRepresentation[] | undefined {
    const substractions: EcoreRepresentation[] = [];
    const stretch = repr.stretch;
    const section = shellCrossSectionCache.loadStylefromCache(repr.shellCrossSectionId)!;
    for (let strtch of stretch) {
      for (const hole of strtch.holes) {
        const holePtos = hole.points.map(p => addIpoint(p, repr.basePoint));
        const ecoreHole: ExtrudedRepresentation<GeoRepresentation<OffsetGeometry>> = {
          eClass: `${representationUriModel}ExtrudedRepresentation`,
          placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
          // FGM: No rounding.
          direction: getEcoreVector3(strtch.normal),
          base: {
            eClass: `${representationUriModel}GeoRepresentation`,
            placement: getEcorePlacement({ x: 0, y: 0, z: 0 }),
            base: getEcoreOffsetGeometry(holePtos, repr.widthType, section.thickness),
          },
        }
        substractions.push(ecoreHole);
      }
    }
    if (substractions.length) {
      return substractions;
    }
  }

  private getEcoreIsRetainingWall(isRetaining: boolean) {
    if (isRetaining) {
      return isRetaining;
    }
  }
}
