import { AddConsolidation, dumpCompoundElements } from "lib/models-struc/checkers.ts/struc-consolidate";
import { ColumnCompoundElement, WallCompoundElement } from "lib/models-struc/compound-element";
import { hypothesisManager } from "lib/models-struc/hypothesis/hypothesismodel-manager";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { isLoadData } from "lib/models/checktools";
import { objDataType } from "lib/models/types";
import { LoadStructuralData } from "lib/models/structural/load";
import { useAnalysisPanel } from "modules/struc/components/analysis/hook/use-analysis-panel";
import { getPropSettingsFromBeamCS } from "modules/struc/components/cross-sections/hooks/use-beam-cross-sections-nodes";
import { getPropSettingsFromShellCS } from "modules/struc/components/cross-sections/hooks/use-shell-cross-sections-nodes";
import { getPropSettingsFromHypothesis, getPropSettingsFromSSE } from "modules/struc/components/hypothesis/hooks/use-hypothesis";
import { SSEPropertyPanel } from "modules/struc/components/hypothesis/sse-hypothesis";
import { useMeshPanel } from "modules/struc/components/mesh/hook/use-mesh-panel";
import MeshViewerPanel from "modules/struc/components/mesh/mesh-viewer";
import { useCallback } from "react";
import { MemoizedSelectionPanel } from "../selection/selection-panel";
import { IPanelSetting, itemType, useSideBarContext } from "../sidebar/context";
import { useMainGraphicContext } from "../viewport/context";
import PropertiesAccordion from "./component-accordion";
import PanelPropertiesForm from "./properties-panel-form";
import { IObjData } from "lib/models/objdata";

const PropertyPanel = () => {

  const { itemData } = useSideBarContext();

  const graphicProc = useMainGraphicContext();

  const getPropSettingsFromStrucElem = useCallback((): IPanelSetting<{}> => {

    const checkNames = () => {
      const strMng = graphicProc.getStructuralModelManager();
      const slabs = strMng.currBuilding.getAllStructElements(objDataType.SLAB);
      const names = slabs.map(s => s.strucName);
      let findDuplicates = (arr: string[]) => arr.filter((item, index) => arr.indexOf(item) !== index)
      console.log(findDuplicates(names)) // All duplicates
    }
    const cleanLinkedObj = () => {
      const strMng = graphicProc.getStructuralModelManager();
      const elem = strMng.getStructElements();
      elem.forEach(e => {
        e.definition.lnkObjIds.length = 0;
        e.lnkObjs.length = 0;
      });
      strMng.currBuilding.columnCompoundElements = new ColumnCompoundElement();
      strMng.currBuilding.wallCompoundElements = new WallCompoundElement();
      alert("Linked object cleaned!")
    }
    const restoreLinkedObj = () => {
      const strMng = graphicProc.getStructuralModelManager();
      const elem = strMng.getStructElements();
      elem.forEach(e => {
        AddConsolidation(e, graphicProc);
      });
      alert("Linked object restored!")
    }
    const showCompoundElem = () => {
      const res = dumpCompoundElements(graphicProc)
      console.log("COLUMNS: ");
      console.log(res[0]);
      console.log("WALLS: ");
      console.log(res[1]);
    }
    const checkLoads = () => {
      const removeLoad: LoadStructuralData[] = [];
      const strMng = graphicProc.getDataModelManager();
      for (const data of strMng.iterAllData()) {
        if (isLoadData(data)) {
          const { hypothesisId, parentStructElemId } = data.definition;
          let parent = strMng.getData(parentStructElemId);
          if (!parent) {
            console.warn(`[LOAD] ${data.strucName} without parent reference`);
            removeLoad.push(data);
          }
          const hypo = hypothesisManager.getHypothesisByid(hypothesisId);
          const windHypo = windhypothesisManager.getWindHypothesisById(hypothesisId);
          if (!hypo && !windHypo) {
            console.warn(`[LOAD] ${data.strucName} without hypothesis`)
          }
        }
      }
      for (const load of removeLoad) {
        graphicProc.removeFromLayer(load);
      }
      if (removeLoad.length) {
        graphicProc.getDataModelManager().dispatchDeletedObjs(removeLoad);
        const lyrManager = graphicProc.getLayerManager();
        lyrManager.layerObserver.dispatchLoadLayers();
      }
      alert("Loads resolved!")
    }

    return {
      title: "",
      setting: {
        propValue: {
          names: { type: "button", value: undefined, publicName: "Check slabs duplicate names", buttonCb: checkNames },
          linked: { type: "button", value: undefined, publicName: "Clean Linked objs", buttonCb: cleanLinkedObj },
          consolidation: { type: "button", value: undefined, publicName: "restore linked obj", buttonCb: restoreLinkedObj },
          showCE: { type: "button", value: undefined, publicName: "print Compound Elements", buttonCb: showCompoundElem },
          checkLoads: { type: "button", value: undefined, publicName: "Clean loads without reference", buttonCb: checkLoads },
        },
      },
    }
  }, [graphicProc])

  const {
    isMeshDownloaded,
    getPropSettingsFromMesh
  } = useMeshPanel();

  const {
    getPropSettingsFromCodeAnalysis,
    getPropSettingsFromHypothesisSolve,
    getPropSettingsFromDisplacement,
    getPropSettingsFromBeamFM,
    getPropSettingsFromShellFM,
    getPropSettingsFromNodalFM,
  } = useAnalysisPanel();

  const settingPanelBuilder: Record<itemType, (val?: any) => IPanelSetting<any> | null> = {
    [itemType.OPERATION]: (info: IPanelSetting<any>) => info,
    [itemType.STRUCELEMENT]: getPropSettingsFromStrucElem,
    [itemType.BEAMCS]: getPropSettingsFromBeamCS,
    [itemType.SHELLCS]: getPropSettingsFromShellCS,
    [itemType.MESH]: getPropSettingsFromMesh,
    [itemType.HYPOSET]: getPropSettingsFromHypothesis,
    [itemType.LOAD]: () => null,
    [itemType.SSE]: getPropSettingsFromSSE,
    [itemType.SSE_PROP]: () => null,
    [itemType.CODEANALYSIS]: getPropSettingsFromCodeAnalysis,
    [itemType.SOLVEANALYSIS]: getPropSettingsFromHypothesisSolve,
    [itemType.DISPLACEMENT]: getPropSettingsFromDisplacement,
    [itemType.BEAMFM]: getPropSettingsFromBeamFM,
    [itemType.SHELLFM]: getPropSettingsFromShellFM,
    [itemType.NODALFM]: getPropSettingsFromNodalFM,
    [itemType.TOOLS]: () => null,
    [itemType.STOREYDRIFT]: () => null,
  };

  if (itemData) {
    if (itemData.type === itemType.SSE_PROP) {
      return (<SSEPropertyPanel type={itemData.info} />)
    }
    const settings = settingPanelBuilder[itemData.type](itemData.info);
    if (settings) {
      const { title, setting } = settings;
      return (
        <div className="layers h-full p-2 space-y-2 overflow-y-auto" >
          <PropertiesAccordion title={title!}>
            <PanelPropertiesForm panelProp={setting!} />
          </PropertiesAccordion>
          {itemData.type === itemType.MESH &&
            <MeshViewerPanel isMeshDownloaded={isMeshDownloaded} />
          }
        </div>
      );
    }
  }
  return (
    <MemoizedSelectionPanel objData={itemData?.info as IObjData}/>
  );
};

export default PropertyPanel;
