/**
 * \file liarrow.ts
 * Implementacion de nuestra propia flecha invertida para cargas, aka [L]oad [I]nverted [Arrow], que se inspira en la
 * THREE.ArrowHelper pero con unas sutiles diferencias; en la de Three tenemos un origen P y una direccion D en la que
 * la flecha apunta, y en ese P es donde se coloca la flecha:
 * 
 *      O------->D
 * 
 * Pero en nuestra clase invertimos eso: El origen esta en P, y la direccion es D, pero no aplicada sobre P, sino sobre
 * el otro extremo Q:
 * 
 *      Q------->P
 * 
 * Por ejemplo en THREE si asumimos un origen en P(0, 0, 0) y una direccion (0, 0, -1) tendriamos lo de la izquierda,
 * pero con nuestra inverted arrow lo tendriamos como a la derecha:
 * 
 *                              |
 *                              |
 *                              V
 *      ----P----           ----P----
 *          |
 *          |
 *          V
 * 
 * LA PUTADA DE ESTO: Hace lento de cojones el rendering.
 * La version dev.greystruct.com actual con el proyecto F3-BOSC21022_TORRE SINGH_JL se mueve muy bien a unos 30-60 fps,
 * pero lo mismo incluyendo el uso de estas nuevas flechas nos baja a unos 6 fps, asi que hay que optimizar la cosa...
 * EFECTIVAMENTE son las flechas las responsables de la lentitud en el rendering.
 * 
 * POSIBLE SOLUCION: Usar meshes instanciados???...
 */

import * as THREE from "three";

const _axis = /*@__PURE__*/ new THREE.Vector3();
let _lineGeometry: THREE.BufferGeometry = null!;
let _coneGeometry: THREE.CylinderBufferGeometry = null!;

/**
 * [L]oad [I]nverted [Arrow], flecha invertida para las cargas. Invertida en el sentido en que apunta hacia su posicion
 * o punto de aplicacion, en vez de desde el.
 *
 * @export
 * @class LIArrow
 * @extends {THREE.Object3D}
 */
export class LIArrow extends THREE.Object3D {

	line: THREE.Line;
    cone: THREE.Mesh;
    material: THREE.Material;

    // Contaremos las flechas para saber cuantas tenemos para la depuracion.
    static count: number = 0;

    // Aqui iran todas las intersecciones a NO ser generadas a un mismo sitio comun para ahorrar calculos y memoria.
    static intersectionNOP = () => { /*console.log("NOP");*/ };
    
    // dir is assumed to be normalized
    constructor(dir = new THREE.Vector3(0, 0, 1),
                origin = new THREE.Vector3(0, 0, 0),
                length = 1,
                color: number | string = "hotpink",
                headLength = length * 0.2,
                headWidth = headLength * 0.2,
                headOnlyIntersections: boolean = true) {
        super();
        this.type = 'LIArrow';

        if (_lineGeometry === null) {
            _lineGeometry = new THREE.BufferGeometry();
            _lineGeometry.setAttribute('position', new THREE.Float32BufferAttribute([0, 0, 0, 0, 1, 0], 3));
            _lineGeometry.translate(0, -1.0, 0);

            // if (false) {
            //     // Para tener claro la maldad de la puta direccion metemos colores, de rojo en 0 a verde en 1.
            //     _lineGeometry.setAttribute( 'color', new THREE.Float32BufferAttribute([1, 0, 0, 0, 1, 0], 3));
            // }

            // Modificamos para que la cabeza sea de seccion triangular, lo que nos da un ahorro respecto a la version
            // ArrowHelper original que es pentagonal.
            _coneGeometry = new THREE.CylinderBufferGeometry(0, 0.5, 1, 3, 1);
            _coneGeometry.translate(0, +0.5, 0);
        }

        this.position.copy(origin);
        // Las geometrias son unicas y compartidas, pero no asi los materiales, que deben soportar distintos colores.
        this.line = new THREE.Line(_lineGeometry, new THREE.LineBasicMaterial({ color: color, fog: false }));
        this.line.matrixAutoUpdate = false;
        this.add(this.line);

        // Nos aseguramos de que solo tiene una cara, para evitar calculos superfluos. Ni niebla, que es mas costosa.
        this.cone = new THREE.Mesh(
            _coneGeometry,
            new THREE.MeshBasicMaterial({ color: color, side: THREE.FrontSide, fog: false })
        );
        this.cone.matrixAutoUpdate = false;
        this.add(this.cone);

        this.setDirection(dir);
        this.setLength(length, headLength, headWidth);

        // De momento le damos el nombre de la flecha mas su numeraco de creacion.
        this.name = `Arrow+${LIArrow.count}`;

        // Y un truco para darle como material propio el de su cabeza de flecha, a ver si luego se representan bien los
        // colores cuando entra en juego la seleccion-deseleccion.
        this.material = this.cone.material as THREE.Material;

        if (headOnlyIntersections) {
            // Optimizacion para que solo se tengan en cuenta las intersecciones que se efectuan contra la cabeza de la
            // flecha, despreciando las que van contra el palito, que es como enhebrar aguja en pajar, con perdon...
            
            // Salvamos la funcion de casting previa de la cabeza antes de machacarla.
            const prevCast4Cone = this.cone.raycast;

            // Machacamos las intersecciones de los componentes palo y punta.
            this.cone.raycast = this.line.raycast = LIArrow.intersectionNOP;

            this.raycast = (raycaster: THREE.Raycaster, intersects: THREE.Intersection[]) => {
                // console.log(`arrow.raycast("${this.name}")`);
                return prevCast4Cone.call(this.cone, raycaster, intersects);
            };
        }

        LIArrow.count += 1;
        console.log(` ---> Created LIArrow "${this.name}" [${LIArrow.count}]`);
    }

    setDirection(dir: THREE.Vector3): void {

        // dir is assumed to be normalized
        if (dir.y > 0.99999) {
            this.quaternion.set(0, 0, 0, 1);
        } else if (dir.y < -0.99999) {
            this.quaternion.set(1, 0, 0, 0);
        } else {
            _axis.set(dir.z, 0, -dir.x).normalize();
            const radians = Math.acos(dir.y);
            this.quaternion.setFromAxisAngle(_axis, radians);
        }
    }

    setLength(length: number, headLength = length * 0.2, headWidth = headLength * 0.2): void {
        // La linea siempre llega a la longitud dada, aunque se meta por la flecha.
        this.line.scale.set(1, length, 1);
        this.line.updateMatrix();

        this.cone.scale.set(headWidth, headLength, headWidth);
        this.cone.position.y = -headLength;
        this.cone.updateMatrix();
    }

    setColor(color4All: number | string, color4Head?: number | string): void {
        if (!color4Head) {
            // @ts-ignore
            this.line.material.color.set(color4All);
            // @ts-ignore
            this.cone.material.color.set(color4All);
        } else {
            // @ts-ignore
            this.line.material.color.set(color4All);
            // @ts-ignore
            this.cone.material.color.set(color4Head);
        }
    }

    // @ts-ignore
    copy(source) {
        super.copy(source, false);
        this.line.copy(source.line);
        this.cone.copy(source.cone);
        return this;
    }

    dispose() {
        this.line.geometry.dispose();
        // @ts-ignore
        this.line.material.dispose();
        this.cone.geometry.dispose();
        // @ts-ignore
        this.cone.material.dispose();
    }

} // class LIArrow