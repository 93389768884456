import * as THREE from "three";
import { GraphicProcessor } from "lib/graphic-processor";
import { IObjData } from "lib/models/objdata";

// Aqui se amplia la interseccion incorporandole los datos sobre el objeto implicado, de variopinta naturaleza, con
// ciertos problemas que ello conlleva, como la inexistencia de ciertas propiedades etc...
export type rayCastResults = THREE.Intersection & { dataObject: IObjData };

const defaultRaycastThresholdValue = 5;

export class RaycasterModel {

  private graphicProcessor: GraphicProcessor;
  private raycaster: THREE.Raycaster;

  get ray() {
    return this.raycaster.ray;
  }

  private objsIntersections: rayCastResults[] = [];
  
  get raycastedObject() {
    return this.objsIntersections;
  }

  private idLayers2Raycast: string[] = [];
  private object2Raycast: IObjData[] = [];

  constructor(graphicProc: GraphicProcessor) {
    this.graphicProcessor = graphicProc;
    // Default raycaster to obtain objects in scene.
    this.raycaster = new THREE.Raycaster();

    if (false) {
      // FGM: UltraOptimizacion. Podemos tener un monton de cosas a las que hacer rayCasting en pantalla, un montonazo
      // de la puta hostia... De los cuales quizas muchos no nos interesen para nada, como por ejemplo los palitos de
      // las flechas, pero aun asi la fmc Raycaster.intersectObject() va a intentar llamar a las fmc raycast de todos
      // los objetos, que siempre existen... La modificacion que se me ocurre es comprobar previamente la existencia de
      // esas fmc raycast y si existen llamarlas. Con esto podria desactivar objetos para los que no me interesa que se
      // calculen colisiones y ahorrariamos calculos. Llamemosla por sus siglas [UORC].
      // FALLA POR LAS PROPIAS LLAMADAS RECURSIVAS... Asi que de momento la dejamos en la reserva...
      const prevIntersectObject = this.raycaster.intersectObject;
      this.raycaster.intersectObject = (
        object: THREE.Object3D,
        recursive?: boolean | undefined,
        optionalTarget?: THREE.Intersection[] | undefined
      ): THREE.Intersection[] => {
        if (object.raycast !== null) {
          return prevIntersectObject.call(this.raycaster, object, recursive, optionalTarget);
        }
        console.log(` - Avoiding intersections calculation with object '${object.name}'.`);
        return [];
      };

      console.log(" U L T R A - O P T I M I Z E D     R A Y - C A S T E R.");
    }
  
    this.setRaycasterThreshold(defaultRaycastThresholdValue);
  }

  setRaycasterThreshold(unit: number): void {
    this.raycaster.params = {
      Mesh: {},
      Line: { threshold: unit },
      LOD: {},
      Points: { threshold: unit },
      Sprite: {},
    };
  }

  clearRaycaster() {
    this.objsIntersections.length = 0;
    this.idLayers2Raycast.length = 0;
    this.object2Raycast.length = 0;
    this.graphicProcessor = undefined!;
  }

  /**
   * Set struc layers according to active viewport.
   * Used by selection box and zoom extents.
   *
   * @param {string[]} layerIds
   * @memberof RaycasterModel
   */
  setStrucLayer2Raycast(layerIds: string[]): void {
    const viewPort = this.graphicProcessor.getActiveViewport();
    const cursorCamera = viewPort.camera as THREE.Camera;
    if (cursorCamera.type === "PerspectiveCamera") {
      this.idLayers2Raycast = [];
    } else {
      this.idLayers2Raycast = layerIds;
    }
  }

  getStrucLayer2Raycast(): string[] {
    return this.idLayers2Raycast;
    // get struc layers according to active viewport to iterate model data
    // const layers: string[] = [];
    // const viewPort = this.graphicProcessor.getActiveViewport();
    // const cursorCamera = viewPort.camera as THREE.Camera;
    // if (cursorCamera.type !== "PerspectiveCamera") {
    //   const strucModelMng = this.graphicProcessor.getStructuralModelManager();
    //   layers.push(...strucModelMng.currStoreyLayers);
    // }
    // return layers;
  }

  /**
   * Aditionally object included to raycast.
   *
   * @param {IObjData[]} objs
   * @memberof RaycasterModel
   */
  setObject2Raycast(objs: IObjData[]): void {
    this.object2Raycast = objs;
  }

  /**
   * Model raycast.
   *
   * @param {THREE.Vector2} mousePos
   * @param {THREE.Camera} camera
   * @memberof RaycasterModel
   */
  intersectObjects(mousePos: THREE.Vector2, camera: THREE.Camera) {
    this.raycaster.setFromCamera(mousePos, camera);
    // this.threeRaycast();
    this.ownModelRaycast();
    // console.log("Raycast result: " + this.objsIntersections.length);
  }

  private ownModelRaycast() {
    this.objsIntersections = [];
    const dataModel = this.graphicProcessor.getDataModelManager();
    dataModel.iterAllDataFromLayers(this.idLayers2Raycast, (data) => {
      if (data.isVisible && !data.isLocked) {
        const inters = this.raycaster.intersectObject(data.graphicObj, true);
        if (inters.length) {
          this.objsIntersections.push({ ...inters[0], dataObject: data });
        }
      }
    });
    for (const data of this.object2Raycast) {
      const inters = this.raycaster.intersectObject(data.graphicObj, true);
      if (inters.length) {
        this.objsIntersections.push({ ...inters[0], dataObject: data });
      }
    }
    // Order raycast result by distance
    this.objsIntersections = this.objsIntersections.sort((a, b) => a.distance - b.distance);
  }

  private threeRaycast() {
    const scene = this.getScene2Raycast();
    const dataModel = this.graphicProcessor.getDataModelManager();
    const intersects = this.raycaster.intersectObjects(scene, true);
    this.objsIntersections = [];
    for (const i of intersects) {
      const res = dataModel.getDataFromGraphic(i);
      if (res && res.dataObject.isVisible) {
        this.objsIntersections.push(res);
      } else {
        const resObj = this.object2Raycast.find(o => o.graphicObj === i.object);
        if (resObj)
          this.objsIntersections.push({ ...i, dataObject: resObj });
      }
    }
  }

  private getScene2Raycast(): THREE.Object3D[] {
    const layerManager = this.graphicProcessor.getLayerManager();
    let objs: THREE.Object3D[] = []
    if (this.idLayers2Raycast?.length) {
      objs = this.idLayers2Raycast.map(id => {
        const layer = layerManager.getLayerDataFromId(id)!;
        return layer.threeObject;
      });
    } else {
      objs = [layerManager.getRootLayer().threeObject];
    }

    if (this.object2Raycast?.length) {
      objs.push(...this.object2Raycast.map(o => o.graphicObj));
    }
    return objs;
  }

  /**
   * Custom raycast to three object.
   *
   * @param {THREE.Object3D} objects
   * @param {boolean} [recursive=true]
   * @return {*}  {THREE.Intersection[]}
   * @memberof RaycasterModel
   */
  raycastObjects(objects: THREE.Object3D, recursive: boolean = true): THREE.Intersection[] {
    return this.raycaster.intersectObjects(objects.children, recursive);
  }

} // class RaycasterModel
