import { baseUriModel } from "./mesh-exporter";
import { loadType } from "lib/models-struc/types/load";
import { GeoRepresentation as GeoRepresentationEcore } from "modules/struc/models/ecore/representation";
import { Vector as VectorEcore } from "modules/struc/models/ecore/location";
import { Point as PointEcore, WireGeometry as WireGeometryEcore } from "modules/struc/models/ecore/geometry";
import { getEcoreFloat } from "./helper-ecore";
import { getEcorePlacement, getEcoreVector3 } from "./locations";
import { getEcorePointGeometry, getEcoreOpenWireGeometry, getEcoreClosedWireGeometry } from "./geometry";
import { IEcoreBuildingElementExport } from "./struc-elem-base";
import { ILoad as ILoadEcore } from "modules/struc/models/ecore/analysis";
import { femStructuralManager } from "lib/models-struc/mesh/femstructural-manager";
import { LoadStructuralData } from "lib/models/structural/load";
import { windhypothesisManager } from "lib/models-struc/hypothesis/wind";
import { windSubTypes } from "lib/models-struc/hypothesis/hypothesis";
import { representationUriModel } from "modules/struc/models/ecore/uri";
import { ErrorCentinel, i_ECS } from "lib/helpers/error-centinel";

export class EcoreLoadElem {

  private strucData: LoadStructuralData;
  private parentElem: IEcoreBuildingElementExport;

  setStrucElem(strData: LoadStructuralData, parentElem: IEcoreBuildingElementExport) {
    this.strucData = strData;
    this.parentElem = parentElem;
  }

  exportToEcore(): ILoadEcore {
    // Sacamos la escena auxiliar de la bolsa magica. Recuerda que la registraste previamente con "+" para denotar que
    // sera un objeto que no se borre de la bolsa magica cuando se haga un resetee el centinela.
    // const auxScene = i_ECS.magicBag.get("+auxScene") as THREE.Scene;
    const name4Load = this.strucData.definition.name;

    // La primera vez que se exporta una carga.
    if (i_ECS.getCounter("counter4Loads") === 0) {
      const styles = [ 'color: red', 'background: yellow', ].join(';');
      ErrorCentinel.printBox("Starting ecore exporting of loads.", styles);

      // Ademas incorporamos a la bolsa magica un Set() de cadenas para almacenar los nombres de las cargas y comprobar
      // que efectivamente NO hay posibles repeticiones en los mismos.
      i_ECS.magicBag.set("names4Loads", new Set<string>());
      i_ECS.magicBag.get("names4Loads").add(name4Load);

      // Incorporamos los titulos de la tabla HTML que sera emitida al finalizar la exportacion de las cargas.
      // Ojo, que los titulos son las claves para rellenar las columnas.
      i_ECS.setTableTitles([
        "Index", "Hypothesis", "Load name", "Owner name", "Value", "Geom.Ext.",
        "Pos (Geom.Ext.)", "Pos (Load)", "Dist L-GE", "Pos (Owner)"
      ]);
    } else {
      // Comprobamos que no hay repeticiones en los nombres.
      if (i_ECS.magicBag.get("names4Loads").has(name4Load)) {
        const errMsg = `ERROR: DUPLICATED LOAD "${name4Load}"!!!.`;
        ErrorCentinel.printBox(errMsg, "color: red", "\t\t\t\t <--- ");
        window.alert(errMsg);        
        debugger;
      } else {
        i_ECS.magicBag.get("names4Loads").add(name4Load);
      }
    }

    // Metemos una nueva linea vacia en la que iremos agregando los datos de la tabla HTML.
    i_ECS.addTableEmptyRow();

    // Incremento en las cargas totales procesadas, asi como de las mismas pero consideradas por la hipotesis en curso.
    const cnt4Load = i_ECS.incCounter("counter4Loads");
    const cnt4LoadHypo = i_ECS.incCounter("counter4Loads4Hypo");

    ErrorCentinel.printBox(`[${cnt4Load}-${cnt4LoadHypo}] exportToEcore() ===> EXPORTING LOAD "${name4Load}" TO ECORE.`);
    i_ECS.setColumnValue("Index", "" + cnt4Load + "-" + cnt4LoadHypo);
    i_ECS.setColumnValue("Hypothesis", i_ECS.magicBag.get("name4Hypothesis"));
    i_ECS.setColumnValue("Load name", name4Load);
    
    const repr = this.strucData.definition;
    const loadEcore: ILoadEcore = {
      eClass: baseUriModel + "analysis/Load",
      vector: this.getEcoreDirection(),
      geometry: repr.externalGeo ? [this.getEcoreRepresentationLoad()] : [],
      element: femStructuralManager.getFEMStructuralElemRef(this.strucData),
      // FGM: Podemos acceder al nombre de la carga, que teoricamente siempre existe.
      name: this.strucData.definition.name,
    };

    if (loadEcore.element === undefined) {
      console.error("Hostia puta que hay carga sin elemento estructural!!!.");
      debugger;
    }

/*    
    let [xV, yV, zV] = [0.0, 0.0, 0.0];
    if (true) {      
      const v = loadEcore.vector!;
      if (v.x !== undefined && v.x !== null) {
        xV = v.x;
      }
      if (v.y !== undefined && v.y !== null) {
        yV = v.y;
      }
      if (v.z !== undefined && v.z !== null) {
        zV = v.z;
      }

      console.log(`vector = (${xV}, ${yV}, ${zV})`);
      // ErrorCentinel.seeObjectProperties(loadEcore.vector, "loadEcore.vector");
      // const ballVector = i_ECS.addDebugBall(auxScene, xV, yV, zV);
      // ErrorCentinel.change2RandomColor(ballVector);
    }
*/

    // Para la propia posicion de la carga que sacamos de su .strucData.definition.basePoint
    let [xL, yL, zL] = [0.0, 0.0, 0.0];
    if (true) {
      const v = this.strucData.definition.basePoint;
      if (v.x !== undefined && v.x !== null) {
        xL = v.x;
      }
      if (v.y !== undefined && v.y !== null) {
        yL = v.y;
      }
      if (v.z !== undefined && v.z !== null) {
        zL = v.z;
      }
      const posOwnLoad = `(${xL}, ${yL}, ${zL})`;
      i_ECS.setColumnValue("Pos (Load)", "" + posOwnLoad);
      console.error(`Pos (ownLoad) = ${posOwnLoad}`);
    }

    // El dueño/owner de la carga.
    let [xO, yO, zO] = [0.0, 0.0, 0.0];
    if (true) {
      // @ts-ignore
      if (this.parentElem.strucElem) {
        // @ts-ignore
        const slab = this.parentElem.strucElem as SlabData;
        const parentName = slab.definition.name;
        i_ECS.setColumnValue("Owner name", parentName)
        // console.error(`name3 = "${slab.definition.name}"`);
        const v = slab.definition.basePoint;
        if (v.x !== undefined && v.x !== null) {
          xO = v.x;
        }
        if (v.y !== undefined && v.y !== null) {
          yO = v.y;
        }
        if (v.z !== undefined && v.z !== null) {
          zO = v.z;
        }

        const posOwner = `(${xO}, ${yO}, ${zO})`;
        i_ECS.setColumnValue("Pos (Owner)", "" + posOwner);
        // console.error(`Pos (ownLoad) = ${posOwnLoad}`);
        // console.error(`V3 = (${v.x}, ${v.y}, ${v.z})`);
        // const ballBase3 = i_ECS.addDebugBall(auxScene, v.x, v.y, v.z);
        // ErrorCentinel.change2Color(ballBase3, 0.0, 0.0, 1.0);
      }
    }

    i_ECS.setColumnValue("Geom.Ext.", "NO");
    if (loadEcore.geometry?.length) {
      const NG = loadEcore.geometry.length;
      
      ErrorCentinel.printBox(`LOAD WITH EXTERNAL GEOMETRY of [${NG}] components.`, "color: red", '\t');
      i_ECS.setColumnValue("Geom.Ext.", "" + NG);

/*      
      console.error(`name2 = "${this.strucData.definition.name}"`);
      // El otro sitio donde hay tambien un base point es este, que ponemos como bola verde.
      const v2 = this.strucData.definition.basePoint;
      console.error(`V2 = (${v2.x}, ${v2.y}, ${v2.z})`);
      // const ballBase2 = i_ECS.addDebugBall(auxScene, v2.x, v2.y, v2.z);
      // ErrorCentinel.change2Color(ballBase2, 0.0, 1.0, 0.0);

      // Puede incluso estar la cosa repetida en otra parte.
      // @ts-ignore
      if (this.parentElem.strucElem) {
        // @ts-ignore
        const slab = this.parentElem.strucElem as SlabData;
        console.error(`name3 = "${slab.definition.name}"`);
        const v3 = slab.definition.basePoint;
        console.error(`V3 = (${v3.x}, ${v3.y}, ${v3.z})`);
        // const ballBase3 = i_ECS.addDebugBall(auxScene, v3.x, v3.y, v3.z);
        // ErrorCentinel.change2Color(ballBase3, 0.0, 0.0, 1.0);
      }
*/

      for (let i = 0; i < NG; ++i) {
        let [xG, yG, zG] = [0.0, 0.0, 0.0];
        const posEG = loadEcore.geometry[i].placement.base;
        if (posEG.x !== undefined && posEG.x !== null) {
          xG = posEG.x;
        }
        if (posEG.y !== undefined && posEG.y !== null) {
          yG = posEG.y;
        }
        if (posEG.z !== undefined && posEG.z !== null) {
          zG = posEG.z;
        }
        const posGeomExt = `(${xG}, ${yG}, ${zG})`;
        i_ECS.setColumnValue("Pos (Geom.Ext.)", posGeomExt);
        console.error(`V[${i}/${NG}] = ${posGeomExt}`);

        // const ballBase = i_ECS.addDebugBall(auxScene, xG, yG, zG);
        // ErrorCentinel.change2Color(ballBase, 1.0, 0.0, 0.0);

        // La distancia (obviamente 3D) entre las posiciones de la geometria externa y de la propia carga.
        const dist = Math.sqrt(((xG - xL) * (xG - xL)) + ((yG - yL) * (yG - yL)) + ((zG - zL) * (zG - zL)));
        // Si es inferior a decima de milimetro.
        if (dist < 0.0001) {
          i_ECS.setColumnValue("Dist L-GE", "< 0.0001");
        } else {
          i_ECS.setColumnValue("Dist L-GE", "" + dist);
        }

/*
        const g = loadEcore.geometry[i];
        // ErrorCentinel.seeObjectProperties(g, `loadEcore.geometry[${i}]`);
        // @ts-ignore
        if (g.base.geometries) {
          // @ts-ignore
          const N = g.base.geometries.length;
          const color4Segments = ErrorCentinel.getRandomColor();
          for (let j = 0; j < N; ++j) {
            // @ts-ignore
            const gJ = g.base.geometries[j];
            let [x0, y0, z0] = [xG, yG, zG];
            if (gJ.p0) {
              const p0 = gJ.p0;
              if (p0.x !== undefined && p0.x !== null) {
                x0 += p0.x;
              }
              if (p0.y !== undefined && p0.y !== null) {
                y0 += p0.y;
              }
              if (p0.z !== undefined && p0.z !== null) {
                z0 += p0.z;
              }

              // const obj = i_ECS.addDebugBall(auxScene, x0, y0, z0);
              // ErrorCentinel.change2RandomColor(obj);
            } else {
              debugger;
            }

            let [x1, y1, z1] = [xG, yG, zG];
            if (gJ.p1) {
              const p1 = gJ.p1;

              if (p1.x !== undefined && p1.x !== null) {
                x1 += p1.x;
              }
              if (p1.y !== undefined && p1.y !== null) {
                y1 += p1.y;
              }
              if (p1.z !== undefined && p1.z !== null) {
                z1 += p1.z;
              }

              // const obj = i_ECS.addDebugBall(auxScene, x1, y1, z1);
              // ErrorCentinel.change2RandomColor(obj);
            } else {
              debugger;
            }

            if (gJ.p0 && gJ.p1) {
              // const segment = i_ECS.addDebugSegmentAB(auxScene, x0, y0, z0, x1, y1, z1);
              // ErrorCentinel.change2Color(segment, ...(color4Segments).toArray() as [number, number, number]);
            } else {
              debugger;
            }
          }
        }
*/
      } // for (let i = 0; i < loadEcore.geometry.length; ++i)
    } // if (loadEcore.geometry?.length)

    const value = getEcoreFloat(Math.abs(repr.loadValue));
    if (value !== undefined) loadEcore.value = value;

    if (true) {
      console.error(`value = ${loadEcore.value}`);
      i_ECS.setColumnValue("Value", "" + loadEcore.value);
    }

    return loadEcore;
  }

  private getEcoreDirection(): VectorEcore {
    const hypoId = this.strucData.definition.hypothesisId;
    const windHypo = windhypothesisManager.getWindHypothesisById(hypoId);
    if (windHypo) {
      // FGM: Nada de rounding aqui!!!.
      if (windHypo.subType === windSubTypes.WX1P) return getEcoreVector3({ x: 1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX2P) return getEcoreVector3({ x: 1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX1N) return getEcoreVector3({ x: -1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WX2N) return getEcoreVector3({ x: -1, y: 0, z: 0 });
      if (windHypo.subType === windSubTypes.WY1P) return getEcoreVector3({ x: 0, y: 1, z: 0 });
      if (windHypo.subType === windSubTypes.WY2P) return getEcoreVector3({ x: 0, y: 1, z: 0 });
      if (windHypo.subType === windSubTypes.WY1N) return getEcoreVector3({ x: 0, y: -1, z: 0 });
      return getEcoreVector3({ x: 0, y: -1, z: 0 }); // windHypo.subType === windSubTypes.WY2N 
    } else {
      return this.parentElem.getEcoreDirection();
    }
  }

  private getEcoreRepresentationLoad(): GeoRepresentationEcore<PointEcore | WireGeometryEcore> {
    const repr = this.strucData.definition;
    return {
      eClass: `${representationUriModel}GeoRepresentation`,
      placement: getEcorePlacement(repr.basePoint),
      base: this.getEcoreGeometryLoad(),
    }
  }

  private getEcoreGeometryLoad(): PointEcore | WireGeometryEcore {
    const repr = this.strucData.definition;
    switch (repr.type) {
      case loadType.CONCENTRATED:
        return getEcorePointGeometry({ x: 0, y: 0, z: 0 });
      case loadType.LINEAL:
        return getEcoreOpenWireGeometry(repr.ptos2D);
      case loadType.SUPERFICIAL:
        return getEcoreClosedWireGeometry(repr.ptos2D);
    }
  }
}
