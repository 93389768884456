import { v4 as uuid } from 'uuid'
import { getBoundingBox } from "lib/geometries/bounding-box";
import { GraphicProcessor } from "lib/graphic-processor";
import { copyMaterial } from "lib/materials";
import { IBox } from "lib/math/box";
import { IPoint } from "lib/math/types";
import { dimDependence } from "./dimension/dimension";
import { getCenterMinZObjData } from "lib/geometries/centroids";
import { LayerData } from "lib/layers/layer-data";
import { isLineBasedGeometry } from './checktools';
import { dataModelPersistence } from 'lib/input-output/database/loader';
import { objDataType, definitionType, materialType } from './types';

/**
 * Aqui tenemos todas las cosas gestionadas por el modelo, desde los pilares a las cargas, todo todito.
 *
 * @export
 * @interface IObjData
 */
export interface IObjData {

  id: string;
  /// Este es el tipo, un enum con el que podriamos saber cuando es una carga o un pilar o su puta madre.
  type: objDataType;

  readonly objName: string;

  graphicObj: THREE.Object3D;
  createGraphicObj(): void;
  createObject(definition?: definitionType, material?: materialType): THREE.Object3D;

  regenerateObjectFromDefinition(): void
  regenerateObjectFromMaterial(graphicProcessor: GraphicProcessor): void
  visibleGraphicObject: boolean;

  layerObj: LayerData;
  readonly layerId: string;

  readonly isVisible: boolean;
  readonly isLocked: boolean;

  isDataVisible: boolean;
  isDataLocked: boolean;
  isLayerLocked: boolean;
  isLayerVisible: boolean;

  delete(): void;

  definition: definitionType;
  material: materialType | undefined;
  cloneDefinition(): definitionType;
  cloneMaterial(): materialType | undefined;

  getBoundingBox(): IBox;
  getBasePoint(): IPoint;

  translate(dist: IPoint): void
  rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void;
  scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void;
  mirror(startPoint: IPoint, endPoint: IPoint): void;

  regenerateReferences(gp: GraphicProcessor): void;
  addToMyDependences(deps: dimDependence[]): void;
  removeFromMyDependences(deps: dimDependence[]): void;

  addObjAsDependence(): void;
  removeObjAsDependence(): void;
  relinkOneDependence(dep: dimDependence): void;
  unlinkOneDependence(dimPos?: number): void;
  relinkAllDependences(): void;
  unlinkAllDependences(): void;

  exportToJSON(): dataModelPersistence;
}

export abstract class ObjData implements IObjData {

  public id: string = uuid();

  public abstract type: objDataType;

  protected abstract nameObj: string;
  get objName(): string { return this.nameObj }

  public layerObj: LayerData;
  get layerId(): string { return this.layerObj.id };

  public abstract graphicObj: THREE.Object3D;
  public abstract createGraphicObj(): void;
  public abstract definition: definitionType;
  public abstract material: materialType | undefined;

  // ********************** VISIBILITY / LOCK ************************

  public isDataVisible: boolean = true;
  public isLayerVisible: boolean = true;

  get isVisible(): boolean {
    return this.isDataVisible && this.isLayerVisible;
  }

  set visibleGraphicObject(show: boolean) {
    if (this.layerObj.isBulkData && isLineBasedGeometry(this)) {
      this.layerObj.changeVisibility(this, show);
    } else {
      this.graphicObj.visible = show;
    }
  }

  public isDataLocked: boolean = false;
  public isLayerLocked: boolean = false;
  get isLocked(): boolean {
    return this.isDataLocked || this.isLayerLocked;
  }

  // ****************************************************************

  public getBoundingBox(): IBox {
    return getBoundingBox(this.graphicObj);
  }

  public getBasePoint(): IPoint {
    return getCenterMinZObjData(this);
  }

  public cloneMaterial(): materialType | undefined {
    if (this.material) return copyMaterial(this.material);
  }

  public abstract cloneDefinition(): definitionType;

  public regenerateObjectFromMaterial(graphicProcessor: GraphicProcessor): void {
    ; // VAMWL.
  }

  public abstract regenerateObjectFromDefinition(): void;

  public abstract createObject(definition?: definitionType, material?: materialType): THREE.Object3D;

  public abstract translate(distance: IPoint): void;
  public abstract rotate(angleX: number, angleY: number, angleZ: number, basePoint: IPoint): void;
  public abstract scale(factorX: number, factorY: number, factorZ: number, basePoint: IPoint): void;
  public abstract mirror(startPoint: IPoint, endPoint: IPoint): void;

  public delete() {
    this.graphicObj = undefined!;
    this.layerObj = undefined!;
  }

  // *************************** DEPENDENCIES ***************************

  public regenerateReferences(graphicProc: GraphicProcessor) {
    ; /* VAMWL. Used by Dimensions. */
  }

  private myDependences: dimDependence[] = [];

  protected dependenciesAreEquals(d0: dimDependence, d1: dimDependence) {
    return d0.data === d1.data && d0.dimPos === d1.dimPos;
  }

  public addToMyDependences(deps: dimDependence[]): void {
    for (const d of deps) {
      if (!this.myDependences.some(dep => this.dependenciesAreEquals(d, dep))) {
        this.myDependences.push(d);
      }
    }
  }
  
  public removeFromMyDependences(deps: dimDependence[]): void {
    for (const d of deps) {
      const indexDep = this.myDependences.findIndex(dep => this.dependenciesAreEquals(d, dep));
      if (indexDep !== -1) {
        this.myDependences.splice(indexDep, 1);
      }
    }
  }

  public addObjAsDependence() {
    ; /* VAMWL. Empty method except by dimensions. */
  }
  
  public removeObjAsDependence() {
    ; /* VAMWL. Empty method except by dimensions. */
  }
  
  public relinkOneDependence(dep: dimDependence) {
    ; /* VAMWL. Empty method except by dimensions. */
  }
  
  public unlinkOneDependence(dimPos?: number) {
    ; /* VAMWL. Empty method except by dimensions. */
  }

  public relinkAllDependences() {
    for (const dep of this.myDependences) {
      dep.data.relinkOneDependence({ data: this, dimPos: dep.dimPos });
    }
  }
  
  public unlinkAllDependences() {
    for (const dep of this.myDependences) {
      dep.data.unlinkOneDependence(dep.dimPos);
    }
  }

  public regenerateDependences() {
    for (const dep of this.myDependences) {
      dep.data.regenerateObjectFromDefinition();
    }
  }

  // *************************** INPUT/OUTPUT ***************************

  public exportToJSON(): dataModelPersistence {
    return {
      id: this.id,
      type: this.type,
      layerId: this.layerId,
      definition: this.exportDefinitionToJSON(),
      material: this.material,
      isDataLocked: this.isDataLocked,
      isDataVisible: this.isDataVisible,
    }
  }
  
  protected exportDefinitionToJSON(): definitionType {
    return this.definition
  }

} // abstract class ObjData implements IObjData
