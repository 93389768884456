// \file types.ts
// Implementacion de algunos interfaces y funciones auxiliares de uso comun como tipos matematicos.
// Mucho de ello es una duplicacion de cosas que ya contiene Three.js y que seguramente podria haberse usado.

/**
 * Interface para nuestros puntos 3D, (similar a THREE.Vector3).
 *
 * @export
 * @interface IPoint
 */
export interface IPoint {
    x: number;
    y: number;
    z: number;
}

/**
 * Interface para nuestros segmentos de linea entre 2 puntos 3D, (similar a THREE.Line3).
 *
 * @export
 * @interface ISegment
 */
export interface ISegment {
    p1: IPoint;
    p2: IPoint;
}

/**
 * Para saber si un objeto dado es un segmento de los nuestros, usando una especie de duckTyping.
 *
 * @export
 * @param {*} o
 * @return {*}  {o is ISegment}
 */
export function isISegment(o: any): o is ISegment {
    if (o.p1 && o.p2) {
        return true;
    }
    return false;
}

/**
 * Crea una copia del segmento dado.
 *
 * @export
 * @param {ISegment} segment
 * @return {*}  {ISegment}
 */
export function copyISegment(segment: ISegment): ISegment {
    // \ToDo: Quitar el assert...
    console.assert(isISegment(segment));
    return {
        p1: { x: segment.p1.x, y: segment.p1.y, z: segment.p1.z },
        p2: { x: segment.p2.x, y: segment.p2.y, z: segment.p2.z },
    };
}

/**
 * Interface para el segundo momento de area 2D (otros dicen inercia).
 *
 * @export
 * @interface IInertia
 */
export interface IInertia {
    x: number;
    y: number;
    xy: number;
}

/**
 * Interface para nuestros colores RGBA, (similar al THREE.Color).
 *
 * @export
 * @interface IColor
 */
export interface IColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

/**
 * Para saber si un objeto dado es un color de los nuestros, usando algo como duckTyping.
 *
 * @export
 * @param {*} o
 * @return {*}  {o is IColor}
 */
export function isIColor(o: any): o is IColor {
    if (o.r !== undefined && o.g !== undefined && o.b !== undefined && o.a !== undefined) {
        return true;
    }
    return false;
}